import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useReducer,
} from "react";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
// import Dialog from "@material-ui/core/Dialog";
// import ActionPage from "./ActionPage";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// import CloseIcon from "@material-ui/icons/Close";
import ApproveIcon from "@mui/icons-material/CheckCircle";
// import RejectIcon from "@material-ui/icons/Cancel";
import ApprovalService from "../../api/ApprovalService";
import { AuthContext } from "../../Context/AuthContext";
import $ from "jquery";
// import AccountantAction from "./AccountantAction";
// import ActionPageView from "./ActionPageView";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// import AccountantActionView from "./AccountantActionView";
// import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
// import Toast from "../Toastify";
import AdvanceBillApproval from "./AdvanceBillApproval";
// import EditIcon from "@material-ui/icons/Edit";

const _ApprovalService = new ApprovalService();

function AdvanceBillList(props) {
  const { cookies, logout } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [actionPageOpen, setActionPageOpen] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [RequestList, SetRequestList] = useState([]);
  const AcchandleShow = () => setShow(true);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [RoleName, SetRoleName] = useState("");
  const [RequestId, SetRequestId] = useState(0);
  const [AppRejStatus, SetAppRejStatus] = useState(0);
  const [ApproverStageId, SetApproverStageId] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const [Roles, SetRoles] = useState(cookies.roleName);
  const [stepList, SetStepList] = useState([]);
  const [StatusName, SetStatusName] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const handleActionPageOpen = () => {
    setActionPageOpen(true);
  };
  useEffect(() => {
    $(".submenu").removeClass("active");
    $("#AdvanceApproval").addClass("active");
    window.scrollTo(0, 0);
    var Id = cookies.userID;
    var role = cookies.roleName;
    SetRoleName(cookies.roleName);
    var RoleId = cookies.roleID;

    $(".loader-container").show();
    _ApprovalService
      .ApproverBillRequest_List(Id, role, RoleId)
      .then((response) => {
        debugger;
        const data = response.data;

        console.log(response.data);
        if (data && data.approvalRequests && data.approvalRequests.length > 0) {
          const result = data.approvalRequests;
          SetRequestList(result);
          console.log(result);
        }

        if (data && data.stepLevels && data.stepLevels.length > 0) {
          const step = data.stepLevels;
          SetStepList(step);
        }
        $(".loader-container").hide();
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function HandleClick(id, val, newval, studentId, status) {
    debugger;

    SetStatusName(status);
    SetRequestId(id);
    SetAppRejStatus(val);
    if (newval == null) {
      SetApproverStageId(0);
    }
    SetApproverStageId(newval);
    setStudentId(studentId);
  }

  function refreshPage() {
    window.location.reload(false);
  }
  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    // Trigger a click event on the hidden file input
    fileInputRef.current.click();
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const savedPage = sessionStorage.getItem("currentPage");
    if (savedPage !== null) {
      setCurrentPage(parseInt(savedPage, 10));
    }

  }, []);

  useEffect(() => {
    sessionStorage.setItem("currentPage", currentPage);
  }, [currentPage]);

  const columns = [
    {
      name: "studentId",
      label: "studentId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "ticketNumber",
      label: "Ticket No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Fund Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ticketDetails",
      label: "Ticket Details",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "createdDate",
      label: "Requested Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      // options: {
      //   filter: true,
      //   sort: true,
      //   customBodyRender: (value) => {
      //     let statusText = "";
      //     let statusColor = "";

      //     if (value === -1) {
      //       statusText = "Rejected";
      //       statusColor = "red";
      //     } else if (value === 1) {
      //       statusText = "Requested";
      //       statusColor = "green";
      //     }

      //     return <span style={{ color: statusColor }}>{statusText}</span>;
      //   },
      // },
    },

    {
      name: "ticketId",
      label: "Option",
      options: {
        filter: true,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          debugger;

          const studentId = tableMeta.rowData[0];
          return (
            <div className="center">
              <IconButton
                title="Approve"
                style={{ color: "green" }}
                variant="primary"
                data-bs-toggle="modal"
                data-bs-target="#Billapprove"
                onClick={() => HandleClick(values, 1, 1, studentId)}
              >
                <ApproveIcon />
              </IconButton>
              {/* <IconButton title="Delete" onClick={() => DeleteStudent(value)}>
                <DeleteIcon />
              </IconButton> */}
            </div>
          );
        },
      },
    },
  ];

  return (
    <div class="main-panel">
      <div class="content">
        <div className="page-inner">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <MUIDataTable
                      data={RequestList}
                      title="Advance Bill verify"
                      columns={columns}
                      options={{
                        responsive: true,
                        download: true,
                        print: true,
                        selectableRows: false,
                        filter: true,
                        viewColumns: true,
                        page: currentPage,
                        onChangePage: (currentPage) => handlePageChange(currentPage),
                        pagination: true,
                        rowsPerPageOptions: [10, 15, 100, { value: RequestList.length, label: 'ALL' }],
                      }}
                    />

                    <div>
                      <div
                        class="modal fade"
                        id="Billapprove"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                      >
                        <div class="modal-dialog modal-xl" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h3 class="modal-title">
                                <span class="fw-mediumbold">
                                  Advance Bill verify{" "}
                                </span>
                              </h3>
                              <button
                                type="button"
                                class="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => refreshPage()}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <AdvanceBillApproval
                                ID={RequestId}
                                Status={AppRejStatus}
                                StageId={ApproverStageId}
                                StudentId={studentId}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdvanceBillList;
