import React, { useState } from "react";
import { useEffect } from "react";
import RoleConfigurationService from "../../api/RoleconfigurationService";
import AssociateService from "../../api/AssociateService";
import $ from "jquery";
import { useForm } from "react-hook-form";
import Toast from "../Toastify";

import MySelect from "../MultiSelect/Myselect";

export const UserRoleConfig = (props) => {
  const AssociateServiceobj = new AssociateService();
  const ConfigurationServiceobj = new RoleConfigurationService();
  const [RoleList, setRoleList] = useState([]);
  const [employeeID, setEmployeeID] = useState([0]);
  const [RoleID, setRoleID] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm();

  //   const onKeyDown = (event) => {
  //     if (event.code == "Space") event.preventDefault();
  //   };

  const onSubmit = (data) => {
    var flag = validateCreate();
    if (flag) {
      data.privilegeIDs = $("#privilegeIDs").val();
      data.statusCode = "AC";

      ConfigurationServiceobj.Register(data)
        .then((response) => {
          Toast("Role Saved Succesfully.", "success");
          $(".close").trigger("click");
          tablerefresh(0);
        })
        .catch((error) => {
          if (error.response.data === "RoleName Already Exist.") {
            Toast(error.response.data, "warning");
            //   toast.error(error.response.data)
          } else {
            Toast("Something went wrong", "error");
            //   toast.error("Something Wrong please try again...")
          }
        });
    }
  };

  const { roleID, tablerefresh } = props;
  useEffect(() => {
    reset();
    document.getElementById("RoleConfiguration").reset();
    $(".main-loader").show();

    ConfigurationServiceobj.Create_Role(roleID)
      .then((response) => {
        var modPrivilegeLst = response.data.modPrivilegeLst;
        var roladate = response.data;
        setdata(modPrivilegeLst);
        console.log(modPrivilegeLst);
        const temp = {
          roleID: roladate.roleID,
          roleName: roladate.roleName,
          privilegeIDs: roladate.privilegeIDs,
          roleDesc: roladate.roleDesc,
        };

        Object.keys(temp).map((key, i) => setValue(key, temp[key]));

        $(".main-loader").hide();
      })
      .catch((error) => {
        $(".main-loader").hide();
      });

    AssociateServiceobj.Create(employeeID).then((res) => {
      var data = res.data;

      setRoleList(data.roleList);
      //    console.log(data.rolelist)
    });
  }, [roleID]);

  function validateCreate() {
    var checked_length = $(
      "#Table_body_Privillages input[type=checkbox]:checked"
    ).length;

    // if (checked_length == 0) {

    //     Toast('Please Select Privilege', 'error')
    //     return false;
    // }

    var RoleID = Number($("#RoleID").val());
    if (RoleID == 0) {
      Toast("Please Enter Role Name", "error");
      return false;
    }
    var selPri = "";
    $("[id^=chk]").each(function () {
      if ($(this).is(":checked")) {
        selPri += this.id.replace("chk", "") + ",";
      }
    });
    if (selPri == "") {
      Toast("Please Select Privilege", "error");

      return false;
    } else {
      selPri = selPri.substring(0, selPri.length - 1);
      $("#privilegeIDs").val(selPri);
      setValue("PrivilegeIDs", selPri);
      return true;
    }
  }

  const [data, setdata] = useState([]);

  const getArray = (menuArray) =>
    menuArray.map((item, index) => {
      return (
        <tr>
          <td>{item.moduleName}</td>
          {item.privilegeList.map((item1, index) => {
            var temp = "chk" + item1.privilegeID;
            // if (item1.selected === 0)
            {
              return (
                <td class="text-center">
                  {temp == "chk0" ? (
                    <div className="crossRolediv">
                      <span className="crossRolebtn" aria-hidden="true">
                        ×
                      </span>
                    </div>
                  ) : (
                    <input
                      type="checkbox"
                      className="form-check-input mycheck"
                      id={temp}
                      onChange={(e) => {
                        if (item1.action != "VIEW") {
                          const findCheckArray = item.privilegeList.filter((e) => item1.moduleName === e.moduleName && e.action != "VIEW");

                          if (findCheckArray) {
                            const viewPrivilege = item.privilegeList.filter((e) => e.moduleName === item1.moduleName && e.action == "VIEW");
                            for (let i = 0; i < viewPrivilege.length; i++) {
                              const currentPrivilege = viewPrivilege[i];

                              if (currentPrivilege) {
                                const temp = "chk" + currentPrivilege.privilegeID;
                                $('#' + temp).prop('checked', true);
                              }
                            }
                          }
                        } else if (item1.action === "VIEW") {
                          const findCheckArray = item.privilegeList.filter((e) => item1.moduleName === e.moduleName && e.action !== "VIEW");

                          for (let i = 0; i < findCheckArray.length; i++) {
                            const priv = findCheckArray[i];
                            if (priv) {
                              const temp = "chk" + priv.privilegeID;
                              $('#' + temp).prop('checked', false);
                            }
                          }
                        }

                      }}
                      defaultChecked={item1.selected}
                    />
                  )}

                  {/* <input type="checkbox" className="form-check-input mycheck" id={temp} defaultChecked={item1.selected} /> */}
                </td>
                // <td>

                //     <div className="form-group form-check text-center pt-2">
                //         <input type="checkbox" className="form-check-input mycheck" id={temp} defaultChecked={item1.selected} />
                //     </div>
                // </td>
              );
            }
          })}
        </tr>
      );
    });

  const SelectAll = () => {
    var checkBoxes = $(".mycheck");
    checkBoxes.prop("checked", !checkBoxes.prop("checked"));
  };

  function setcheckboxCLick() {
    setIsChecked(!isChecked);
    if ($(".SelectAll").prop("checked") == true) {
      $(".mycheck").prop("checked", true);
    } else {
      $(".mycheck").prop("checked", false);
    }
  }

  function ROleOnchange(e) {
    const val = e.value;
    ConfigurationServiceobj.Create_Role(val)
      .then((response) => {
        var modPrivilegeLst = response.data.modPrivilegeLst;
        var roladate = response.data;
        setdata(modPrivilegeLst);

        const temp = {
          roleID: roladate.roleID,
          roleName: roladate.roleName,
          privilegeIDs: roladate.privilegeIDs,
          description: roladate.description,
        };

        Object.keys(temp).map((key, i) => setValue(key, temp[key]));

        $(".main-loader").hide();
      })
      .catch((error) => {
        $(".main-loader").hide();
      });
  }
  const handleKeyPress = (e) => {
    const inputValue = e.key;

    if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(inputValue)) {
      e.preventDefault();
      setError("Role Name cannot contain special characters.");
    } else {
      setError("");
    }
  };
  return (
    <>
      <div>
        <form
          id="RoleConfiguration"
          onKeyPress={(e) => {
            if (e.key == "Enter") {
              e.preventDefault();
            }
          }}
          onSubmit={(e) => handleSubmit(onSubmit)(e)}
        >
          <div className="row">
            <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
              <div className="form-group mb-3">
                <label>
                  Role Name <span className="text-danger">*</span>
                </label>
                <input
                  type="hidden"
                  id="privilegeIDs"
                  value={register.privilegeIDs}
                />

                {roleID == 0 ? (
                  <input
                    type="text"
                    className="form-control"
                    maxLength={100}
                    placeholder="Role Name"
                    {...register("roleName", {
                      required: "Role Name is Required",
                    })}
                    onChange={() => setError("roleName", "")}
                  // onKeyDown={(event) => onKeyDown(event)}

                  // onKeyPress={(e) => {
                  //   if (
                  //     /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(e.key)
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                  />
                ) : (
                  <input
                    type="text"
                    className="form-control"
                    maxLength={100}
                    placeholder="Role Name"
                    {...register("roleName", {
                      required: "Role Name is Required",
                    })}
                    onChange={() => setError("roleName", "")}
                  // onKeyDown={(event) => onKeyDown(event)}
                  // onKeyPress={(e) => {
                  //   if (
                  //     /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(e.key)
                  //   ) {
                  //     e.preventDefault();
                  //   }
                  // }}
                  />
                )}
                {errors.roleName && (
                  <span className="text-danger">{errors.roleName.message}</span>
                )}
              </div>
            </div>
            <div className="col-md-4 col-sm-12 col-lg-4 col-xl-4">
              <div className="form-group mb-3">
                <label>Role Description </label>

                <textarea
                  type="text"
                  className="form-control"
                  maxLength={20}
                  placeholder="Description"
                  name="Description"
                  {...register("roleDesc", {})}
                ></textarea>
              </div>
            </div>
            <div className="col-md-4">
              <div
                className="form-group mb-3"
                style={{
                  float: "right",
                  display: "flex",
                  position: "relative",
                  top: "30%",
                  right: "7%",
                }}
              >
                <input
                  type="checkbox"
                  className="form-control SelectAll"
                  maxLength={20}
                  style={{ marginRight: "10px" }}
                  onChange={() => setcheckboxCLick()}
                  checked={isChecked}
                />
                <label style={{ marginRight: "10px", marginTop: "10px" }}>
                  Select All
                </label>
              </div>
            </div>
          </div>

          <div className=" text-right">
            {/* <Tooltip title="Select All" >

                        <label htmlFor="icon-button-file">
                            <IconButton
                                className="HoverDefaultIcon text-right"
                                onClick={() => SelectAll()}
                                component="span"
                            >
                                <DoneAll> </DoneAll>
                            </IconButton>
                        </label>


                    </Tooltip> */}
          </div>

          <table
            id="request_table1"
            className="table table-striped custom-table"
          >
            <thead>
              <tr>
                <th>Module Name </th>
                <th>Create</th>
                <th>Edit</th>
                <th>Delete</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody id="Table_body_Privillages">
              {data ? getArray(data) : <></>}
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-10 col-sm-12 col-lg-10 col-xl-10"></div>
            <div className="col-md-2 col-sm-12 col-lg-2 col-xl-2">
              <div className="text-right ">
                <button className="btn btn-primary btn-sm" type="submit">
                  <i className="la la-save"></i> Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
