import React, { useState, useEffect, useContext, useReducer } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReimbursementService from "../../api/ReimbursementService";
import Toast from "../Toastify";
import { AuthContext } from "../../Context/AuthContext";
import $ from "jquery";
import "bootstrap/js/dist/modal";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ApprovalService from "../../api/ApprovalService";
import Axios from "axios";
import { saveAs } from "file-saver";
import 'react-datepicker/dist/react-datepicker.css';

const RemObj = new ReimbursementService();
const _ApprovalService = new ApprovalService();

const AdvanceBillupload = (props) => {
  const { ID, StudentId } = props;
  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [funTypeList, setFunTypeList] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [TicketCode, setTicket] = useState("");
  const [amount, setAmount] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [advBillDate, setAdvBillDate] = useState(null);
  const [advBillNumber, setAdvBillNumber] = useState("");
  const [FundDetail, setFundDetails] = useState("");
  const [TicketId, setTicketId] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [filepath, Setfilepath] = useState("");
  const [fileerror, setfileerror] = useState("");
  const [downloadpath, setdownloadpath] = useState("");
  const [StudentRequestList, SetStudentRequestList] = useState([]);
  const isSubmit = 0;
  const [TicketById, setTicketById] = useState([]);
  const [funtyppp, setfuntyppp] = useState("");
  const maxDate = new Date();
  const minDate = new Date(2020, 0, 1);
  var today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    

  useEffect(() => {
    var roleid = cookies.roleID;
    if (ID > 0) {
      RemObj.Get_RequestById(ID, roleid, 1)
        .then((response) => {
          const data = response.data;

          if (data && data.ticket && data.ticket.length > 0) {
            const firstTicket = data.ticket[0];
            setTicket(firstTicket.ticketNumber);
            setSelectedTicket({
              value: firstTicket.fundType,
              label: firstTicket.type,
            });
            setfuntyppp(firstTicket.type);
            setAmount(firstTicket.amount);
            setBillNumber(firstTicket.billNumber);
            setFundDetails(firstTicket.ticketDetails);
            Setfilepath(firstTicket.fileName);
            setdownloadpath(firstTicket.downloadPath);

            // setdownloadpath(
            //   Axios.defaults.baseURL + "/Uploadfiles/" + firstTicket.fileName
            // );
            console.log("Fetched ticket data:", firstTicket);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    if (StudentId != null) {
      _ApprovalService
        .Student_CreditedInfo(StudentId)
        .then((res) => {
          if (res != null && res.data != null && res.data.length > 0) {
            const result = res.data;
            SetStudentRequestList(result);
          }
          $(".loader-container").hide();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ID]);

  useEffect(() => {
    if (ID == 0) {
      RemObj.Get_RequestDetails()
        .then((response) => {
          const data = response.data;

          if (data && data.funType && data.funType.length > 0) {
            setFunTypeList(data.funType);
          }

          if (data && data.ticketCode) {
            setTicket(data.ticketCode);
          }
          console.log(funTypeList);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  const handleAmountChange = (event) => {
    const input = event.target.value;
    const numericInput = input.replace(/[^0-9.]/g, ""); // Allow digits and decimal point

    const parts = numericInput.split(".");
    const integerPart = parts[0];
    let decimalPart = parts[1];

    if (integerPart.length <= 10) {
      if (decimalPart !== undefined) {
        decimalPart = decimalPart.slice(0, 2);
      }

      const formattedIntegerPart = new Intl.NumberFormat("en-IN").format(
        integerPart
      );

      let formattedAmount = formattedIntegerPart;

      if (decimalPart !== undefined) {
        formattedAmount += "." + decimalPart;
      }

      setAmount(formattedAmount);
    } else {
      const trimmedIntegerPart = integerPart.slice(0, 10);
      const formattedIntegerPart = new Intl.NumberFormat("en-IN").format(
        trimmedIntegerPart
      );

      let formattedAmount = formattedIntegerPart;

      if (decimalPart !== undefined) {
        decimalPart = decimalPart.slice(0, 2); // Limit decimal part to 2 digits
        formattedAmount += "." + decimalPart;
      }

      setAmount(formattedAmount);
    }
  };

  const handleBillNumberChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^a-zA-Z0-9/-]/g, ""); // Allow letters, numbers, /, and -
    setBillNumber(sanitizedInput);
  };
  const handleAdvBillNumberChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^a-zA-Z0-9/-]/g, ""); // Allow letters, numbers, /, and -
    setAdvBillNumber(sanitizedInput);
  };


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (validateFileExtension(file)) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      setfileerror(
        "Invalid file format. Please select a valid file.It allows .jpg, .jpeg, .png, .pdf"
      );
    }
  };

  const validateFileExtension = (file) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };

  const [errors, setErrors] = useState({
    FundType: "",
    Amount: "",
    billNumber: "",
    advBillDate:"",
    advBillNumber:"",
    UploadPath: "",
    FundDetails: "",

  });

  const validateForm = () => {
    let newErrors = { ...errors };
    newErrors.advBillDate=advBillDate?"":"Advance Bill Date is required.";
    newErrors.advBillNumber=advBillNumber?"":"Advance Bill Number is required."
    newErrors.UploadPath = selectedFile ? "" : "Advance Bill Upload is required.";

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const HandleSubmit = (e, skipValidation = false) => {
    e.preventDefault();

      const formData = new FormData();
      if(validateForm()){
      formData.append("TicketId", ID);
      formData.append("TicketNumber",TicketCode);
      formData.append("AdvBillDate", advBillDate);
      formData.append("AdvBillNumber",advBillNumber);
      formData.append("UploadPath", selectedFile);
  
      formData.append("CreatedBy", cookies.userID);
      formData.append("CreatedDate", date);
 
      Submit_Request(formData);

    }
    
  };

  const Submit_Request = (formData) => {
   debugger
    $(".loader-container").show();
    RemObj.UploadBill(formData)
      .then((response) => {
        console.log(response);
        $(".loader-container").hide();

        if (ID == 0) {
          Toast("Request Created Successfully", "success");
        } else {
          Toast("Request Updated Successfully", "success");
        }
        setTimeout(() => {
          $(".close").trigger("click");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error while saving:", error);
      });
  };

  function refreshPage() {
    window.location.reload(false);
  }
  function download(url) {
    RemObj.Download(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }

  return (
    <form
      id="PersonalDetailsCreate"
      onKeyPress={(e) => {
        if (e.key == "Enter") {
          e.preventDefault();
        }
      }}
      onSubmit={HandleSubmit}
    >
      <div class="row">
        <div class="col-lg-8">
          <div className="row">
            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">Request Number</label>
                <input
                  type="text"
                  name="TicketNumber"
                  class="form-control"
                  placeholder=""
                  value={TicketCode}
                  disabled={true}
                />
              </div>
            </div>

            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Fund Type <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="Amount"
                  className="form-control"
                  placeholder="Amount"
                  value={funtyppp}
                  onChange={(e) => {
                    handleAmountChange(e); // Call the existing handler
                    setErrors({ ...errors, Amount: "" }); // Clear the error message
                  }}
                  disabled={true}
                />

                <div class="invalid-feedback" style={{ display: "block" }}>
                  {errors.FundType}
                </div>
              </div>
            </div>

            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Amount <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="Amount"
                  className="form-control"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => {
                    handleAmountChange(e); // Call the existing handler
                    setErrors({ ...errors, Amount: "" }); // Clear the error message
                  }}
                  disabled={true}
                />
                <div class="invalid-feedback" style={{ display: "block" }}>
                  {errors.Amount}
                </div>
              </div>
            </div>
            {funtyppp === 'Reimbursement Request' &&<>
            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Bill Number <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="billNumber"
                  className="form-control"
                  placeholder="Bill Number"
                  value={billNumber}
                  onChange={(e) => {
                    handleBillNumberChange(e); // Call the existing handler
                    setErrors({ ...errors, billNumber: "" }); // Clear the error message
                  }}
                  disabled={true}
                  maxLength={20}
                />
                <div class="invalid-feedback" style={{ display: "block" }}>
                  {errors.billNumber}
                </div>
              </div>
            </div>
            </>}

            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">{ID > 0 && filepath !== null ? "View Proof" : "Upload Proof"}</label>
                <div>
                  {ID > 0 && filepath !== null ? (
                    <>
                      {/* <a onClick="download(filepath)" download> */}
                      <a onClick={() => download(filepath)} title="Download">
                        <CloudDownloadIcon className="download-icon" />
                        {/* <div>{filepath}</div> */}
                      </a>
                    </>
                  ) : (
                    <>
                      <h4>No file uploaded</h4>
                    </>
                  )}

                  {/* <div class="invalid-feedback" style={{ display: "block" }}>
                    {fileerror}
                  </div>
                  <div class="invalid-feedback" style={{ display: "block" }}>
                    {errors.UploadPath}
                  </div> */}
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Fund Details<span class="text-danger">*</span>
                </label>
                <div>
                  <textarea
                    name="FundDetails"
                    class="form-control"
                    value={FundDetail}
                    placeholder=""
                    onChange={(e) => {
                      setFundDetails(e.target.value);
                      setErrors({ ...errors, FundDetails: "" }); // Clear the error message
                    }}
                    disabled={true}
                  ></textarea>
                  <div class="invalid-feedback" style={{ display: "block" }}>
                    {errors.FundDetails}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Advance Bill Number <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="advBillNumber"
                  className="form-control"
                  placeholder="
                Advance Bill Number"
                  value={advBillNumber}
                  onChange={(e) => {
                    handleAdvBillNumberChange(e); // Call the existing handler
                    setErrors({ ...errors, advBillNumber: "" }); // Clear the error message
                  }}
                 // disabled={true}
                  maxLength={20}
                />
                <div class="invalid-feedback" style={{ display: "block" }}>
                  {errors.advBillNumber}
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Advance Bill Date <span class="text-danger">*</span>
                </label>
                <DatePicker
                    selected={advBillDate}
                    onChange={(date) => setAdvBillDate(date)}
                    minDate={minDate}
                    maxDate={maxDate}
                    placeholderText="Select Advance Bill Date"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                <div class="invalid-feedback" style={{ display: "block" }}>
                  {errors.advBillDate}
                </div>
              </div>
            </div>

            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">Upload Advance bill</label>
                <span class="text-danger">*</span>
                <div>
                  <input
                    type="file"
                    name="UploadPath"
                    className="form-control"
                    accept=".jpg, .jpeg, .png, .pdf"
                    onChange={(e) => {
                      handleFileChange(e); // Call the existing handler
                      setErrors({ ...errors, UploadPath: "" }); // Clear the error message
                    }}
                  />
                 

                  <div class="invalid-feedback" style={{ display: "block" }}>
                    {fileerror}
                  </div>
                  <div class="invalid-feedback" style={{ display: "block" }}>
                    {errors.UploadPath}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 modal-footer no-bd">
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => refreshPage()}
                  >
                    Cancel
                  </button>
                  {/* <button
                    class="btn btn-info float-right"
                    style={{ marginLeft: "10px" }}
                    onClick={(e) => HandleSubmit(e, true)}
                  >
                    Save
                  </button> */}
                  <button
                    class="btn btn-primary float-right"
                    style={{ marginLeft: "10px" }}
                    //onClick={validateForm}
                  >
                    Upload advance bill
                  </button>
                </div>
          </div>
        </div>
        <div class="col-lg-4">
          {StudentRequestList != null && StudentRequestList.length > 0 ?
           <div className="trans">
              {/* <p> <span style={{ fontWeight: "bold"}}>Allocated Amount :</span> {StudentRequestList[0].allocatedAmount}</p> */}
              <p><span style={{ fontWeight: "bold"}}>Total Credited Amount :</span> {StudentRequestList[0].totalCreditedAmount}</p>
              {/* <p><span style={{ fontWeight: "bold"}}>Balance Amount :</span> {StudentRequestList[0].balanceAmount}</p> */}
            </div> : <></>
          }
          <div
            className={
              StudentRequestList.length > 10 ? "trans-scroll" : "trans"
            }
          >
            <h3 style={{ fontWeight: "bold", color: "#1da837" }}>
              {" "}
              Transaction History
            </h3>
            <table
                    id="add-row"
                    className="display table table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <th> Date</th>
                        <th> Fund Type</th>
                        <th> Amount</th>
                        <th> View Bill</th>
                        <th> View Advance Bill</th>
                      </tr>
                    </thead>
                    <tbody>
                      {StudentRequestList.map((item, index) => (
                        <tr key={index}>
                          <td>{item.creditedDate}</td>
                          <td>{item.fundType}</td>
                          <td>{item.creditedAmount}</td>
                          <td>
                      
                      {item.billUrl != null ?
                       <a target="_blank" onClick={() => download(item.billUrl)} title="Download">
                        <CloudDownloadIcon className="download-icon" />
                        {/* <div>{filepath}</div> */}
                      </a> : <></>}
                      </td>
                      <td>
                      
                      {item.uploadAdvanceBill != null ?
                       <a target="_blank" onClick={() => download(item.uploadAdvanceBill)} title="Download">
                        <CloudDownloadIcon className="download-icon" />
                        {/* <div>{filepath}</div> */}
                      </a> : <></>}
                      </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
          </div>
        </div>
      </div>
    </form>
  );
};
export default AdvanceBillupload;
