import React, { useState, useReducer, useRef, useContext } from "react";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Swal from 'sweetalert2';
import AddIcon from "@mui/icons-material/Add";
// import Button from "@material-ui/core/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StudentMain from "./StudentMain";
import StudentService from "../../api/StudentService";
import { useEffect } from "react";
import swal from "sweetalert";
import Toast from "../Toastify";
import $, { data } from "jquery";
import axios from "axios";
//import XLSX from "xlsx";
import { AuthContext } from "../../Context/AuthContext";
// import { LaptopWindows } from "@material-ui/icons";
import DemandTemplate from "../../assets/Students_Master_Data.xlsx";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import SemesterResult from "./SemesterResult";
import SemesterResultView from "./SemesterResultView";
import { imgURL } from "../../api/AxiosService";
import { Avatar } from "@mui/material";


const StudentServiceObj = new StudentService();

function StudentList() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [StudentID, SetStudentID] = useState(0);
  const [StudentModel, SetStudentModel] = useState([]);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const { cookies, logout, removeCookie, setCookie } = useContext(AuthContext);
  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);

  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    // Trigger a click event on the hidden file input
    fileInputRef.current.click();
  };
  const [downloadComplete, setDownloadComplete] = useState(false);
  const handleDownloadComplete = () => {
    setDownloadComplete(true);
  };

  const handleFileSelected = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
      // Handle the selected Excel file here
      console.log("Selected Excel file:", selectedFile);
    } else {
      // Display an error message or handle the invalid file type
      console.error("Invalid file type. Please select an Excel file (.xlsx)");
    }
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = (e) => {
      var filestr = e.target.result.split(",");
      SavaFile(filestr[1]);
    };
  };

  const SavaFile = (str) => {
    debugger;
    const obj = {
      str: str,
      EmployeeID: Number(cookies.userID),
    };

    $(".loader-container").show();

    StudentServiceObj.BulkUpload(obj)
      .then((response) => {
        debugger
        $(".loader-container").hide();

        // Check if response.data.value is available
        if (response.data.responseObject.value.responseObject) {
          const responseData = JSON.parse(response.data.responseObject.value.responseObject);

          const successCount = responseData.TotalCount;
          const errorMessage = responseData.Errors;
          const errorCount = responseData.Errors.length;
          const updateCount = responseData.UpdateCount;
          const totalCount = successCount + errorCount;
          if (totalCount == successCount) {
            const successMessage =
              updateCount > 0
                ? `${responseData.TotalCount} Student(s) Profile Uploaded successfully`
                : `Student(s) Profile Updated successfully`;

            Toast(successMessage, "success");
            setTimeout(() => {
              $(".close").trigger("click");
            }, 2000);
            refreshPage();
          } else if (errorCount > 0) {
            Swal.fire({
              icon: 'warning',
              title: 'Student Profile Upload Details',
              customClass: 'swal-wide',
              html: `
              <style>
                table {
                  border-collapse: collapse;
                  width: 100%;
                  margin-bottom: 10px;
                }
                th, td {
                  border: 1px solid #ddd;
                  padding: 8px;
                  text-align: center;
                }
                .error-container {
                  max-height: 100px;
                  overflow-y: auto;
                  margin-top: 10px;
                  word-wrap: break-word;
                }
                .error-list {
                  list-style-type: none;
                  padding: 0;
                  margin: 0;
                }
                .error-list-item {
                  margin-bottom: 5px;
                  font-size: 13px;
                  text-align:left;
                }
                .error-head{
                  font-size: 13px;
                }
                
              </style>
              <table>
                <thead>
                  <tr>
                    <th>Total Count</th>
                    <th>Success Count</th>
                    <th>Error Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>${totalCount}</td>
                    <td>${successCount}</td>
                    <td>${errorCount}</td>
                  </tr>
                </tbody>
              </table>
                    <div class="error-container">
            <div ><b class="error-head">Error Messages:</b></div>
            <div class="error-list">
              ${errorMessage.map(msg => `<div class="error-list-item">${msg}</div>`).join('')}
            </div>
          </div>
            `,
            }).then((result) => {
              if (result.isConfirmed) {
                refreshPage();
              }
            });
          }



        } else {
          // Handle unexpected response format
          console.error("Unexpected response format:", response);
          $(".loader-container").hide();
          Toast("In Excel, fill the all mandatory fields.", "warning");
          setTimeout(() => {
            $(".close").trigger("click");
          }, 2000);
          refreshPage();
        }
      })
      .catch((error) => {
        console.error(error);
        $(".loader-container").hide();
        Toast("An error occurred while uploading students", "error");
        refreshPage();
      });
  };


  function HandleClick(id) {
    SetStudentID(id);

    //PageRender();
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    $(".submenu").removeClass("active");
    $("#StudentCreation").addClass("active");

    $(".loader-container").show();
    //SetStudentID(0);
    StudentServiceObj.GetstudentList()
      .then((res) => {
        debugger
        var data = res.data;

        if (data != null) {
          SetStudentModel(data);
        }
        $(".loader-container").hide();
      })
      .catch((error) => {
        console.log(error);
        $(".loader-container").hide();
      });
  }, [GridRender]);

  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
  }
  function DeleteStudent(id) {
    swal({
      title: "Are You Sure?",
      text: "Do you want to delete this request?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        StudentServiceObj.DeleteStudent(id)
          .then((res) => {
            Toast("Student deleted succesfully", "success");

            setGridRender();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  const columns = [
    {
      name: "studentId",
      label: "studentId",
      options: {
        filter: true,
        sort: true,
        display: "none",
      },
    },
    {
      name: "studentPhoto",
      label: "Profile",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Avatar variant="rounded" src={imgURL+ value}></Avatar>)
        }
      },
    },
    {
      name: "userName",
      label: "User Name",
    },
    {
      name: "registerNo",
      label: "Register No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "studentName",
      label: "Student Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "mobileNumber",
      label: "Mobile Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "collegeName",
      label: "College Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "course",
      label: "Course",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "joiningDate",
      label: "Academic Year",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <span style={{ color: value === "Active" ? "green" : "red" }}>
            {value}
          </span>
        ),
      },
    },

    {
      name: "OPTION",
      label: "Option",
      options: {
        filter: true,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          const value = tableMeta.rowData[0];
          return (
            <div className="center">
              {priviledge.includes("STUDENTCREATE") && (
                <>
                  <IconButton
                    title="Edit"
                    data-bs-toggle="modal"
                    data-bs-target="#addStudent"
                    onClick={() => HandleClick(value)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    title="Delete"
                    onClick={() => DeleteStudent(value)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </>
              )}

              {/* {priviledge.includes("STUDENTCREATE") && (
                <IconButton
                  title="Result"
                  data-bs-toggle="modal"
                  data-bs-target="#addResult"
                  onClick={() => HandleClick(value)}
                >
                  <ChecklistRtlIcon />
                </IconButton>
              )} */}

              {priviledge.includes("STUDENTRESULTVIEW") && (
                <IconButton
                  title="ResultView"
                  data-bs-toggle="modal"
                  data-bs-target="#ResultView"
                  onClick={() => HandleClick(value)}
                >
                  <ChecklistRtlIcon />
                </IconButton>
              )}
            </div>
          );
        },
      },
    },
  ];
  const HeaderElements = () => (
    <>
      <Tooltip title="Create Student">
        <label htmlFor="icon-button-file">
          <IconButton data-bs-toggle="modal" data-bs-target="#addStudent">
            <AddIcon />
          </IconButton>
        </label>
      </Tooltip>

      <button className="btn btn-primary" onClick={handleFileUpload}>
        <CloudUploadIcon className="download-icon" style={{ marginRight: '6px' }} />
        Upload File
      </button>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelected}
        accept=".xlsx"
      />

      <a
        className={`btn btn-primary download-button ${downloadComplete ? 'd-none' : ''}`}
        href={DemandTemplate}
        download
        onClick={handleDownloadComplete}
        style={{ marginLeft: '1px' }}
      >
        <CloudDownloadIcon className="download-icon" />
        <span className="ml-1"> Student Template</span>
      </a>
    </>
  );

  return (
    <>
      <div class="main-panel">
        <div class="content">
          <div className="page-inner">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <MUIDataTable
                        data={StudentModel}
                        title="Students"
                        columns={columns}
                        options={{
                          responsive: true,
                          download: true,
                          print: true,
                          selectableRows: false,
                          filter: true,
                          viewColumns: true,
                          customToolbar: () => (
                            <>
                              <HeaderElements />
                            </>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div
          class="modal fade"
          id="addStudent"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title">
                  {StudentID == 0 ? (
                    <span class="fw-mediumbold">Add Student</span>
                  ) : (
                    <span class="fw-mediumbold">Edit Student</span>
                  )}
                </h3>
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={refreshPage}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <StudentMain Id={StudentID} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div
          class="modal fade"
          id="addResult"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title">
                  <span class="fw-mediumbold">Add Semester Results</span>
                </h3>
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={refreshPage}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <SemesterResult Id={StudentID} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div
          class="modal fade"
          id="ResultView"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title">
                  <span class="fw-mediumbold">Semester Result View</span>
                </h3>
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={refreshPage}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <SemesterResultView Id={StudentID} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default StudentList;
