import React from "react";
import AxiosServices from "./AxiosService";

const Auth = new AxiosServices();

class ApprovalService {
  ApproverRequest_List = async (Id, RoleName, RoleId) => {
    return Auth.get(
      `/Approval/RequestList?Id=${Id}&RoleName=${RoleName}&RoleId=${RoleId}`
    );
  };

  ApproverBillRequest_List = async (Id, RoleName, RoleId) => {
    return Auth.get(
      `/Approval/ApproverBillRequest_List?Id=${Id}&RoleName=${RoleName}&RoleId=${RoleId}`
    );
  };
  Get_RequestById = async (ID, RoleId,IsView) => {
    return Auth.get(`/Approval/Get_RequestById?id=${ID}&RoleId=${RoleId}&IsView=${IsView}`);
  };

  SubmitRequest = async (data) => {
    return Auth.post(`/Approval/Approve_Request`, data);
  };

  ApproveAdvanceBill_Request = async (data) => {
    return Auth.post(`/Approval/ApproveAdvanceBill_Request`, data);
  };
  Accountant_Submit = async (data) => {
    return Auth.post(`/Approval/Accountant_Approve`, data);
  };

  Student_CreditedInfo = async (Id) => {
    return Auth.get(`/Approval/Student_CreditedInfo?Id=${Id}`);
  };

  DashboardData = async (Code, Month, year, Id) => {
    return Auth.get(
      `/Settings/DashboardData?Code=${Code}&Month=${Month}&year=${year}&Id=${Id}`
    );
  };

  Accountant_Template = async (Id, RoleName, RoleId) => {
    return Auth.get(
      `/Approval/Accountant_Template?Id=${Id}&RoleName=${RoleName}&RoleId=${RoleId}`
    );
  };

  
  BulkUpload = async (data) => {
    return Auth.post("/Approval/BulkUploads", data);
  };
}

export default ApprovalService;
