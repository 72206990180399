import React, { useState, useReducer, useEffect, useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ApprovalService from "../../api/ApprovalService";
import $ from "jquery";
import Toast from "../Toastify";
import Axios from "axios";
import { saveAs } from "file-saver";
import ReimbursementService from "../../api/ReimbursementService";
import FundChatBox from "./FundChatBox";

const RemObj = new ReimbursementService();
const _ApprovalService = new ApprovalService();

const ActioPageView = (props) => {
  const { ID, Status, StageId, StudentId, Role,setApproveGridRender } = props;

  const { cookies, logout } = useContext(AuthContext);

  const [funTypeList, setFunTypeList] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [TicketCode, setTicket] = useState("");
  const [amount, setAmount] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [billDate,setBillDate]=useState("");
  const [FundDetail, setFundDetails] = useState("");
  const [TicketId, setTicketId] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [filepath, Setfilepath] = useState("");
  const [fileerror, setfileerror] = useState("");
  const [downloadpath, setdownloadpath] = useState("");
  const [commet, setComment] = useState("");
  const [error, seterror] = useState("");
  const [StudentName, SetStudentName] = useState("");
  const [registerNo, setRegisterNo] = useState("");
  const [accomType, setAccomType] = useState("");
  const [course, setCourse] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [batchNo, setBatchNo] = useState("");
  const [StudentRequestList, SetStudentRequestList] = useState([]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }


  useEffect(() => {
    var empId = cookies.roleID;
    if (ID > 0) {
      _ApprovalService
        .Get_RequestById(ID, empId, 1)
        .then((response) => {
          const data = response.data;

          if (
            data &&
            data.approvalRequests &&
            data.approvalRequests.length > 0
          ) {
            const firstTicket = data.approvalRequests[0];
            setTicket(firstTicket.ticketNumber);
            setSelectedTicket(firstTicket.type);
            setAmount(firstTicket.amount);
            setBillNumber(firstTicket.billNumber);
            if (firstTicket?.billDate) {
              setBillDate(formatDate(firstTicket.billDate));
            } else {
              setBillDate("");
            }
            setFundDetails(firstTicket.ticketDetails);
            Setfilepath(firstTicket.fileName);

            var path =
              Axios.defaults.baseURL +
              "/" +
              "Uploadfiles" +
              "/" +
              firstTicket.fileName;
            setdownloadpath(path);
            SetStudentName(firstTicket.studentName);
            setRegisterNo(firstTicket.registerNo);
            setAccomType(firstTicket.accommodationType);
            setCourse(firstTicket.course);
            setSpecialization(firstTicket.specialization);
            setCollegeName(firstTicket.collegeName);
            setBatchNo(firstTicket.batchNo);

            setComment(firstTicket.firstLevelComment);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    if (StudentId > 0) {
      _ApprovalService
        .Student_CreditedInfo(StudentId)
        .then((res) => {
          if (res != null && res.data != null && res.data.length > 0) {
            const result = res.data;
            SetStudentRequestList(result);
          }
          $(".loader-container").hide();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ID]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }

  const HandleSubmit = () => {
   

    var flag = false;
    if (Status == -1) {
      if (commet == null || commet == "") {
        seterror("Please enter your remarks");
        flag = true;
      }
    }

    if (flag == false) {
      const requestData = {
        TicketId: ID,
        // ApproverStageId: StageId,
        ApproverStageId: 0,
        FirstLevelApprovedBy: cookies.userID,
        FirstLevelComment: commet,
        RoleName: cookies.roleName,
        IsApproved: Status,
      };

      $(".loader-container").show();

      _ApprovalService
        .SubmitRequest(requestData)
        .then((response) => {
          if (ID == 0) {
            Toast("Request Approved Successfully", "success");
          } else {
            Toast("Request Approved Successfully", "success");
          }
          $(".close").trigger("click");
          $(".loader-container").hide();
         // setApproveGridRender();
        })
        .catch((error) => {
          console.error("Error while saving:", error);
        });
    }
  };

  function download(url) {
    RemObj.Download(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }

  return (
    <div class="row">
      <div class="col-lg-7">
        <div class="row">
          <div class="col-lg-6 mb-2">
            <label class="text-label form-label">Request Number</label>
            <input
              type="text"
              name="RegNo"
              class="form-control"
              placeholder=""
              value={TicketCode}
              disabled={true}
            />
          </div>
          <div className="col-lg-6 mb-2">
            <label class="text-label form-label">Student Name &nbsp;<span data-toggle="modal" data-target="#studentViewModal"><i className="fa fa-eye"></i></span> </label>
            <input
              type="text"
              name="RegNo"
              className="form-control"
              placeholder=""
              value={StudentName}
              disabled={true}
            />
          </div>
          {/*  Modal component */}
          <div className="modal " id="studentViewModal" tabIndex="-1" role="dialog" aria-labelledby="studentViewModalTitle" aria-hidden="true"  >
            <div className="modal-dialog" role="document">
              <div className="modal-content studentinfomodal">
                
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span className="btnclose" aria-hidden="true">&times;</span>

                  </button>
                <div className="modal-body">
                  {[
                    { label: 'Student Name', value: StudentName },
                    { label: 'Register Number', value: registerNo },
                    { label: 'Course', value: course },
                    { label: 'Specialization', value: specialization },
                    { label: 'College Name', value: collegeName },
                    { label: 'Batch No', value: batchNo },
                    { label: 'Accommodation Type', value: accomType },
                  ].map((item, index) => (
                    <div className="row mb-2" key={index}>
                      <div className="col-5">
                        <b>{item.label}</b>
                      </div>
                      <div className="col-1 text-center">
                        :
                      </div>
                      <div className="col-6">
                        {item.value}
                      </div>
                    </div>
                  ))}
                </div>


              </div>
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">Fund Type</label>
            <input
              type="text"
              name="RegNo"
              class="form-control"
              value={selectedTicket}
              disabled={true}
            />
          </div>

          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">Amount</label>
            <input
              type="text"
              name="Amount"
              class="form-control"
              value={amount}
              disabled={true}
            />
          </div>
          {selectedTicket == "Reimbursement Request" && <>
          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">Bill Number</label>
            <input
              type="text"
              name="firstName"
              class="form-control"
              value={billNumber}
              disabled={true}
            />
          </div>
          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">Bill Date</label>
            <input
              type="text"
              name="billDate"
              class="form-control"
              value={billDate}
              disabled={true}
            />
          </div>
          </>
}


          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">
              {selectedTicket == "Advance Request"
                ? downloadpath != null
                  ? "View Reference / Proof"
                  : "Reference / Proof"
                : filepath !== null
                  ? "View Bill"
                  : "Upload Bill"}
            </label>
            <div>
              <span className="file-info">
                {/* <a href={downloadpath} download>
                  <CloudDownloadIcon className="download-icon" />
                  <div>{filepath}</div>
                </a> */}

                <a onClick={() => download(filepath)}>
                  <CloudDownloadIcon className="download-icon" />
                  {/* <div>{filepath}</div> */}
                </a>
              </span>
            </div>
          </div>
          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">
              Fund Details<span class="text-danger">*</span>
            </label>
            <div>
              <textarea
                name="lastName"
                class="form-control"
                value={FundDetail}
                disabled={true}
              ></textarea>
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            {Status === 1 ? (
              <div class="mb-3">
                <label class="text-label form-label">Approver Remarks</label>
                <div>
                  <textarea
                    name="firstLevelComment"
                    class="form-control"
                    placeholder="Enter your remarks"
                    value={commet}
                    onChange={(e) => setComment(e.target.value)}
                    disabled={true}
                  ></textarea>
                </div>
              </div>
            ) : (
              <div class="mb-3">
                <label class="text-label form-label">
                  Rejected Remarks <span class="text-danger">*</span>
                </label>
                <div>
                  <textarea
                    name="firstLevelComment"
                    class="form-control"
                    placeholder="Enter your remarks"
                    value={commet}
                    onChange={(e) => {
                      setComment(e.target.value);
                      seterror("");
                    }}
                    disabled={true}
                  ></textarea>
                </div>
                <div class="invalid-feedback" style={{ display: "block" }}>
                  {error}
                </div>
              </div>
            )}
          </div>

          <div>
            <FundChatBox ID={ID} />
          </div>
        </div>
      </div>

      <div class="col-lg-5">
        {StudentRequestList != null && StudentRequestList.length > 0 ? (
          <div className="trans">
            {/* <p> <span style={{ fontWeight: "bold"}}>Allocated Amount :</span> {StudentRequestList[0].allocatedAmount}</p> */}
            <p>
              <span style={{ fontWeight: "bold" }}>
                Total Credited Amount :
              </span>{" "}
              {StudentRequestList[0].totalCreditedAmount}
            </p>
            {/* <p><span style={{ fontWeight: "bold"}}>Balance Amount :</span> {StudentRequestList[0].balanceAmount}</p> */}
          </div>
        ) : (
          <></>
        )}
        <div
          className={StudentRequestList.length > 10 ? "trans-scroll" : "trans"}
        >
          <h3 style={{ fontWeight: "bold", color: "#1da837" }}>
            {" "}
            Transaction History
          </h3>
          <table
            id="add-row"
            className="display table table-striped table-hover"
          >
            <thead>
              <tr>
                <th> Date</th>
                <th> Fund Type</th>
                <th> Amount</th>
                <th> View Bill</th>
                <th> View Advance Bill</th>
              </tr>
            </thead>
            <tbody>
              {StudentRequestList.map((item, index) => (
                <tr key={index}>
                  <td>{item.creditedDate}</td>
                  <td>{item.fundType}</td>
                  <td>{item.creditedAmount}</td>
                  <td>
                    {item.billUrl != null ? (
                      <a
                        target="_blank"
                        onClick={() => download(item.billUrl)}
                        title="Download"
                      >
                        <CloudDownloadIcon className="download-icon" />
                        {/* <div>{filepath}</div> */}
                      </a>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td>
                    {item.uploadAdvanceBill != null ? (
                      <a
                        target="_blank"
                        onClick={() => download(item.uploadAdvanceBill)}
                        title="Download"
                      >
                        <CloudDownloadIcon className="download-icon" />
                        {/* <div>{filepath}</div> */}
                      </a>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default ActioPageView;
