import React, { useState, useReducer, useRef, useContext } from "react";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StudentMain from "./StudentMain";
import StudentService from "../../api/StudentService";
import { useEffect } from "react";
import swal from "sweetalert";
import Toast from "../Toastify";
import $, { data } from "jquery";
import axios from "axios";
import { AuthContext } from "../../Context/AuthContext";
import DemandTemplate from "../../assets/Students_Master_Data.xlsx";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";
import SemesterResult from "./SemesterResult";
import SemesterResultView from "./SemesterResultView";

const StudentServiceObj = new StudentService();

function StudentBankDetails() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [StudentID, SetStudentID] = useState(0);
  const [StudentModel, SetStudentModel] = useState([]);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const { cookies, logout, removeCookie, setCookie } = useContext(AuthContext);
  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);

  const fileInputRef = useRef(null);

  useEffect(() => {
    $(".submenu").removeClass("active");
    $("#StudentBankdetails").addClass("active");

    $(".loader-container").show();
    window.scrollTo(0, 0);
    //SetStudentID(0);
    StudentServiceObj.GetstudentList()
      .then((res) => {
        var data = res.data;

        if (data != null) {
          SetStudentModel(data);
        }
        $(".loader-container").hide();
      })
      .catch((error) => {
        console.log(error);
        $(".loader-container").hide();
      });
  }, [GridRender]);

  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
  }
  function DeleteStudent(id) {
    swal({
      title: "Are You Sure?",
      text: "Do you want to delete this request?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        StudentServiceObj.DeleteStudent(id)
          .then((res) => {
            Toast("Student deleted succesfully", "success");

            setGridRender();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  const columns = [
    {
      name: "userName",
      label: "User Name",
    },
    {
      name: "registerNo",
      label: "Register No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "studentName",
      label: "Student Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "joiningDate",
      label: "Batch No",
      options: {
        filter: true,
        sort: true,
      },
    },


    {
      name: "bankName",
      label: "Bank Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    
    {
      name: "holderName",
      label: "Account Holder Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bankAccNumber",
      label: "Account Number",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "bankBranch",
      label: "Branch",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ifscCode",
      label: "IFSCCode",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <span style={{ color: value === "Active" ? "green" : "red" }}>
            {value}
          </span>
        ),
      },
    },
  ];

  return (
    <>
      <div class="main-panel">
        <div class="content">
          <div className="page-inner">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <MUIDataTable
                        data={StudentModel}
                        title="Student Bank Details"
                        columns={columns}
                        options={{
                          responsive: true,
                          download: true,
                          print: true,
                          selectableRows: false,
                          filter: true,
                          viewColumns: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default StudentBankDetails;
