
    import React, { useContext, useState, useEffect } from "react";
    import { useLocation } from "react-router-dom";
    import { AuthContext } from "../../Context/AuthContext";
    import AuthenticationService from "../../api/AuthenticationService";
    import $ from "jquery";
    import { useNavigate } from "react-router";
    import "../SideBar/Loader.css";
    
    

export const Cybertronic = () => {
    const { data, setCookie, removeCookie, cookies } = useContext(AuthContext);

 
    
  const navigate = useNavigate();
  const AuthObject = new AuthenticationService();
    const search = useLocation(). search;
    const Username=new URLSearchParams(search). get("Username");
    const Password=new URLSearchParams(search). get("Password");
    const ishrms=new URLSearchParams(search). get("ishrms");
    const Url=new URLSearchParams(search). get("Url");
    const [disabled, setDisabled] = useState(false);
 
    useEffect(() => {
      setCookie("SidebarReload", 0, { path: "/" });
      setCookie("KAPPUURL", Url, { path: "/" });
        var data = {
          UserName: Username,
          Password: Password,
          ishrms : Number(ishrms),
         rememberMeflag: true,
        };
        if (cookies.ISReload == 1) {
          setCookie("ISReload", 0, { path: "/" });
          window.location.reload();
        }
    
        if (data.UserName != null && data.Password != null) {
          $(".loaders-containers").show();
          AuthObject.getData(data)
            .then(
              (res) => (
                // localStorage.setItem("token", data.user.token),
                // setCookie("userID", res.data?.userID, { path: "/" }),
                // setCookie("userName", res.data?.userName, { path: "/" }),
                // setCookie("employeeName", res.data?.employeeName, { path: "/" }),
                // setCookie("token", res.data?.token, { path: "/" }),
                // // setCookie("Rememberme", authentication.rememberMeflag, { path: "/" }),
                // setCookie("roleName", res.data?.roleName, { path: "/" }),
                // setCookie("KpppDecript", res.data?.decriptPassword, { path: "/" }),
                // setCookie("KuuuDecript", res.data?.decriptUsername, { path: "/" }),
                // setCookie("roleID", res.data?.roleID, { path: "/" }),
                // setCookie("UserPrivileges", res.data?.userPrivileges.split(","), {
                //   path: "/",
                // }),
                // setCookie("SidebarReload", 1, { path: "/" }),
                // localStorage.setItem("token", data.user.token),
               
                // setDisabled(false),
                // $("#btnLoginSubmit").prop("disabled", false),
                setTimeout(() => {
                   RedirectFunctions(res.data.roleName.trim().toLowerCase());
                })
                   // setTimeout(() => { Toast('Logged in Succesfully', 'success')}, 500)
              )
            )
            .catch((res) => {
              console.log(res);
    
              // swal({
              //   text: res.response.data.message,
              //   icon: "warning",
              // });
              // $(".loaders-containers").hide();
              removeCookie("KAPPU");
              removeCookie("KAPPP");
              // setDisabled(false);
              window.location.reload();
            });
          
        }
      });
      function RedirectFunctions(RoleName) {
        debugger;
        switch (RoleName) {
          case "hod":
            navigate("/Dashboard", { replace: true });
            ////$('.main-loader').hide();
            break;
          case "engineer":
            navigate("/Dashboard", { replace: true });
            ////$('.main-loader').hide();
            break;
          case "admin":
            navigate("/Dashboard", { replace: true });
            ////$('.main-loader').hide();
            break;
          case "md":
            navigate("/Dashboard", { replace: true });
            ////$('.main-loader').hide();
            break;
          default:
            navigate("/Dashboard", { replace: true });
            ////$('.main-loader').hide();
            break;
        }
      }
  return (
    <>
   <div className="loader-container" style={{backgroundColor:"rgba(192, 193, 194, 0.73)"}}>

<span className="loadercube" style={{position:"fixed",right:"46%",bottom:"50%"}}></span>
  </div>
    </>
  );
};
