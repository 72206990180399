import React, { useState, useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import Stepper from "react-stepper-horizontal";
import DatePicker from "react-datepicker";
import StudentService from "../../api/StudentService";
import Select from "react-select";
import $ from "jquery";
import { useEffect } from "react";
import Toast from "../Toastify";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { saveAs } from "file-saver";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const StudentServiceObj = new StudentService();
function StudentEdit(props) {
  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);
  const { Id } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [StudentID, SetStudentID] = useState(Id);
  const [StudentModel, setStudentModel] = useState([]);
  const [CourseModel, setCourseModel] = useState([]);
  const [amount, setAmount] = useState("");
  const [studentName, setstudentName] = useState("");
  const [fatherName, setfatherName] = useState("");
  const [gender, setgender] = useState("");
  const [registerNo, setregisterNo] = useState("");
  const [joiningDate, setjoiningDate] = useState("");
  const [dateOfBirth, setdateOfBirth] = useState("");
  const [mobileNumber, setmobileNumber] = useState("");
  const [emergencyContactNumber, setemergencyContactNumber] = useState("");
  const [aadharNumber, setaadharNumber] = useState("");
  const [userName, setuserName] = useState("");
  const [panNumber, setpanNumber] = useState("");
  const [bankAccType, setbankAccType] = useState("");
  const [bankAccNumber, setbankAccNumber] = useState("");
  const [bankName, setbankName] = useState("");
  const [holderName, setholderName] = useState("");
  const [bankBranch, setbankBranch] = useState("");
  const [ifscCode, setifscCode] = useState("");
  const [personalMailID, setpersonalMailID] = useState("");
  const [presentAddress, setpresentAddress] = useState("");
  const [permanentAddress, setpermanentAddress] = useState("");
  const [status, setstatus] = useState("Active");
  const [course, setcourse] = useState("");
  const [specialization, setspecialization] = useState("");
  const [collegeName, setcollegeName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [maxendDate, setmaxEndDate] = useState("");
  const [passwordHash, setpasswordHash] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [fileerror, setfileerror] = useState("");
  const [filepath, Setfilepath] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [downloadpath, setdownloadpath] = useState("");
  const [specializationModel, setspecializationModel] = useState([]);
  const [Joinstartdate, SetJoinstartdate] = useState();
  const [Joinenddate, SetJoinenddate] = useState();

  const [error, setformError] = useState({
    RegisterNo: "",
    Email: "",
    StudentName: "",
    mobileNumber: "",
    DateOfBirth: "",
    FatherName: "",
    BankName: "",
    HolderName:"",
    BankAccNumber: "",
    BankAccType: "",
    BankBranch: "",
    IFSCCode: "",
    CollegeName: "",
    Course: "",
    Specialization: "",
    StartDate: "",
    EndDate: "",
    PresentAddress: "",
    UserName: "",
    AlternateNumber: "",
    UploadPath: "",
  });
  const handleCheckboxChange = () => {
    debugger;

    setIsChecked(!isChecked);

    // Clear the value when unchecked, set a value when checked
    if (!isChecked) {
      $("#permanentAddress").val(presentAddress);
      setpermanentAddress(presentAddress);
    } else {
      $("#permanentAddress").val("");
    }

    console.log($("#permanentAddress").val());
    var val2 = document.getElementById("permanentAddress").value;
    val2 = permanentAddress;
  };

  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
  }

  useEffect(() => {
    setformError([]);
    SetStudentID(Id);

    if (cookies.roleName == "Student") {
      setActiveStep(activeStep + 3);
    }
    StudentServiceObj.Edit(Id).then((res) => {
      debugger;
      var data = res.data;

      console.log(data);

      if (Id != 0) {
        data.startDate = data.startDatestr;
        data.endDate = data.endDatestr;
        setCourseModel(data.courses);

        if (data.joiningStartDate !== null && data.joiningStartDate !== "") {
          SetJoinstartdate(new Date(data.joiningStartDate));
        }
        if (data.joiningEndDate !== null && data.joiningEndDate !== "") {
          SetJoinenddate(new Date(data.joiningEndDate));
        }

        //setjoiningDate(data.joiningDate);
        if (data.startDate != null) {
          const momentDate = moment(data.startDate, "YYYY-MM-DD");
          setStartDate(momentDate.toDate());
        }
        const FromDate2 = (d1) => {
          var MmDate = d1.format("YYYY-MM-DD");
          setStartDate(MmDate);
        };

        if (data.endDate != null) {
          const momentDate = moment(data.endDate, "YYYY-MM-DD");
          setEndDate(momentDate.toDate());
        }
        const FromDate3 = (d1) => {
          var MmDate = d1.format("YYYY-MM-DD");
          setEndDate(MmDate);
        };

        setstudentName(data.studentName);
        setfatherName(data.fatherName);

        if (data.gender > 0) {
          setgender({
            value: data.gender,
            label: Gender[data.gender - 1].label,
          });
        }

        setregisterNo(data.registerNo);
        // setjoiningDate(data.joiningDate);
        // setdateOfBirth(data.dateOfBirth);
        setmobileNumber(data.mobileNumber);
        setemergencyContactNumber(data.emergencyContactNumber);
        //setaadharNumber(data.aadharNumber);
        setuserName(data.userName);
        setpanNumber(data.panNumber);
        //setbankAccType(data.bankAccType);
        setbankAccNumber(data.bankAccNumber);
        setbankName(data.bankName);
        setholderName(data.holderName);
        setbankBranch(data.bankBranch);
        setifscCode(data.ifscCode);
        setpersonalMailID(data.personalMailID);
        setpresentAddress(data.presentAddress);
        setpermanentAddress(data.permanentAddress);
        setAmount(data.allocatedAmount);
        setstatus(data.status);

        setcourse(data.course);
        setspecialization(data.specialization);
        setcollegeName(data.collegeName);
        // setStartDate(data.startDate);
        // setEndDate(data.endDate);
        Setfilepath(data.fileName);
        // setdownloadpath(firstTicket.downloadPath);
        // setSelectedFile(data.fileName);
        setdownloadpath("/Uploadfiles/StudentPassbook/" + data.fileName);
        setpasswordHash(data.passwordHash);
        setStudentModel(data);
      } else {
        setCourseModel(data.courses);
        setuserName(data.userName);
        setStudentModel(data);
      }
    });
  }, [Id]);

  function Loadspecialization(course) {
    debugger;
    StudentServiceObj.GetSpecialisationList(course).then((res) => {
      var data = res.data;
      setspecializationModel(data);
    });
  }

  function numberOnly(id) {
    var element = document.getElementById(id);
    element.value = element.value.replace(/[^0-9]/gi, "");
  }

  const handleaadharNumber = () => {
    // Remove all non-numeric characters
    const numericValue = aadharNumber.replace(/\D/g, "");

    // Format the numeric value as Aadhar Number (nnn-nnnn-nnnn)
    const formattedValue = numericValue.replace(
      /(\d{4})(\d{4})(\d{4})/,
      "$1 $2 $3"
    );
    console.log(formattedValue);
    setaadharNumber(formattedValue);
  };

  const handleDateChange = (e) => {
    debugger;
    const { value } = e.value;
    setStudentModel((prevState) => ({
      ...prevState,
      ["gender"]: value,
    }));
  };

  const steps = [
    {
      title: "Basic Details",
      // description: <Stepper4 onDataSubmit={handleDataSubmit} />,
    },
    {
      title: "Personal Details",
      //  description: <Stepper1 onDataSubmit={handleDataSubmit} />,
    },
    {
      title: "Education Details",
      //  description: <Stepper2 onDataSubmit={handleDataSubmit} />,
    },
    {
      title: "Bank Details",
      //  description: <Stepper3 onDataSubmit={handleDataSubmit} />,
    },
  ];

  const activeStepStyle = {
    backgroundColor: "red",
    transition: "background-color 0.3s ease",
  };

  // function getSectionComponent() {
  //   switch (activeStep) {
  //     case 0:
  //       return <Stepper4 />;
  //     case 1:
  //       return <Stepper1 />;
  //     case 2:
  //       return <Stepper2 />;
  //     case 3:
  //       return <Stepper3 />;

  //     default:
  //       return null;
  //   }
  // }
  const myEyeFunction = () => {
    var x = document.getElementById("Password");
    if (x.type === "password") {
      x.type = "text";
      $("#pswicon").removeClass("fa-eye");
      $("#pswicon").addClass("fa-eye-slash");
    } else {
      $("#pswicon").removeClass("fa-eye-slash");
      $("#pswicon").addClass("fa-eye");
      x.type = "password";
    }
  };
  function download(url) {
    StudentServiceObj.Download(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }
  const handleFileChange = (event) => {
   
    const file = event.target.files[0];
    if (validateFileExtension(file)) {
      setSelectedFile(file);
    } else {
      setSelectedFile(filepath);
      setfileerror(
        "Invalid file format. Please select a valid file.It allows .jpg, .jpeg, .png, .pdf"
      );
    }
  };

  const validateFileExtension = (file) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };
  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    // Allow letters, digits, and spaces
    if (
      (keyCode >= 65 && keyCode <= 90) || // A-Z
      (keyCode >= 97 && keyCode <= 122) || // a-z
      (keyCode >= 48 && keyCode <= 57) || // 0-9
      keyCode === 32
    ) {
      // Space
      return;
    } else {
      e.preventDefault();
    }
  };

  function handleNext() {
    setActiveStep(activeStep + 1);
  }

  function handlePrevious() {
    setActiveStep(activeStep - 1);
  }
  function mobile_NumberChange() {
    var mobile_Number = $("#errRegisterNo").val();
    if (mobile_Number == "") {
      $("#errRegisterNo").text("Mobile Number is Required");
    } else {
      $("#errRegisterNo").text("");
    }
  }
  function RegisterChange() {
    var Register = $("#RegNo").val();
    if (Register == "") {
      $("#errRegisterNo").text("Register Number is Required");
    } else {
      $("#errRegisterNo").text("");
    }
  }
  function mobile_NumberChange() {
    var mobile_Number = $("#mobile_Number").val();
    if (mobile_Number == "") {
      $("#errmobile_Number").text("Mobile Number is Required");
    } else {
      $("#errmobile_Number").text("");
    }
  }
  function mobile_NumberChange() {
    var mobile_Number = $("#mobile_Number").val();
    if (mobile_Number == "") {
      $("#errmobile_Number").text("Mobile Number is Required");
    } else {
      $("#errmobile_Number").text("");
    }
  }
  const BasicDetails = (e) => {
    e.preventDefault();
    debugger;
    const formData = {
      StudentID: Id,
      RegisterNo: e.target.registerNo.value,
      Isfirst: Id,
      UserName: userName,
      StudentName: e.target.studentName.value,
      FatherName: e.target.fatherName.value,
      PersonalMailID: e.target.personalMailID.value,
      //DateOfBirth: dateOfBirth,
      Gender: e.target.gender.value,
      mobileNumber: e.target.mobileNumber.value,
      EmergencyContactNumber: e.target.emergencyContactNumber.value,
      PresentAddress: e.target.presentAddress.value,
      PermanentAddress: e.target.permanentAddress.value,
      //AadharNumber: e.target.aadharNumber.value,
      Type: "Basic",
    };

    debugger;
    var myerror = error;
    var flag = true;

    if (formData.RegisterNo == null || formData.RegisterNo == "") {
      myerror.RegisterNo = "Register Number is Required";
      flag = false;
    } else {
      myerror.RegisterNo = "";
    }

    if (formData.PersonalMailID == null || formData.PersonalMailID == "") {
      myerror.Email = " Email is Required";
      flag = false;
    } else {
      if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
          formData.PersonalMailID
        )
      ) {
        myerror.Email = "Sorry! Email ID is Not Vaild";
        flag = false;
      } else myerror.Email = "";
    }

    if (formData.StudentName == null || formData.StudentName == "") {
      myerror.StudentName = "Student Name is Required";
      flag = false;
    } else {
      myerror.StudentName = "";
    }
    if (formData.FatherName == null || formData.FatherName == "") {
      myerror.FatherName = "Parent Name / Guardian Name is Required";
      flag = false;
    } else {
      myerror.FatherName = "";
    }

    if (formData.mobileNumber == null || formData.mobileNumber == "") {
      myerror.mobileNumber = " Mobile Number is Required";
      flag = false;
    } else if (formData.mobileNumber.length < 10) {
      myerror.mobileNumber = " Mobile Number is invalid.";
      flag = false;
    } else {
      if (/[^0-9]+/.test(formData.mobileNumber)) {
        myerror.mobileNumber = "Sorry! Only Allow Numbers";
        flag = false;
      } else myerror.mobileNumber = "";
    }

    if (
      formData.EmergencyContactNumber == null ||
      formData.EmergencyContactNumber == ""
    ) {
      myerror.AlternateNumber = "Alternate Contact is Required";
      flag = false;
    } else if (formData.EmergencyContactNumber.length < 10) {
      myerror.AlternateNumber = "Alternate Contact is invalid.";
      flag = false;
    } else {
      if (/[^0-9]+/.test(formData.EmergencyContactNumber)) {
        myerror.AlternateNumber = "Sorry! Only Allow Numbers";
        flag = false;
      } else myerror.AlternateNumber = "";
    }

    if (formData.Gender == null || formData.Gender == "") {
      myerror.Gender = "Gender is Required";
      flag = false;
    } else {
      myerror.Gender = "";
    }
    if (formData.PresentAddress == null || formData.PresentAddress == "") {
      myerror.PresentAddress = "Present Address is Required";
      flag = false;
    } else {
      myerror.PresentAddress = "";
    }
    // if (formData.DateOfBirth == null || formData.DateOfBirth == "") {
    //   myerror.DateOfBirth = "Date Of Birth is Required";
    //   flag = false;
    // } else {
    //   myerror.DateOfBirth = "";
    // }

    // if (formData.AadharNumber == null || formData.AadharNumber == "") {
    //   myerror.AadharNumber = "Aadhar Number is Required";
    //   flag = false;
    // } else {
    //   myerror.AadharNumber = "";
    // }

    setformError({ ...myerror });

    if (flag) {
      //$(".loader-container").show();

      Submit_StudentDetail(formData);
    }
  };

  const Submit_StudentDetail = (formData) => {
    debugger;
    const Baseform = new FormData();

    Baseform.append("StudentID", formData.StudentID);
    Baseform.append("RegisterNo", formData.RegisterNo);
    Baseform.append("StudentName", formData.StudentName);
    Baseform.append("UserName", formData.UserName);
    Baseform.append("FatherName", formData.FatherName);
    Baseform.append("PersonalMailID", formData.PersonalMailID);
    //Baseform.append("DateOfBirth", formData.DateOfBirth);
    Baseform.append("Gender", formData.Gender);
    Baseform.append("mobileNumber", formData.mobileNumber);
    Baseform.append("PresentAddress", formData.PresentAddress);
    Baseform.append("PermanentAddress", formData.PermanentAddress);
    Baseform.append("EmergencyContactNumber", formData.EmergencyContactNumber);
    Baseform.append("Isfirst", formData.Isfirst);
    Baseform.append("Type", formData.Type);
    StudentServiceObj.RequestEdit(Baseform)
      .then((response) => {
        //console.log(response);
        debugger;
        setActiveStep(activeStep + 1);
        if (StudentID == 0) {
          Toast("Student Created Successfully", "success");
        } else {
          Toast("Student Saved Successfully", "success");
        }
        const stud = response.data;
        SetStudentID(stud);
        //setGridRender();
      })
      .catch((error) => {
        console.error("Error while saving:", error);
        Toast("Student Saved Successfully ,but Error in mail", "error");
        refreshPage();
        const stud = error.data;
        SetStudentID(stud);
      });
  };

  const Gender = [
    //{ label: "Select Gender", value: 0 },
    { label: "Male", value: 1 },
    { label: "Female", value: 2 },
    { label: "Other", value: 3 },
  ];

  const handleInputFocus = (e) => {
    // Remove commas from the input value when it receives focus
    const numericValue = e.target.value.replace(/,/g, "");
    setAmount(numericValue);
  };

  const handleInputBlur = () => {
    if (amount) {
      // Format the numeric value with commas when the input loses focus
      const formattedAmount = new Intl.NumberFormat("en-IN").format(
        parseFloat(amount)
      );
      setAmount(formattedAmount);
    } else {
      setAmount("");
    }
  };
  const handleAmountInputChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");

    // Calculate the new cursor position based on the changes in the input value
    const cursorPosition = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;
    const selectionLength = selectionEnd - cursorPosition;
    const newCursorPosition =
      cursorPosition - (amount.length - numericValue.length);

    setAmount(numericValue);
    // setErrors({ ...errors, Amount: "" }); // Clear the error message

    // Set the new cursor position, considering the selection length
    // e.target.setSelectionRange(
    //   newCursorPosition,
    //   newCursorPosition + selectionLength
    // );
  };
  const BankDetails = (e) => {
    debugger
    
    e.preventDefault();

    
    const formData = {
      StudentID: StudentID,
      RegisterNo: registerNo,
      StudentName: studentName,
      FatherName: fatherName,
      PersonalMailID: personalMailID,
      DateOfBirth: dateOfBirth,
      // Gender: gender.value,
      mobileNumber: mobileNumber,
      EmergencyContactNumber: emergencyContactNumber,
      //AadharNumber: aadharNumber,
      Isfirst: Id,
      JoiningDate: joiningDate != null ? joiningDate : $("#joiningDate").val(),
      //PanNumber: panNumber,
      PresentAddress: presentAddress,
      PermanentAddress: permanentAddress,
      Status: "AC",
      CollegeName: collegeName,
      Course: course,
      Specialization: specialization,
      StartDate: startDate,
      EndDate: endDate,
      // AllocatedAmount: amount,
      BankName: e.target.bankName.value,
      HolderName:e.target.holderName.value,
      BankAccNumber: e.target.bankAccNumber.value,
      //BankAccType: e.target.bankAccType.value,
      BankBranch: e.target.bankBranch.value,
      IFSCCode: e.target.ifscCode.value,
      UserName: userName,
      UploadPath: selectedFile,
      Type: "Bank",
      CreatedBy:StudentID,
    };
    
    var myerror = error;
    var flag = true;
    var tost = true;
    var isfirstclear = true;

    if (formData.BankName == null || formData.BankName == "") {
      isfirstclear = false;
      myerror.BankName = "Bank Name is Required";

      flag = false;
    } else {
      myerror.BankName = "";
    }
    if (formData.HolderName == null || formData.HolderName == "") {
      isfirstclear = false;
      myerror.HolderName = "Account Holder Name is Required";
      flag = false;
    } else {
      myerror.BankName = "";
    }
    if (formData.BankAccNumber == null || formData.BankAccNumber == "") {
      isfirstclear = false;
      myerror.BankAccNumber = "Bank Account Number is Required";

      flag = false;
    } else {
      myerror.BankAccNumber = "";
    }
    // if (formData.BankAccType == null || formData.BankAccType == "") {
    //   myerror.BankAccType = "Bank Account Type is Required";
    //   if (tost == true) {
    //     Toast("Bank Account Type is Required", "warning");
    //     tost = false;
    //   }
    //   flag = false;
    // } else {
    //   myerror.BankAccType = "";
    // }
    if (formData.BankBranch == null || formData.BankBranch == "") {
      isfirstclear = false;
      myerror.BankBranch = "Bank Branch is Required";

      flag = false;
    } else {
      myerror.BankBranch = "";
    }
    if (formData.IFSCCode == null || formData.IFSCCode == "") {
      isfirstclear = false;
      myerror.IFSCCode = "IFSC Code is Required";

      flag = false;
    } else {
      myerror.IFSCCode = "";
    }
    if (selectedFile == null || selectedFile == "null" || selectedFile == "" ) {
      isfirstclear = false;
      Toast("Passbook is Required", "warning");
      flag = false;
    }

    
    if (isfirstclear) {
      removeCookie("isprofileComplete");
      setCookie("isprofileComplete", 0, { path: "/" });
    } else if (cookies.roleName == "Student") {
      removeCookie("isprofileComplete");
      setCookie("isprofileComplete", 1, { path: "/" });
    }

    setformError({ ...myerror });

    if (flag) {
      //$(".loader-container").show();

      Submit_BankDetails(formData);
    }
  };

  const Submit_BankDetails = (formData) => {
    
    const FromformData = new FormData();

    console.log(formData.StartDate);
    FromformData.append("StudentID", formData.StudentID);
    FromformData.append("RegisterNo", formData.RegisterNo);
    FromformData.append("StudentName", formData.StudentName);
    FromformData.append("FatherName", formData.FatherName);
    FromformData.append("PersonalMailID", formData.PersonalMailID);
    //FromformData.append("DateOfBirth", formData.DateOfBirth);
    // FromformData.append("Gender", formData.Gender);
    FromformData.append("mobileNumber", formData.mobileNumber);
    FromformData.append(
      "EmergencyContactNumber",
      formData.EmergencyContactNumber
    );
    FromformData.append("Isfirst", formData.Isfirst);
    // FromformData.append("AllocatedAmount", formData.AllocatedAmount);
    FromformData.append("JoiningDate", formData.JoiningDate);
    FromformData.append("PresentAddress", formData.PresentAddress);
    FromformData.append("PermanentAddress", formData.PermanentAddress);
    FromformData.append("Status", formData.Status);
    FromformData.append("CollegeName", formData.CollegeName);
    FromformData.append("Course", formData.Course);
    FromformData.append("Specialization", formData.Specialization);
    FromformData.append("CreatedBy", formData.StudentID);
    if (formData.StartDate != null && formData.StartDate != "") {
      FromformData.append(
        "StartDatestr",
        new Date(formData.StartDate).toISOString()
      );
    }
    if (formData.EndDate != null && formData.EndDate != "") {
      FromformData.append(
        "EndDatestr",
        new Date(formData.EndDate).toISOString()
      );
    }
    FromformData.append("BankName", formData.BankName);
    FromformData.append("HolderName",formData.HolderName);
    FromformData.append("BankAccNumber", formData.BankAccNumber);
    FromformData.append("BankBranch", formData.BankBranch);
    FromformData.append("IFSCCode", formData.IFSCCode);
    FromformData.append("UserName", formData.UserName);
    if (filepath == null || filepath == " ") {
      FromformData.append("UploadPath", formData.UploadPath);
    }else if(formData.Status=='Requested'){
      FromformData.append("UploadPath", filepath);
    } else {
      FromformData.append("UploadPath", formData.UploadPath);
    }
    FromformData.append("Type", formData.Type);
    StudentServiceObj.RequestEdit(FromformData)
      .then((response) => {
        console.log(response);
        //$(".loader-container").hide();

        Toast("Bank details Saved Successfully", "success");

        const stud = response.data;
        SetStudentID(stud);
        setActiveStep(activeStep + 1);

        $(".close").trigger("click");
      })
      .catch((error) => {
        console.error("Error while saving:", error);
        //Toast("Student Created Successfully ,but Error in mail", "error");
        Toast("Bank details Saved Successfully", "success");
        //refreshPage();
      });
         refreshPage();
    //$(".loader-container").hide();
  };

  const AccountType = [
    { label: "Savings", value: 1 },
    { label: "Current", value: 2 },
  ];

  const EducationDetails = (e) => {
    e.preventDefault();
    debugger;
    const formData = {
      StudentID: StudentID,
      Isfirst: Id,
      UserName: userName,
      CollegeName: e.target.collegeName.value,
      Course: e.target.course.value,
      Specialization: e.target.specialization.value,
      StartDate: startDate,
      EndDate: endDate,
      Status: status,
      Type: "Education",
    };

    var myerror = error;
    var flag = true;
    if (formData.CollegeName == null || formData.CollegeName == "") {
      myerror.CollegeName = "College Name is Required";
      flag = false;
    } else {
      myerror.CollegeName = "";
    }
    if (formData.Course == null || formData.Course == "") {
      myerror.Course = "Course is Required";
      flag = false;
    } else {
      myerror.Course = "";
    }
    if (formData.Specialization == null || formData.Specialization == "") {
      myerror.Specialization = "Specialization is Required";
      flag = false;
    } else {
      myerror.Specialization = "";
    }
    // if (formData.StartDate == null || formData.StartDate == "") {
    //   myerror.StartDate = "Start Date is Required";
    //   flag = false;
    // } else {
    //   myerror.StartDate = "";
    // }
    // if (formData.EndDate == null || formData.EndDate == "") {
    //   myerror.EndDate = "End Date is Required";
    //   flag = false;
    // } else {
    //   myerror.EndDate = "";
    // }

    setformError({ ...myerror });

    if (flag) {
      Submit_EducationDetails(formData);
    }
  };

  function Onchangedatehandler(value) {
    SetJoinstartdate(value[0]);
    SetJoinenddate(value[1]);

    // const max = value[0];
    // max.setFullYear(max.getFullYear() + 5);

    // setmaxEndDate(max);

    var year1 = new Date(value[0]).getFullYear();
    var year2 = new Date(value[1]).getFullYear();
    setjoiningDate(year1 + " - " + year2);
  }
  const Submit_EducationDetails = (formData) => {
    const FromformData = new FormData();

    console.log(formData.StartDate);
    FromformData.append("StudentID", formData.StudentID);
    FromformData.append("Isfirst", formData.Isfirst);
    FromformData.append("CollegeName", formData.CollegeName);
    FromformData.append("Course", formData.Course);
    FromformData.append("Specialization", formData.Specialization);
    if (formData.StartDate != "" && formData.StartDate != null) {
      FromformData.append(
        "StartDatestr",
        new Date(formData.StartDate).toISOString()
      );
    }
    if (formData.EndDate != "" && formData.EndDate != null) {
      FromformData.append(
        "EndDatestr",
        new Date(formData.EndDate).toISOString()
      );
    }
    FromformData.append("UserName", formData.UserName);

    FromformData.append("Type", formData.Type);
    StudentServiceObj.RequestEdit(FromformData)
      .then((response) => {
        console.log(response);
        debugger;
        if (StudentID > 0) {
          Toast("Student Saved Successfully", "success");
        } else {
          Toast("Student Saved Successfully", "success");
        }
        const stud = response.data;
        SetStudentID(stud);
        setActiveStep(activeStep + 1);

        //$(".close").trigger("click");
      })
      .catch((error) => {
        console.error("Error while saving:", error);

        Toast("Student Saved Successfully ,but Error in mail", "error");
        refreshPage();
      });
  };

  const PersonalDetails = (e) => {
    e.preventDefault();
    debugger;
    const formData = {
      StudentID: StudentID,
      Isfirst: Id,
      JoiningDate: e.target.joiningDate.value,
      AllocatedAmount: e.target.Amount.value,
      //PanNumber: e.target.panNumber.value,
      Status: e.target.isActive.value,
      Type: "Personal",
      UserName: e.target.userName.value,
    };

    var myerror = error;
    var flag = true;

    setformError({ ...myerror });

    if (flag) {
      Submit_PersonalDetails(formData);
    }
  };

  const Submit_PersonalDetails = (formData) => {
    debugger;

    const FromformData = new FormData();

    console.log(formData.StartDate);
    FromformData.append("StudentID", formData.StudentID);
    FromformData.append("UserName", formData.UserName);
    FromformData.append("Isfirst", formData.Isfirst);
    FromformData.append("AllocatedAmount", formData.AllocatedAmount);
    FromformData.append("JoiningDate", formData.JoiningDate);
    FromformData.append("Status", formData.Status);
    FromformData.append("Type", formData.Type);
    StudentServiceObj.RequestEdit(FromformData)
      .then((response) => {
        console.log(response);
        setActiveStep(activeStep + 1);
        debugger;
        if (StudentID == 0) {
          Toast("Student Saved Successfully", "success");
        } else {
          Toast("Student Saved Successfully", "success");
        }
        const stud = response.data;
        SetStudentID(stud);
        //setGridRender();

        //$(".close").trigger("click");
      })
      .catch((error) => {
        console.error("Error while saving:", error);
        Toast("Student Saved Successfully ,but Error in mail", "error");
        refreshPage();
      });
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);

    const selectedStartDate = e.target.value;
    setStartDate(selectedStartDate);

    // If a start date is selected, set the minimum date for the end date input
    if (selectedStartDate) {
      setEndDate(selectedStartDate);
    }
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value.toUpperCase(); // Convert input to uppercase

    // Check if the input matches the desired format
    if (/^[0-9A-Z]{0,11}$/.test(inputValue)) {
      setifscCode(inputValue); // Set the uppercase value
      setformError({ IFSCCode: "" }); // Clear the error message
    } else {
    }
  };

  return (
    <div>
      <div>
        <div className="StepperClass">
          <Stepper
            steps={steps}
            activeStep={activeStep}
            activeStepStyle={activeStepStyle}
            activeColor="#1DA837"
            defaultColor="#88b082"
            completeColor="#91e193"
            activeTitleColor="#1DA837"
            completeTitleColor="#91e193"
            defaultTitleColor="#000"
            circleFontColor="#fff"
            completeBarColor="#91e193"
          />
        </div>

        <br />
        <div>
          <form
            id="BasicDetailsCreate"
            onKeyPress={(e) => {
              if (e.key == "Enter") {
                e.preventDefault();
              }
            }}
            onSubmit={BasicDetails}
            style={{ display: activeStep == 0 ? "block" : "none" }}
          >
            <div id="wizard_Service" class="tab-pane" role="tabpanel">
              <div
                class="card-header"
                style={{
                  marginBottom: "25px",
                  backgroundColor: "#ece7e1 !important",
                }}
              >
                <h3 class="card-title" style={{ lineHeight: 1 }}>
                  Basic Details
                </h3>
              </div>

              <div class="row">
                <div class="col-lg-4 mb-2">
                  <div class="form-group">
                    <label>
                      Register Number (enroll number){" "}
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="registerNo"
                      class="form-control"
                      placeholder="Register Number "
                      value={registerNo}
                      //onChange={handleChange}
                      onChange={(e) => {
                        setregisterNo(e.target.value);
                        setformError({ ...error, RegisterNo: "" }); // Clear the error message
                      }}
                      onBlur={RegisterChange}
                      onKeyPress={handleKeyPress}
                      autoComplete="off"
                    />
                    <span className="text-danger" id="errRegisterNo">
                      {error.RegisterNo}
                    </span>
                  </div>
                </div>
                <div class="col-lg-4 mb-2">
                  <div class="form-group">
                    <label>
                      Student Name <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="studentName"
                      class="form-control"
                      placeholder="Student Name"
                      value={studentName}
                      onChange={(e) => {
                        setstudentName(e.target.value);
                        setformError({ ...error, StudentName: "" }); // Clear the error message
                      }}
                      onKeyPress={handleKeyPress}
                      autoComplete="off"
                    />
                    <span className="text-danger" id="errStudentName">
                      {error.StudentName}
                    </span>
                  </div>
                </div>

                <div class="col-lg-4 mb-2">
                  <div class="form-group">
                    <label>
                      Parent Name / Guardian Name{" "}
                      <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="fatherName"
                      class="form-control"
                      placeholder="Parent Name / Guardian Name"
                      value={fatherName}
                      onChange={(e) => {
                        setfatherName(e.target.value);
                        setformError({ ...error, FatherName: "" }); // Clear the error message
                      }}
                      onKeyPress={handleKeyPress}
                      autoComplete="off"
                    />
                    <span className="text-danger" id="errFatherName">
                      {error.FatherName}
                    </span>
                  </div>
                </div>

                {/* <div class="col-lg-4 mb-2">
                  <div class="form-group">
                    <label>
                      Date Of Birth <span class="text-danger">*</span>
                    </label>
                    <div>
                      <DatePicker
                        name="dateOfBirth"
                        id="dateOfBirth"
                        className="form-control"
                        selected={dateOfBirth}
                        showYearDropdown // Enable year dropdown
                        showMonthDropdown // Enable month dropdown
                        dropdownMode="select" // Use select dropdowns for year and month
                        dateFormat="dd/MM/yyyy" // Specify your desired date format
                        onChange={(date) => {
                          setdateOfBirth(date);
                          setformError({ ...error, DateOfBirth: "" }); // Clear the error message
                        }}
                        onKeyDown={handleKeyPress}
                        placeholderText="Date Of Birth"
                        maxDate={new Date()}
                        autoComplete="off"
                      />
                      {/* <DatePicker
                        name="dateOfBirth"
                        id="dateOfBirth"
                        className="form-control"
                        selected={dateOfBirth}
                       
                      /> */}

                {/* <input
                        type="date"
                        value={dateOfBirth}
                        name="dateOfBirth"
                        id="dateOfBirth"
                        className="form-control"
                        //minDate={today}
                        onChange={(e) => {
                          setdateOfBirth(e.target.value);
                          setformError({ ...error, DateOfBirth: "" }); // Clear the error message
                        }}
                        onKeyPress={handleKeyPress}
                        placeholder="Date Of Birth"
                        max={new Date().toISOString().split("T")[0]}
                      /> 
                      <span className="text-danger" id="errDateOfBirth">
                        {error.DateOfBirth}
                      </span>
                    </div>
                  </div>
                </div> */}
                <div class="col-lg-4 mb-2">
                  <div class="form-group">
                    <label>
                      Gender <span class="text-danger">*</span>
                    </label>
                    <div>
                      <Select
                        name="gender"
                        id="Gender"
                        //value={gender}
                        value={gender}
                        onChange={(e) => {
                          setgender(e);
                          setformError({ ...error, Gender: "" }); // Clear the error message
                        }}
                        options={Gender}
                      />
                    </div>
                    <span className="text-danger" id="errGender">
                      {error.Gender}
                    </span>
                  </div>
                </div>

                <div class="col-lg-4 mb-2">
                  <div class="form-group">
                    <label>
                      Mobile Number <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="mobileNumber"
                      id="mobileNumber"
                      className="form-control"
                      placeholder="Mobile Number"
                      value={mobileNumber}
                      onChange={(e) => {
                        setmobileNumber(e.target.value);
                        setformError({ ...error, mobileNumber: "" });
                        // Clear the error message
                      }}
                      onKeyPress={(e) => {
                        if (!/^\d+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      maxLength="10"
                      autoComplete="off"
                    />

                    <span className="text-danger" id="errmobileNumber">
                      {error.mobileNumber}
                    </span>
                  </div>
                </div>

                <div class="col-lg-4 mb-2">
                  <div class="form-group">
                    <label>
                      Alternate contact <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="emergencyContactNumber"
                      id="emergencyContactNumber"
                      class="form-control"
                      placeholder="Alternate contact"
                      maxLength="10"
                      onChange={(e) => {
                        setemergencyContactNumber(e.target.value);
                        setformError({ ...error, AlternateNumber: "" }); // Clear the error message
                      }}
                      onKeyPress={(e) => numberOnly("emergencyContactNumber")}
                      value={emergencyContactNumber}
                      //onChange={handleChange}
                      autoComplete="off"
                    />

                    <span className="text-danger" id="errAlternateNumber">
                      {error.AlternateNumber}
                    </span>
                  </div>
                </div>

                <div class="col-lg-4 mb-2">
                  <div class="form-group">
                    <label>
                      Student Email <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="personalMailID"
                      class="form-control"
                      placeholder="Student Email"
                      value={personalMailID}
                      onChange={(e) => {
                        setpersonalMailID(e.target.value);
                        setformError({ ...error, Email: "" }); // Clear the error message
                      }}
                      autoComplete="off"
                    />
                    <span className="text-danger" id="errmobileemail">
                      {error.Email}
                    </span>
                  </div>
                </div>

                {/* <div class="col-lg-4 mb-2">
                  <div class="form-group">
                    <label>
                      Aadhar Number <span class="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="aadharNumber"
                      id="aadharNumber"
                      class="form-control"
                      placeholder="Aadhar Number"
                      maxLength="12"
                      value={aadharNumber}
                      onChange={(e) => {
                        setaadharNumber(e.target.value);
                        setformError({ ...error, AadharNumber: "" }); // Clear the error message
                      }}
                      onBlur={handleaadharNumber}
                      autoComplete="off"
                      onKeyPress={(e) => {
                        if (!/^\d+$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <span className="text-danger" id="errAadharNumber">
                      {error.AadharNumber}
                    </span>
                  </div>
                </div> */}

                <div class="col-lg-4 mb-2">
                  <div class="form-group">
                    <label>
                      Present Address <span class="text-danger">*</span>
                    </label>
                    <textarea
                      type="text"
                      name="presentAddress"
                      id="presentAddress"
                      class="form-control"
                      value={presentAddress}
                      placeholder="Present Address"
                      onChange={(e) => {
                        setpresentAddress(e.target.value);
                        setformError({ ...error, PresentAddress: "" }); // Clear the error message
                      }}
                    >
                      {presentAddress}
                    </textarea>
                    <span className="text-danger" id="errPresentAddress">
                      {error.PresentAddress}
                    </span>
                    <br />
                    <span>
                      <input
                        type="checkbox"
                        id="sameper"
                        checked={isChecked}
                        onClick={handleCheckboxChange}
                      />{" "}
                      Same as Permanent Address
                    </span>
                  </div>
                </div>

                <div class="col-lg-4 mb-2">
                  <div class="form-group">
                    <label>Permanent Address</label>
                    <textarea
                      type="text"
                      name="permanentAddress"
                      id="permanentAddress"
                      value={permanentAddress}
                      onChange={(e) => {
                        setpermanentAddress(e.target.value);
                        //setformError({ ...error, PresentAddress: "" }); // Clear the error message
                      }}
                      class="form-control"
                      placeholder="Permanent Address"
                    >
                      {permanentAddress}
                    </textarea>
                  </div>
                </div>
              </div>

              <button
                className="btn btn-primary float-right"
                //onClick={handleNext}
                style={{ marginLeft: "10px" }}
              >
                Save & Next
              </button>
            </div>
          </form>

          {activeStep == 1 ? (
            <form
              id="PersonalDetailsCreate"
              onKeyPress={(e) => {
                if (e.key == "Enter") {
                  e.preventDefault();
                }
              }}
              onSubmit={PersonalDetails}
            >
              <div id="wizard_Service" class="tab-pane" role="tabpanel">
                <div class="card-header" style={{ marginBottom: "25px" }}>
                  <h1 class="card-title">Personal Details</h1>
                </div>

                <div class="row">
                  {/* <div class="col-lg-4 mb-2">
            <div class="mb-4">
              <label class="text-label form-label">
                Blood Group 
              </label>
              <input
                type="text"
                name="BloodGroup"
                class="form-control"
                placeholder="Blood Group"
                required
              />
            </div>
          </div> */}
                  <div class="col-lg-4 mb-2">
                    <div class="form-group">
                      <label>
                        User Name <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="userName"
                        class="form-control"
                        placeholder="User Name"
                        value={userName}
                        disabled={true}
                      />
                      <span className="text-danger" id="errUserName">
                        {error.UserName}
                      </span>
                    </div>
                  </div>
                  {/* <div class="col-lg-4 mb-2">
                  <div class="form-group">
                    <label >
                      Password <span class="text-danger">*</span>
                    </label>
                    <input
                      type="Password"
                      name="Password"
                      id="Password"
                      class="form-control"
                      placeholder="Password"
                      value={userName}
                      disabled={true}
                    />
                      <i
                          className="fa fa-eye"
                          id="pswicon"
                          onClick={myEyeFunction}
                          style={{
                            float: "right",
                            marginRight: "10px",
                            marginTop: "-28px",
                            position: "relative",
                            zIndex: 2,
                          }}
                        ></i>
                    <span className="text-danger" id="errUserName">
                      {error.UserName}
                    </span>
                  </div>
                </div> */}

                  {cookies.roleName != "Student" ? (
                    <div class="col-lg-4 mb-2">
                      <div class="form-group">
                        <label>Allocated Amount</label>
                        <div>
                          <input
                            type="text"
                            name="Amount"
                            id="Amount"
                            className="form-control"
                            placeholder="Amount"
                            value={amount}
                            onChange={handleAmountInputChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur} // Handle onBlur event
                            autoComplete="off"
                            maxLength={12}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div class="col-lg-4 mb-2">
                    <div class="form-group">
                      <label>Batch No</label>
                      <div>
                        <DatePicker
                          id="joiningDate"
                          name="joiningDate"
                          className="form-control"
                          selectsRange={true}
                          onChange={Onchangedatehandler}
                          placeholderText="Batch No"
                          autoComplete="off"

                          dateFormat="yyyy"
                          showYearPicker
                          startDate={Joinstartdate}
                          endDate={Joinenddate}
                        />
                      </div>
                    </div>
                  </div>

                  {/* <div class="col-lg-4 mb-2">
                    <div class="form-group">
                      <label>Pan Number</label>
                      <input
                        type="text"
                        name="panNumber"
                        id="panNumber"
                        class="form-control"
                        value={panNumber}
                        onChange={(e) => {
                          setpanNumber(e.target.value);
                          //setformError({ ...error, AadharNumber: "" }); // Clear the error message
                        }}
                        onKeyPress={handleKeyPress}
                        placeholder="Pan Number"
                        autoComplete="off"
                      />
                    </div>
                  </div> */}
                  <div class="col-md-4 mb-2">
                    <div class="form-group ">
                      <label>Status </label>
                      {/* <Switch  onClick={toogler} />
               {toogle ? <span>Active</span> : <span>InActive</span>} */}
                      <div class="form-check">
                        <div class="row">
                          <div class="col-3">
                            <label class="form-radio-label ">
                              {status == "Active" ? (
                                <input
                                  class="form-radio-input"
                                  type="radio"
                                  name="isActive"
                                  value={"AC"}
                                  onChange={(e) => {
                                    setstatus("AC");
                                  }}
                                  checked="1"
                                />
                              ) : (
                                <input
                                  class="form-radio-input"
                                  type="radio"
                                  name="isActive"
                                  value={"AC"}
                                  onChange={(e) => {
                                    setstatus("AC");
                                  }}
                                />
                              )}
                              <span
                                style={{ marginLeft: "5px" }}
                                class="form-radio-sign"
                              >
                                Active
                              </span>
                            </label>
                          </div>
                          <div class="col-3">
                            <label class="form-radio-label ml-3">
                              {status == "Inactive" ? (
                                <input
                                  class="form-radio-input"
                                  type="radio"
                                  name="isActive"
                                  value={"Inactive"}
                                  onChange={(e) => {
                                    setstatus("Inactive");
                                  }}
                                  checked="0"
                                />
                              ) : (
                                <input
                                  class="form-radio-input"
                                  type="radio"
                                  name="isActive"
                                  value={"Inactive"}
                                  onChange={(e) => {
                                    setstatus("Inactive");
                                  }}
                                />
                              )}
                              <span
                                style={{ marginLeft: "5px" }}
                                class="form-radio-sign"
                              >
                                Inactive
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* <span className="text-danger">{error.status}</span> */}
                    </div>
                  </div>

                  {/* <div class="col-lg-4 mb-2">
            <div class="mb-4">
              <label class="text-label form-label">
                Student Photo 
              </label>
              <input
                type="file"
                name="StudentPhoto"
                class="form-control"
                placeholder=""
              />
            </div>
          </div> */}
                </div>

                <button
                  className="btn btn-primary float-right"
                  style={{ marginLeft: "10px" }}
                >
                  Save & Next
                </button>
              </div>
            </form>
          ) : (
            <></>
          )}
          {activeStep == 2 ? (
            <form
              id="EducationDetailsCreate"
              onKeyPress={(e) => {
                if (e.key == "Enter") {
                  e.preventDefault();
                }
              }}
              onSubmit={EducationDetails}
            >
              <div id="wizard_Service" class="tab-pane" role="tabpanel">
                <div class="card-header" style={{ marginBottom: "25px" }}>
                  <h1 class="card-title">Education Details</h1>
                </div>
                <div class="row">
                  <div class="col-lg-4 mb-2">
                    <div class="form-group">
                      <label>
                        Course <span class="text-danger">*</span>
                      </label>
                      <Autocomplete
                        //disablePortal
                        options={CourseModel}
                        sx={{ width: 300 }}
                        value={course}
                        // onChange={(e) => {
                        //   setcourse(e.target.label);
                        //   setformError({ ...error, Course: "" }); // Clear the error message
                        // }}
                        name="course"
                        id="course"
                        //class="form-control"
                        onKeyUp={(e) => {
                          debugger;
                          setcourse(e.target.defaultValue);
                          setformError({ ...error, Course: "" }); // Clear the error message
                        }}
                        onChange={(e) => {
                          debugger;
                          setcourse(e.target.innerText);
                          Loadspecialization(e.target.innerText);
                          setformError({ ...error, Course: "" }); // Clear the error message
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            //onKeyPress={handleKeyPress}
                            placeholder="Course"
                            //autoComplete="off"
                          />
                        )}
                      />

                      <span className="text-danger" id="errCourse">
                        {error.Course}
                      </span>
                    </div>
                  </div>

                  <div class="col-lg-4 mb-2">
                    <div class="form-group">
                      <label>
                        Specialization <span class="text-danger">*</span>
                      </label>

                      <Autocomplete
                        //disablePortal
                        options={specializationModel}
                        sx={{ width: 300 }}
                        value={specialization}
                        // onChange={(e) => {
                        //   setcourse(e.target.label);
                        //   setformError({ ...error, Course: "" }); // Clear the error message
                        // }}
                        name="specialization"
                        id="specialization"
                        //class="form-control"
                        onKeyUp={(e) => {
                          debugger;
                          setspecialization(e.target.defaultValue);
                          setformError({ ...error, specialization: "" }); // Clear the error message
                        }}
                        onChange={(e) => {
                          debugger;
                          setspecialization(e.target.innerText);
                          setformError({ ...error, specialization: "" }); // Clear the error message
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            //onKeyPress={handleKeyPress}
                            placeholder="Specialization"
                            //autoComplete="off"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div class="col-lg-4 mb-2">
                    <div class="form-group">
                      <label>
                        College Name <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="collegeName"
                        id="collegeName"
                        value={collegeName}
                        class="form-control"
                        onChange={(e) => {
                          setcollegeName(e.target.value);
                          setformError({ ...error, CollegeName: "" }); // Clear the error message
                        }}
                        onKeyPress={handleKeyPress}
                        placeholder="College Name"
                        autoComplete="off"
                      />
                      <span className="text-danger" id="errCollegeName">
                        {error.CollegeName}
                      </span>
                    </div>
                  </div>

                  <div class="col-lg-4 mb-2">
                    <div class="form-group">
                      <label>Start Month & Year</label>
                      <div>
                        <DatePicker
                          name="startDate"
                          id="startDate"
                          className="form-control"
                          selected={startDate}
                          dateFormat="MMMM yyyy"
                          showMonthYearPicker
                          //showYearDropdown // Enable year dropdown
                          //showMonthDropdown // Enable month dropdown
                          dropdownMode="select" // Use select dropdowns for year and month
                          //dateFormat="dd/MM/yyyy" // Specify your desired date format
                          onChange={(date) => {
                            setStartDate(date);
                            setformError({ ...error, StartDate: "" }); // Clear the error message
                          }}
                          onKeyDown={handleKeyPress}
                          placeholderText="Start Date"
                          maxDate={new Date()}
                          autoComplete="off"
                        />
                        {/* <DatePicker
                          name="startDate"
                          id="startDate"
                          className="form-control"
                          selected={startDate}
                          onChange={(date) => {
                            setStartDate(date);
                            setformError({ ...error, StartDate: "" }); // Clear the error message
                          }}
                          onKeyDown={handleKeyPress}
                          placeholderText="Date Of Birth"
                          maxDate={new Date()}
                        /> */}
                        {/* <input
                          type="date"
                          id="startDate"
                          name="startDate"
                          className="form-control"
                          value={startDate}
                          onChange={handleStartDateChange}
                        /> */}
                        <span className="text-danger" id="errStartDate">
                          {error.StartDate}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4 mb-2">
                    <div class="form-group">
                      <label>End Month & Year</label>
                      <div>
                        <DatePicker
                          name="endDate"
                          id="endDate"
                          className="form-control"
                          selected={endDate}
                          onChange={(date) => {
                            setEndDate(date);
                            setformError({ ...error, EndDate: "" }); // Clear the error message
                          }}
                          minDate={startDate}
                          onKeyDown={handleKeyPress}
                          dateFormat="MMMM yyyy"
                          showMonthYearPicker
                          //showYearDropdown // Enable year dropdown
                          //showMonthDropdown // Enable month dropdown
                          dropdownMode="select" // Use select dropdowns for year and month
                          //dateFormat="dd/MM/yyyy" // Specify your desired date format
                          placeholderText="End Date"
                          autoComplete="off"
                        />
                        {/* <DatePicker
                          name="endDate"
                          id="endDate"
                          className="form-control"
                          selected={endDate}
                          onChange={(date) => {
                            setEndDate(date);
                            setformError({ ...error, EndDate: "" }); // Clear the error message
                          }}
                          minDate={startDate}
                          onKeyDown={handleKeyPress}
                          placeholderText="Date Of Birth"
                        /> */}
                        {/* <input
                          type="date"
                          id="endDate"
                          name="endDate"
                          className="form-control"
                          value={endDate}
                          min={startDate} // Set the minimum date for the end date input
                          onChange={handleEndDateChange}
                        /> */}
                        <span className="text-danger" id="errEndDate">
                          {error.EndDate}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-primary float-right"
                  style={{ marginLeft: "10px" }}
                >
                  Save & Next
                </button>
              </div>
            </form>
          ) : (
            <></>
          )}

          {activeStep == 3 ? (
            <form
              id="BankDetailsCreate"
              onKeyPress={(e) => {
                if (e.key == "Enter") {
                  e.preventDefault();
                }
              }}
              onSubmit={BankDetails}
            >
              <div id="wizard_Service" class="tab-pane" role="tabpanel">
                <div class="card-header" style={{ marginBottom: "25px" }}>
                  <h1 class="card-title">Bank Details</h1>
                </div>
                <div class="row ">
                  <div class="col-lg-3 mb-2">
                    <div class="form-group">
                      <label>
                        Bank Name <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="bankName"
                        id="bankName"
                        onKeyPress={handleKeyPress}
                        value={bankName}
                        onChange={(e) => {
                          setbankName(e.target.value);
                          setformError({ ...error, BankName: "" }); // Clear the error message
                        }}
                        class="form-control"
                        placeholder="Bank Name"
                        autoComplete="off"
                      />
                      <span className="text-danger" id="errBankName">
                        {error.BankName}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3 mb-2">
                    <div className="form-group">
                      <label>Account Holder Name <span class="text-danger">*</span></label>
                      <input
                        type="text"
                        name="holderName"
                        id="holderName"
                        value={holderName}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^[a-zA-Z\s]*$/.test(value)) {
                            setholderName(value);
                            setformError({ ...error, HolderName: "" }); // Clear the error message
                          }
                        }}
                        className="form-control"
                        placeholder="Account Holder Name"
                        autoComplete="off"
                      />
                      <span className="text-danger" id="errHolderName">
                        {error.HolderName}
                      </span>
                    </div>
                  </div>

                  <div class="col-lg-3 mb-2">
                    <div class="form-group">
                      <label>
                        Account Number <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="bankAccNumber"
                        id="bankAccNumber"
                        class="form-control"
                        value={bankAccNumber}
                        maxLength={25}
                        onChange={(e) => {
                          setbankAccNumber(e.target.value);
                          setformError({ ...error, BankAccNumber: "" }); // Clear the error message
                        }}
                        onKeyPress={(e) => {
                          if (!/^\d+$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        placeholder="Account Number"
                        autoComplete="off"
                      />
                      <span className="text-danger" id="errBankAccNumber">
                        {error.BankAccNumber}
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-3 mb-2">
                    <div class="form-group">
                      <label>
                        IFSC Code <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="ifscCode"
                        id="ifscCode"
                        className="form-control"
                        placeholder="IFSC Code"
                        onChange={handleInputChange}
                        value={ifscCode}
                        maxLength={11}
                        autoComplete="off"
                      />

                      <span className="text-danger" id="errIFSCCode">
                        {error.IFSCCode}
                      </span>
                    </div>
                  </div>

                  {/* <div class="col-lg-4 mb-2">
                    <div class="form-group">
                      <label>
                        Account Type <span class="text-danger">*</span>
                      </label>
                      <Select
                        name="bankAccType"
                        id="bankAccType"
                        options={AccountType}
                        value={bankAccType}
                        onChange={(e) => {
                          setbankAccType(e);
                          setformError({ ...error, BankAccType: "" }); // Clear the error message
                        }}
                      />
                      <span className="text-danger" id="errBankAccType">
                        {error.BankAccType}
                      </span>
                    </div>
                  </div> */}

                  <div class="col-lg-3 mb-2">
                    <div class="form-group">
                      <label>
                        Branch <span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="bankBranch"
                        class="form-control"
                        placeholder="Branch"
                        onKeyPress={handleKeyPress}
                        value={bankBranch}
                        onChange={(e) => {
                          setbankBranch(e.target.value);
                          setformError({ ...error, BankBranch: "" }); // Clear the error message
                        }}
                        autoComplete="off"
                      />
                      <span className="text-danger" id="errBankBranch">
                        {error.BankBranch}
                      </span>
                    </div>
                  </div>

                  <div class="col-lg-3 mb-2">
                    <div class="form-group">
                      <label class="text-label form-label">
                        {" "}
                        {StudentID > 0 && filepath !== null
                          ? "Update Passbook"
                          : "Upload Passbook"}{" "}
                        <span class="text-danger">*</span>
                      </label>

                      <div>
                        <input
                          type="file"
                          name="UploadPath"
                          className="form-control"
                          accept=".jpg, .jpeg, .png, .pdf"
                          onChange={(e) => {
                            handleFileChange(e); // Call the existing handler
                            setformError({ ...error, UploadPath: "" }); // Clear the error message
                          }}
                        />

                        <div
                          class="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {fileerror}
                        </div>
                        <div
                          class="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {error.UploadPath}
                        </div>
                      </div>
                    </div>
                  </div>
                  {filepath && StudentID > 0 &&
                    filepath !== null &&
                    filepath !== "null" && (
                      <div class="col-lg-4 mb-2">
                        <div class="form-group">
                          <label class="text-label form-label">
                            Download Passbook
                          </label>

                          <div>
                            <>
                              {/* <a href={downloadpath} download>
                        <CloudDownloadIcon className="download-icon" />
                        <div>{filepath}</div>
                      </a> */}

                              <a
                                onClick={() => download(filepath)}
                                title="Download"
                              >
                                <CloudDownloadIcon className="download-icon" />
                                {/* <div>{filepath}</div> */}
                              </a>
                            </>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <button
                  className="btn btn-primary float-right"
                  style={{ marginLeft: "10px" }}
                  disabled={status == "Requested" ? true : false}
                >
                  {status == "Requested" ? "Requested" : "Update"}
                </button>
              </div>
            </form>
          ) : (
            <></>
          )}

          {activeStep < steps.length - 1 ? (
            <>
              <button
                className="btn btn-info float-right"
                onClick={handleNext}
                style={{ marginLeft: "10px" }}
              >
                Next
              </button>
              {activeStep > 0 && (
                <button
                  type="button"
                  className="btn btn-danger float-right"
                  //data-dismiss="modal"
                  onClick={handlePrevious}
                >
                  Previous
                </button>
              )}
            </>
          ) : (
            <>
              {/* <button
                className="btn btn-primary float-right"
                style={{ marginLeft: "10px" }}
              >
                Submit
              </button> */}

              {cookies.roleName != "Student" ? (
                <button
                  type="button"
                  className="btn btn-danger float-right"
                  //data-dismiss="modal"
                  onClick={handlePrevious}
                >
                  Previous
                </button>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default StudentEdit;
