import React, { useEffect, useState, useRef, useReducer } from "react";
import ApplicarionConfigService from "../../api/ApplicationConfigService";
import $ from "jquery";
import swal from "sweetalert";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const AppService = new ApplicarionConfigService();

function DropdownList() {
  const [DropdowndataList, SetDropdowndataList] = useState([]);
  const [Dropdowntype, SetDropdowntype] = useState([]);

  const [DropdowntypeID, SetDropdowntypeID] = useState("0");
  const [DropdowntypeValue, SetDropdowntypeValue] = useState("");
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const valueRef = useRef(null);
  const SortOrderRef = useRef(null);
  const [levelList, SetLevelList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const onKeyDown = (event) => {
    // if (event.code === 'Space') event.preventDefault()
  };

  useEffect(() => {
    if (DropdowntypeValue !== "") {
      debugger;
      AppService.getData(DropdowntypeValue).then((res) => {
        if (res != null && res.data != null && res.data.length > 0) {
          SetDropdowndataList([...res.data]);
        } else {
          SetDropdowndataList([...res.data]);
        }
      });

      var id = 0;
      AppService.GetStepDropDownList(id).then((res) => {
        console.log(res.data);
        if (res != null && res.data != null && res.data.length > 0) {
          console.log(res.data);
          SetLevelList([...res.data]);
        } else {
          SetLevelList(null);
        }
      });

      if (DropdowntypeValue == "STEP" || DropdowntypeValue == "Step") {
        $(".SortOrderAll").show();

        $(".SortOrdertableHeader").text("Sort Order");
        $(".StepLeveltableHeader").text("Step Level");
      } else {
        $(".SortOrderAll").hide();
        $(".SortOrdertableHeader").text("");
        $(".StepLeveltableHeader").hide();
      }
      document.getElementById("values").value = "";
      document.getElementById("SortOrder").value = "";
    }
  }, [DropdowntypeValue, Render]);

  const handleDropdownChange = (event) => {
    debugger;
    setSelectedValue(event.target.value);
    hideErrorMessage();
  };

  const columns = [
    {
      name: "id",
      label: "id",
      options: {
        filter: true,
        sort: true,
        display: "none",
      },
    },

    {
      name: "value",
      label: "Value",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "sortOrder",
      label: "Sort Order",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "stepLevel",
      label: "Step Level",
      options: {
        filter: true,
        sort: true,
        display:
          DropdowntypeValue === "STEP" || DropdowntypeValue === "Step"
            ? "none"
            : "",
      },
    },

    {
      name: "OPTION",
      label: "Option",
      options: {
        filter: true,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          const id = tableMeta.rowData[0];
          const value = tableMeta.rowData[1];
          const sortOrder = tableMeta.rowData[2];
          const stepLevel = tableMeta.rowData[3];
          return (
            <div className="center">
              <IconButton
                title="Edit"
                data-original-title="Edit"
                onClick={(e) => EditDropdown(id, value, sortOrder, stepLevel)}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                title="Delete"
                data-original-title="Remove"
                onClick={(e) => DeleteDropdown(id)}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  useEffect(() => {
    AppService.DropDownTypeList().then((res) => {
      if (res != null && res.data != null && res.data.length > 0) {
        SetDropdowntype(res.data);
        SetDropdowntypeValue(res.data[0].value);
      }
    });
  }, []);

  function DeleteDropdown(id) {
    $(window).scrollTop(0);
    swal({
      title: "Are You Sure?",

      text: "Do you want to delete this data from the list?",

      buttons: true,

      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const obj = {
          ID: Number(id),
          Term: DropdowntypeValue,
        };
        AppService.Deletedata(obj)
          .then((response) => {
            PageRender();
            swal("Dropdownlist", "Deleted Successfully!", "success");
            document.getElementById("values").value = "";
          })
          .catch((error) => {
            if (error.response.data === "SLA Name Already Exist.") {
              swal("Dropdownlist", "SLA Name Already Exist!", "error");
              // Toast("SLA Name Already Exist.", 'error');
            } else {
              swal("Dropdownlist", "Something went wrong!", "error");
              // Toast("Something went wrong", 'error');
            }
          });
      }
    });
  }
  function checkexitsornot(obj) {
    AppService.GetSortOrdercheckexitsornot(obj)
      .then((res) => {
        console.log(res);

        GetSortOrdercheckexitsornot(obj);
        // Savefunction(obj);
      })
      .catch((err) => {
        $("#errValue").text("Sort Value is already exists");
      });
  }
  function GetSortOrdercheckexitsornot(obj) {
    debugger;
    AppService.Getcheckexitsornot(obj)
      .then((res) => {
        Savefunction(obj);
      })
      .catch((err) => {
        alert();
        $("#errValue").text(" Value is already exists");
      });
  }

  function Savefunction(obj) {
    debugger;
    $("#errValue").text("");
    AppService.CreateSave(obj)
      .then((response) => {
        PageRender();
        document.getElementById("values").value = "";
        document.getElementById("SortOrder").value = "";
        $("#StepLevel").val("");
        SetDropdowntypeID(0);
        swal("Dropdownlist", "Saved Successfully!", "success");
      })
      .catch((error) => {
        if (error.response.data === "SLA Name Already Exist.") {
          swal("Dropdownlist", "SLA Name Already Exist!", "error");
          // Toast("SLA Name Already Exist.", 'error');
        } else {
          swal("Dropdownlist", "Something went wrong!", "error");
          // Toast("Something went wrong", 'error');
        }
      });
  }
  function SaveDropdown() {
    const obj = {
      ID: Number(DropdowntypeID),
      Term: DropdowntypeValue,
      Value: valueRef.current.value,
      SortOrder: Number(SortOrderRef.current.value),
      StepLevel: $("#StepLevel").val(),
    };
    if (obj.Value == null || obj.Value == "") {
      $("#errValue").text("Please Enter Value");
      return false;
    }
    if (DropdowntypeValue == "STEP" || DropdowntypeValue == "Step") {
      if (obj.StepLevel == null || obj.StepLevel == "") {
        $("#errStepLevel").text("Please Select Step Level");
        return false;
      }
      if (obj.SortOrder == null || obj.SortOrder == "") {
        $("#errSortOrder").text("Please Enter Sort Order ");
        return false;
      }
    }
    $("#StepLevel").val("");

    checkexitsornot(obj);
    // GetSortOrdercheckexitsornot(obj);
  }
  function EditDropdown(id, value, sortOrder, stepLevel) {
    $(window).scrollTop(0);
    document.getElementById("values").value = value;
    SetDropdowntypeID(id);
    document.getElementById("ID").value = id;
    document.getElementById("SortOrder").value = sortOrder;
    $("#StepLevel").val(stepLevel);

    setSelectedValue(stepLevel);
  }

  function hideErrorMessage() {
    $("#errValue").text("");
    $("#errStepLevel").text("");
    $("#errSortOrder").text("");
  }
  const options = {
    responsive: true,
    download: true,
    print: true,
    selectableRows: false,
    filter: true,
    viewColumns: true,
    textLabels: {
      body: {
        noMatch: "No records found",
      },
    },
  };
  return (
    <>
      <div className="row card-body">
        <div className="col-lg-4 col-md-9 col-sm-12">
          <div class="form-group">
            <label>Type</label>
            <select
              class="form-control "
              id=""
              onChange={(e) => SetDropdowntypeValue(e.target.value)}
              value={DropdowntypeValue}
            >
              {Dropdowntype &&
                Dropdowntype.map((x, i) => {
                  return (
                    // x.term != 'STATUS' &&
                    <option key={i} value={x.term}>
                      {x.value}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
        <div className="col-lg-4 col-md-9 col-sm-12">
          <input type="hidden" name="ID" id="ID" />
          <div class="form-group">
            <label>Values</label>
            <input
              type="text"
              class="form-control "
              id="values"
              ref={valueRef}
              onChange={() => hideErrorMessage()}
              onKeyDown={(event) => onKeyDown(event)}
            ></input>
            <span style={{ color: "red" }} id="errValue"></span>
          </div>
        </div>
        <div className="col-lg-12 col-md-9 col-sm-12 SortOrderAll ml-3">
          <div className="row">
            <div class="form-group col-lg-4 col-md-9 col-sm-12">
              <label>Step Level</label>
              <select
                className="form-control"
                id="StepLevel"
                value={selectedValue}
                onChange={handleDropdownChange}
              >
                <option value="">Select Step Level</option>
                {levelList.map((item) => (
                  <option key={item.roleID} value={item.roleID}>
                    {item.roleName}
                  </option>
                ))}
              </select>
              <span style={{ color: "red" }} id="errStepLevel"></span>
            </div>
            <div class="form-group col-lg-4 col-md-9 col-sm-12">
              <label>Sort Order</label>
              <input
                type="number"
                class="form-control SortOrderAll"
                id="SortOrder"
                ref={SortOrderRef}
                onChange={() => hideErrorMessage()}
                onKeyDown={(event) => onKeyDown(event)}
              ></input>
              <span style={{ color: "red" }} id="errSortOrder"></span>
            </div>
          </div>
          <input type="hidden" name="ID" id="ID" />
        </div>
        <div
          className="col-lg-12 mt-6 col-md-9 col-sm-12"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <button
            type="button"
            id="addRowButton"
            class="btn btn-primary btn-sm"
            style={{ marginTop: "1%" }}
            onClick={SaveDropdown}
          >
            Add
          </button>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div class="table-responsive">
            {DropdowndataList !== null && DropdowndataList.length > 0 ? (
              <MUIDataTable
                data={DropdowndataList}
                columns={columns}
                options={options}
              />
            ) : (
              <MUIDataTable
                data={DropdowndataList}
                columns={columns}
                options={options}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default DropdownList;
