import React, { useState, useReducer, useEffect, useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ApprovalService from "../../api/ApprovalService";
import $ from "jquery";
import Toast from "../Toastify";
import Axios from "axios";
import { saveAs } from "file-saver";
import ReimbursementService from "../../api/ReimbursementService";

const RemObj = new ReimbursementService();

const _ApprovalService = new ApprovalService();

const AdvanceBillApproval = (props) => {
  const { ID, Status, StageId, StudentId } = props;

  const { cookies, logout } = useContext(AuthContext);

  const [funTypeList, setFunTypeList] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [TicketCode, setTicket] = useState("");
  const [amount, setAmount] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [advBillDate,setAdvBillDate]=useState("");
  const [advBillNumber, setAdvBillNumber] = useState("");
  const [FundDetail, setFundDetails] = useState("");
  const [TicketId, setTicketId] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [filepath, Setfilepath] = useState("");
  const [advancefilepath, Setadvancefilepath] = useState("");
  const [fileerror, setfileerror] = useState("");
  const [downloadpath, setdownloadpath] = useState("");
  const [commet, setComment] = useState("");
  const [error, seterror] = useState("");
  const [StudentName, SetStudentName] = useState("");
  const [StudentRequestList, SetStudentRequestList] = useState([]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }


  useEffect(() => {
    var roleId = cookies.roleID;
    if (ID > 0) {
      _ApprovalService
        .Get_RequestById(ID, roleId, 0)
        .then((response) => {
          const data = response.data;

          debugger;
          if (
            data &&
            data.approvalRequests &&
            data.approvalRequests.length > 0
          ) {
            const firstTicket = data.approvalRequests[0];
            setTicket(firstTicket.ticketNumber);
            // setSelectedTicket({
            //   value: firstTicket.fundType,
            //   label: firstTicket.type,
            // });
            debugger
            setSelectedTicket(firstTicket.type);
            setAmount(firstTicket.amount);
            setBillNumber(firstTicket.billNumber);
            if (firstTicket?.advBillDate) {
              setAdvBillDate(formatDate(firstTicket.advBillDate));
            } else {
              setAdvBillDate("");
            }
            
              setAdvBillNumber(firstTicket.advBillNumber);
           
            setFundDetails(firstTicket.ticketDetails);
            Setfilepath(firstTicket.fileName);
            Setadvancefilepath(firstTicket.uploadAdvanceBill);
            // setdownloadpath(firstTicket.downloadPath);
            setdownloadpath(
              Axios.defaults.baseURL + "/Uploadfiles/" + firstTicket.fileName
            );
            SetStudentName(firstTicket.studentName);
            console.log("Fetched ticket data:", firstTicket);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    debugger;
    if (StudentId > 0) {
      _ApprovalService
        .Student_CreditedInfo(StudentId)
        .then((res) => {
          if (res != null && res.data != null && res.data.length > 0) {
            const result = res.data;
            SetStudentRequestList(result);
          }
          $(".loader-container").hide();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ID]);

  const HandleSubmit = (id) => {
    debugger
    var flag = false;
    // if (Status == -1) {
    //   if (commet == null || commet == "") {
    //     seterror("Please enter your remarks");
    //     flag = true;
    //   }
    // }

    if (flag == false) {
      const requestData = {
        TicketId: ID,
        // ApproverStageId: StageId,
        //ApproverStageId: 0,
        FirstLevelApprovedBy: cookies.userID,
        FirstLevelComment: commet,
        //RoleName: cookies.roleName,
        IsApproved: id,
        //RoleId: cookies.roleID,
        //IsClosed: Number(0),
      };

      $(".loader-container").show();

      _ApprovalService
        .ApproveAdvanceBill_Request(requestData)
        .then((response) => {
          $(".loader-container").hide();

          if (id == 1) {
            Toast("Request Approved Successfully", "success");
          } else if (id == 0) {
            Toast("Request Rejectd Successfully", "success");
          } else {
            Toast("Request Approved Successfully", "success");
          }
          setTimeout(() => {
            window.location.reload();
          }, 2000);

          setGridRender();
        })
        .catch((error) => {
          $(".loader-container").hide();
          console.error("Error while saving:", error);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
    }
  };
  function download(url) {
    RemObj.Download(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }

  return (
    <div class="row">
      <div class="col-lg-8">
        <div class="row">
          <div class="col-lg-6 mb-2">
            <label class="text-label form-label">Request Number</label>
            <input
              type="text"
              name="RegNo"
              class="form-control"
              placeholder=""
              value={TicketCode}
              disabled={true}
            />
          </div>
          <div class="col-lg-6 mb-2">
            <label class="text-label form-label">Student Name</label>
            <input
              type="text"
              name="RegNo"
              class="form-control"
              placeholder=""
              value={StudentName}
              disabled={true}
            />
          </div>

          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">Fund Type</label>
            <input
              type="text"
              name="RegNo"
              class="form-control"
              disabled={true}
              value={selectedTicket}
            />
          </div>

          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">Amount</label>
            <input
              type="text"
              name="Amount"
              class="form-control"
              value={amount}
              disabled={true}
            />
          </div>
          {selectedTicket == "Reimbursement Request" && <>
          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">Bill Number</label>
            <input
              type="text"
              name="firstName"
              class="form-control"
              value={billNumber}
              disabled={true}
            />
          </div>
          </>}

          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">{selectedTicket=="Advance Request"?downloadpath != null ? "View Reference / Proof" : "Reference / Proof":downloadpath != null ? "View Bill" : "Upload Bill"}</label>
            <div>
              {downloadpath != null ? (
                <span className="file-info">
                  {/* <a href={downloadpath} download>
                    <CloudDownloadIcon className="download-icon" />
                    <div>{filepath}</div>
                  </a> */}

                  <a onClick={() => download(filepath)}>
                    <CloudDownloadIcon className="download-icon" />
                    {/* <div>{filepath}</div> */}
                  </a>
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">
              Advance Bill Number</label>
            <input
              type="text"
              name="firstName"
              class="form-control"
              value={advBillNumber}
              disabled={true}
            />
          </div>

          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">Advance Bill Date</label>
            <input
              type="text"
              name="advBillDate"
              class="form-control"
              value={advBillDate}
              disabled={true}
            />
          </div>

          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">View Advance Bill</label>
            <div>

                <span className="file-info">
                  {/* <a href={downloadpath} download>
                    <CloudDownloadIcon className="download-icon" />
                    <div>{filepath}</div>
                  </a> */}

                  <a onClick={() => download(advancefilepath)}>
                    <CloudDownloadIcon className="download-icon" />
                    {/* <div>{filepath}</div> */}
                  </a>
                </span>
              
            </div>
          </div>
          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">
              Fund Details<span class="text-danger">*</span>
            </label>
            <div>
              <textarea
                name="lastName"
                class="form-control"
                value={FundDetail}
                disabled={true}
              ></textarea>
            </div>
          </div>

          <div class="col-lg-6 mb-3">
              <div class="mb-3">
                <label class="text-label form-label">Remarks</label>
                <div>
                  <textarea
                    name="firstLevelComment"
                    class="form-control"
                    placeholder="Enter your remarks"
                    value={commet}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
              </div>
           
          </div>

          <div className="col-12 modal-footer no-bd">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              Cancel
            </button>
            <button
              class="btn btn-danger float-right"
              style={{ marginLeft: "10px" }}
              onClick={()=> HandleSubmit(0)}
            >
              Reject
            </button>
            <button
              class="btn btn-primary float-right"
              style={{ marginLeft: "10px" }}
              onClick={()=> HandleSubmit(1)}
            >
              Approve
            </button>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
         {StudentRequestList != null && StudentRequestList.length > 0 ?
           <div className="trans">
              {/* <p> <span style={{ fontWeight: "bold"}}>Allocated Amount :</span> {StudentRequestList[0].allocatedAmount}</p> */}
              <p><span style={{ fontWeight: "bold"}}>Total Credited Amount :</span> {StudentRequestList[0].totalCreditedAmount}</p>
              {/* <p><span style={{ fontWeight: "bold"}}>Balance Amount :</span> {StudentRequestList[0].balanceAmount}</p> */}
            </div> : <></>
          }
        <div
          className={StudentRequestList.length > 10 ? "trans-scroll" : "trans"}
        >
          <h3 style={{ fontWeight: "bold", color: "#1da837" }}>
            {" "}
            Transaction History
          </h3>
          <table
                    id="add-row"
                    className="display table table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <th> Date</th>
                        <th> Fund Type</th>
                        <th> Amount</th>
                        <th> View Bill</th>
                        <th> View Advance Bill</th>
                      </tr>
                    </thead>
                    <tbody>
                      {StudentRequestList.map((item, index) => (
                        <tr key={index}>
                          <td>{item.creditedDate}</td>
                          <td>{item.fundType}</td>
                          <td>{item.creditedAmount}</td>
                          <td>
                      
                      {item.billUrl != null ?
                       <a target="_blank" onClick={() => download(item.billUrl)} title="Download">
                        <CloudDownloadIcon className="download-icon" />
                        {/* <div>{filepath}</div> */}
                      </a> : <></>}
                      </td>
                      <td>
                      
                      {item.uploadAdvanceBill != null ?
                       <a target="_blank" onClick={() => download(item.uploadAdvanceBill)} title="Download">
                        <CloudDownloadIcon className="download-icon" />
                        {/* <div>{filepath}</div> */}
                      </a> : <></>}
                      </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
        </div>
      </div>
    </div>
  );
};
export default AdvanceBillApproval ;
