import React, { useState, useReducer } from "react";
import $ from "jquery";
import { useEffect } from "react";
import AssociateService from "../../api/AssociateService";
import Toast from "../Toastify";
import makeAnimated from "react-select/animated";
import MySelect from "../MultiSelect/Myselect";
import { components } from "react-select";
// import {Switch} from "antd";
// import Select from 'react-dropdown-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AssociateServiceobj = new AssociateService();

const Options = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);
const animatedComponents = makeAnimated();

function AddOrEditEmployee(props) {
  const { employeeID } = props;

  const [dateOfBirth, setdateOfBirth] = useState("");
  const [toogle, setToggle] = useState(false);
  const [reportinglistbyselect, setreportinglistbyselect] = useState([]);
  const [rolelistbyselect, setrolelistbyselect] = useState([]);
  const [HonorTitle, setHonorTitle] = useState([]);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [EmployeeModel, setEmployeeModel] = useState([]);
  const [RoleList, setRoleList] = useState([]);
  const [ReportingList, setReportingList] = useState([]);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [HODList, setHODList] = useState([]);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [SelectedVAl, setSelectedVAl] = useState(null);
  const [error, setformError] = useState({
    employee_Code: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Title: "",
    Location: "",
    Mobile_Number: "",
    AlternateMobileNumber: "",
    Email: "",
    Role: "",
    status: "",
    LocationStatus: "",
  });
  const [LocationStatusSelect, setLocationStatusSelect] = useState([]);
  const [LocationStatus, setLocationStatus] = useState([]);

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployeeModel((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const toogler = () => {
    toogle ? setToggle(false) : setToggle(true);
  };

  useEffect(() => {
    document.getElementById("AddOrEditEmployee").reset();
    debugger;
    setformError([]);
    AssociateServiceobj.Create(employeeID).then((res) => {
      var data = res.data;

      setEmployeeModel(data);

      setRoleList(data.roleList);
      setReportingList(data.reportingList);
      //  setreportinglistbyselect(data.reportingSeletedList)
      setHonorTitle(data.title);

      setreportinglistbyselect(data.reportManager);

      var stationIDs =
        data.stringRoleID != null ? data.stringRoleID.split(",") : [];
      var stationArray = [];
      RoleList.map((x) => {
        for (var i = 0; i < stationIDs.length; i++) {
          if (Number(stationIDs[i]) == x.value) {
            const values = {
              label: x.label,
              value: x.value,
            };
            stationArray.push(values);
          }
        }
      });
      setrolelistbyselect([...stationArray]);
      var LocStatus =
        data.locationStatus !== null && data.locationStatus != ""
          ? data.locationStatus.split(",")
          : [];
      debugger;
      var LocStatusArray = [];
      LocationStatus.map((i) => {
        debugger;
        LocStatus.map((j) => {
          if (i.value == j) {
            LocStatusArray.push(i);
          }
        });
      });
      setLocationStatusSelect([...LocStatusArray]);
    });
  }, [employeeID, GridRender]);

  const onSubmit = (data) => {
    debugger;
    data.preventDefault();

    var myerror = error;
    var data = EmployeeModel;
    data.isActive = Number(EmployeeModel.isActive);
    data.mobile_Number = document.getElementById("mobile_Number").value;
    data.alternateMobileNumber = document.getElementById(
      "alternateMobileNumber"
    ).value;
    if (document.getElementById("dateofBirth").value == "") {
      data.dateofBirth = null;
    } else {
      data.dateofBirth = document.getElementById("dateofBirth").value;
    }

    data.dateofBirth = dateOfBirth;
    data.stringRoleID = "";
    data.roleList = [];

    for (var i = 0; i < rolelistbyselect.length; i++) {
      data.stringRoleID += rolelistbyselect[i].value + ",";
    }
    debugger;
    data.rolelistbyselect = {};

    //data.reportManager = document.getElementById("reportingmanager").value;
    //data.title = document.getElementById("HonorTitle").value;
    var flag = true;
    console.log(data);
    if (data.employee_Code == null || data.employee_Code == "") {
      myerror.employee_Code = "Employee Code is Required";
      flag = false;
    } else {
      if (/[^A-Za-z0-9]+/.test(data.employee_Code)) {
        myerror.employee_Code = "Sorry! Only Allow Alphabets and Numbers";
        flag = false;
      } else myerror.employee_Code = "";
    }

    if (data.email == null || data.email == "") {
      myerror.Email = " Email is Required";
      flag = false;
    } else {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
        myerror.Email = "Sorry! Email ID is Not Vaild";
        flag = false;
      } else myerror.Email = "";
    }

    if (data.firstName == null || data.firstName == "") {
      myerror.FirstName = " First Name is Required";
      flag = false;
    } else {
      if (!/^[A-Za-z .]+$/.test(data.firstName)) {
        myerror.FirstName = "Sorry! Only Allow Alphabets";

        flag = false;
      } else myerror.FirstName = "";
    }
    // if (data.lastName == null || data.lastName == "") {
    //   myerror.LastName = " Last Name is Required";
    //   flag = false;
    // } else {
    //   if (!/^[A-Za-z .]+$/.test(data.lastName)) {
    //     myerror.LastName = "Sorry! Only Allow Alphabets";

    //     flag = false;
    //   } else myerror.LastName = "";
    // }
    // if (data.location == null || data.location == "") {
    //   myerror.Location = " Address is Required";
    //   flag = false;
    // }
    if (data.mobile_Number == null || data.mobile_Number == "") {
      myerror.Mobile_Number = " Mobile Number is Required";
      flag = false;
    } else if (data.mobile_Number.length < 10) {
      myerror.Mobile_Number = " Mobile Number is invalid";
      flag = false;
    }
    if (data.stringRoleID == "") {
      myerror.Role = "Role is required";
      flag = false;
    } else {
      myerror.Role = "";
    }
    setformError({ ...myerror });

    if (flag) {
      $(".loader-container").show();

      data.status = "AC";

      AssociateServiceobj.Register(data)
        .then((response) => {
          debugger;
          $(".loader-container").hide();
          if (data.employeeID == 0) {
            Toast("Employee Created Successfully", "success");
          } else {
            Toast("Employee Updated Successfully", "success");
          }
          $(".close").trigger("click");

          window.location.reload();
        })
        .catch((error) => {
          debugger;
          $(".loader-container").hide();
          if (error.response.data === "email") {
            Toast("Email ID is Already Exists.", "Warning");
          } else if (error.response.data === "empcode") {
            Toast("Employee Code is Already Exists.", "Warning");
          } else {
            Toast("Mail is not working ", "error");
            console.log(error);
            $(".close").trigger("click");

            window.location.reload();
          }
        });
    }
    // }
  };
  function EmplocodeChange() {
    var Empcode = $("#employee_Code").val();
    if (Empcode == "") {
      $("#erremployee_Code").text("Employee Code is Required");
    } else {
      $("#erremployee_Code").text("");
    }
  }
  function FirstNameChange() {
    var FirstName = $("#employee_Code").val();
    if (FirstName == "") {
      $("#erremployee_Code").text("Employee Code is Required");
    } else {
      $("#erremployee_Code").text("");
    }
  }

  function firstNameChange() {
    var FirstName = $("#firstName").val();
    if (FirstName == "") {
      $("#errfirstName").text("First Name is Required");
    } else {
      $("#errfirstName").text("");
    }
  }

  function lastNameChange() {
    var lastName = $("#lastName").val();
    if (lastName == "") {
      $("#errlastName").text("Last Name is Required");
    } else {
      $("#errlastName").text("");
    }
  }

  // function locationChange() {
  //   var location = $("#location").val();
  //   if (location == "") {
  //     $("#errlocation").text("Location  is Required");
  //   } else {
  //     $("#errlocation").text("");
  //   }
  // }

  function mobile_NumberChange() {
    var mobile_Number = $("#mobile_Number").val();
    if (mobile_Number == "") {
      $("#errmobile_Number").text("Mobile Number is Required");
    } else {
      $("#errmobile_Number").text("");
    }
  }

  function emailChange() {
    var email = $("#email").val();
    if (email == "") {
      $("#erremail").text("Email Id is Required");
    } else {
      $("#erremail").text("");
    }
  }

  function ROleOnchange(e) {
    $("#errroleSeletedList").text("");

    setrolelistbyselect([...e]);
  }

  function numberOnly(e) {
    // var element = document.getElementById(id);
    // element.value = element.value.replace(/[^0-9]/gi, "");
    const inputChar = String.fromCharCode(e.charCode);
    if (!/^\d+$/.test(inputChar)) {
      e.preventDefault();
    }
  }

  function LocationStatusOnchange(e) {
    debugger;
    $("#errLocStaSeletedList").text("");
    setLocationStatusSelect([...e]);
  }

  const clearForm = (e) => {
    e.preventDefault();
    window.location.reload();
  };
  return (
    <div>
      <form
        id="AddOrEditEmployee"
        onKeyPress={(e) => {
          if (e.key == "Enter") {
            e.preventDefault();
          }
        }}
        onSubmit={onSubmit}
      >
        <div class="row">
          <div class="col-sm-4">
            <div class="form-group ">
              <label>
                Employee Code <span className="text-danger">*</span>
              </label>
              {employeeID == 0 ? (
                <input
                  type="text"
                  className="form-control"
                  maxLength={12}
                  value={EmployeeModel.employee_Code}
                  placeholder="Employee Code"
                  name="employee_Code"
                  onChange={handleChange}
                  onBlur={EmplocodeChange}
                  id="employee_Code"
                  onKeyDown={(event) => onKeyDown(event)}
                  autoComplete="off"
                />
              ) : (
                <input
                  type="text"
                  className="form-control"
                  maxLength={5}
                  value={EmployeeModel.employee_Code}
                  placeholder="Employee Code"
                  name="employee_Code"
                  onChange={handleChange}
                  onKeyDown={(event) => onKeyDown(event)}
                  disabled
                />
              )}
              <span className="text-danger" id="erremployee_Code">
                {error.employee_Code}
              </span>
            </div>
          </div>

          {/* <div class="col-md-4">
            <div class="form-group  ">
              <label>
                Title<span className="text-danger">*</span>
              </label>
              <select
                class="form-control "
                id="HonorTitle"
                name="HonorTitle"
                value={HonorTitle}
                onChange={HonorTitleChange}
              >
                <option>Select Title</option>
                <option value="1">Mr</option>
                <option value="2">Ms</option>
              </select>

              <span className="text-danger" id="errTitle">
                {error.Title}
              </span>
            </div>
          </div> */}

          <div class="col-sm-4">
            <div class="form-group ">
              <label>
                First Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={100}
                placeholder="First Name"
                value={EmployeeModel.firstName}
                name="firstName"
                id="firstName"
                onChange={handleChange}
                onBlur={firstNameChange}
                autoComplete="off"
              />
              <span className="text-danger" id="errfirstName">
                {error.FirstName}
              </span>
            </div>
          </div>
          {/* <div class="col-sm-4">
            <div class="form-group ">
              <label>Middle Name</label>
              <input
                type="text"
                className="form-control"
                maxLength={100}
                placeholder="Middle Name"
                value={EmployeeModel.middleName}
                name="middleName"
                id="middleName"
                onChange={handleChange}
              />
              <span className="text-danger" id="errmiddleName">
                {error.MiddleName}
              </span>
            </div>
          </div> */}
          <div class="col-sm-4">
            <div class="form-group ">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                maxLength={100}
                onBlur={lastNameChange}
                placeholder="Last Name"
                value={EmployeeModel.lastName}
                name="lastName"
                id="lastName"
                onChange={handleChange}
                autoComplete="off"
              />
              {/* <span id="errlastName" className="text-danger">
                {error.LastName}
              </span> */}
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group ">
              <label>Date Of Birth</label>
              {/* <input
                type="Date"
                className="form-control"
                maxLength={100}
                placeholder="Date Of Birth"
                name="dateofBirth"
                id="dateofBirth"
                value={EmployeeModel.dateofBirthstr}
                onChange={handleChange}
                //onBlur={emailChange}
                autoComplete="off"
              /> */}

              <DatePicker
                className="form-control"
                maxLength={100}
                placeholder="Date Of Birth"
                name="dateofBirth"
                id="dateofBirth"
                value={EmployeeModel.dateofBirthstr}
                selected={dateOfBirth}
                showYearDropdown // Enable year dropdown
                showMonthDropdown // Enable month dropdown
                dropdownMode="select" // Use select dropdowns for year and month
                dateFormat="dd/MM/yyyy" // Specify your desired date format
                onChange={(date) => {
                  setdateOfBirth(date);
                }}
                placeholderText="Date Of Birth"
                maxDate={new Date()}
              />
              {/* <span className="text-danger" id="erremail">
                {error.Email}
              </span> */}
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label>
                Email <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                maxLength={100}
                placeholder="Email"
                name="email"
                id="email"
                value={EmployeeModel.email}
                onChange={handleChange}
                onBlur={emailChange}
                autoComplete="off"
              />
              <span className="text-danger" id="erremail">
                {error.Email}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label>
                Mobile Number <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                maxLength="10"
                placeholder="Mobile Number"
                value={EmployeeModel.mobile_Number}
                name="mobile_Number"
                id="mobile_Number"
                onKeyPress={numberOnly}
                onChange={handleChange}
                onBlur={mobile_NumberChange}
                autoComplete="off"
              />
              <span className="text-danger" id="errmobile_Number">
                {error.Mobile_Number}
              </span>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group ">
              <label>Alternate Mobile Number</label>
              <input
                type="text"
                className="form-control"
                maxLength="10"
                placeholder="Alternate Mobile Number"
                value={EmployeeModel.alternateMobileNumber}
                name="alternateMobileNumber"
                id="alternateMobileNumber"
                onKeyPress={numberOnly}
                onChange={handleChange}
                onBlur={mobile_NumberChange}
                autoComplete="off"
              />
              <span className="text-danger" id="erralternateMobileNumber">
                {error.AlternateMobileNumber}
              </span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label>Pincode</label>
              <input
                type="text"
                className="form-control"
                maxLength="10"
                placeholder="Pincode"
                value={EmployeeModel.pincode}
                name="pincode"
                id="pincode"
                onKeyPress={numberOnly}
                onChange={handleChange}
                autoComplete="off"
              />
              {/* <span className="text-danger" id="errmobile_Number">
                {error.Mobile_Number}
              </span> */}
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label>Addresss</label>
              <textarea
                type="text"
                className="form-control"
                maxLength={100}
                placeholder="Addresss"
                value={EmployeeModel.location}
                name="location"
                id="location"
              ></textarea>
              {/* <span id="errlocation" className="text-danger">
                {error.Location}
              </span> */}
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label>Status </label>
              {/* <Switch  onClick={toogler} />
               {toogle ? <span>Active</span> : <span>InActive</span>} */}
              <div class="form-check">
                <div class="row">
                  <div class="col-3">
                    <label class="form-radio-label ">
                      {EmployeeModel.isActive == 1 ? (
                        <input
                          class="form-radio-input"
                          type="radio"
                          name="isActive"
                          value={1}
                          onChange={handleChange}
                          checked="1"
                        />
                      ) : (
                        <input
                          class="form-radio-input"
                          type="radio"
                          name="isActive"
                          value={1}
                          onChange={handleChange}
                        />
                      )}
                      <span
                        style={{ marginLeft: "5px" }}
                        class="form-radio-sign"
                      >
                        Active
                      </span>
                    </label>
                  </div>
                  <div class="col-3">
                    <label class="form-radio-label ml-3">
                      {EmployeeModel.isActive == 0 ? (
                        <input
                          class="form-radio-input"
                          type="radio"
                          name="isActive"
                          value={0}
                          onChange={handleChange}
                          checked="0"
                        />
                      ) : (
                        <input
                          class="form-radio-input"
                          type="radio"
                          name="isActive"
                          value={0}
                          onChange={handleChange}
                        />
                      )}
                      <span
                        style={{ marginLeft: "5px" }}
                        class="form-radio-sign"
                      >
                        Inactive
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <span className="text-danger">{error.status}</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label>
                Role <span className="text-danger">*</span>
              </label>
              <MySelect
                isMulti
                options={RoleList}
                value={rolelistbyselect}
                name="roleSeletedList"
                id="roleSeletedList"
                placeholder="Select Role"
                onChange={ROleOnchange}
              />
              <span className="text-danger" id="errroleSeletedList">
                {error.Role}
              </span>
            </div>
          </div>
        </div>

        <div class="modal-footer no-bd">
          {EmployeeModel.employeeID == 0 ? (
            <button type="submit" id="addRowButton" class="btn btn-primary">
              Add
            </button>
          ) : (
            <button type="submit" id="addRowButton" class="btn btn-primary">
              Update
            </button>
          )}

          <button
            type="button"
            class="btn btn-danger"
            data-dismiss="modal"
            onClick={clearForm}
          >
            Close
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddOrEditEmployee;
