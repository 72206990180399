import React, { useState, useReducer, useEffect, useContext } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ApprovalService from "../../api/ApprovalService";
import $ from "jquery";
import Toast from "../Toastify";
import { AuthContext } from "../../Context/AuthContext";
import Axios from "axios";
import { saveAs } from "file-saver";
import ReimbursementService from "../../api/ReimbursementService";
import FundChatBox from "./FundChatBox";

const RemObj = new ReimbursementService();
const _ApprovalService = new ApprovalService();
export default function AccountantAction(props) {
  const { ID, Status, StageId, StudentId } = props;

  const { cookies, logout } = useContext(AuthContext);

  const [funTypeList, setFunTypeList] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [TicketCode, setTicket] = useState("");
  const [amount, setAmount] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [FundDetail, setFundDetails] = useState("");
  const [TicketId, setTicketId] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [filepath, Setfilepath] = useState("");
  const [fileerror, setfileerror] = useState("");
  const [downloadpath, setdownloadpath] = useState("");
  const [commet, setComment] = useState("");
  const [error, seterror] = useState("");
  const [FirstCmd, SetFirstCmd] = useState("");
  const [SecondCmd, SetSecondCmd] = useState("");
  const [ThirdCmd, SetThirdCmd] = useState("");

  const [StudentName, SetStudentName] = useState("");
  const [BankAccNumber, SetBankAccNumber] = useState("");
  const [BankAccType, SetBankAccType] = useState("");
  const [registerNo, setRegisterNo] = useState("");
  const [accomType, setAccomType] = useState("");
  const [course, setCourse] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [batchNo, setBatchNo] = useState("");
  const [BankBranch, SetBankBranch] = useState("");
  const [BankName, SetBankName] = useState("");
  const [PassBookUrl, SetPassBookUrl] = useState("");
  const [IFSCCode, SetIFSCCode] = useState("");

  const [error1, seterror1] = useState("");
  const [error2, seterror2] = useState("");

  const [Accommet, setAcComment] = useState("");
  const [tcamount, settcamount] = useState("");
  const [StudentRequestList, SetStudentRequestList] = useState([]);
  const [Image, SetImage] = useState("");

  const [accordion1Open, setAccordion1Open] = useState(false);
  const [accordion2Open, setAccordion2Open] = useState(false);
  const [accordion3Open, setAccordion3Open] = useState(true);
  const [stepList, SetStepList] = useState([]);
  const [Transidd, setTransidd] = useState("");
  const [employeeId, setemployeeId] = useState(cookies.userID);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [Commentlist, SetCommentlist] = useState([]);
  const [commentselectedFile, setSelectedcommentFile] = useState(null);
  const [Comment, SetComment] = useState("");

  const handleAccordion1Toggle = () => {
    setAccordion1Open(!accordion1Open);
  };

  const handleAccordion2Toggle = () => {
    setAccordion2Open(!accordion2Open);
  };

  const handleAccordion3Toggle = () => {
    setAccordion3Open(!accordion3Open);
  };
  useEffect(() => {
    RemObj.CommentBox(ID)
      .then((res) => {
        if (res != null && res.data != null && res.data.length > 0) {
          const result = res.data;
          SetCommentlist(result);
        }
        $(".loader-container").hide();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ID, Commentlist, Render]);
  useEffect(() => {
    var roleid = cookies.roleID;
    if (ID > 0) {
      _ApprovalService
        .Get_RequestById(ID, roleid, 0)
        .then((response) => {
          const data = response.data;

          console.log(data);

          if (
            data &&
            data.approvalRequests &&
            data.approvalRequests.length > 0
          ) {
            const firstTicket = data.approvalRequests[0];
            setTicket(firstTicket.ticketNumber);
            setSelectedTicket(firstTicket.type);
            setAmount(firstTicket.amount);
            setBillNumber(firstTicket.billNumber);
            setFundDetails(firstTicket.ticketDetails);
            Setfilepath(firstTicket.fileName);
            // setdownloadpath(firstTicket.downloadPath);
            setdownloadpath(
              Axios.defaults.baseURL + "/Uploadfiles/" + firstTicket.fileName
            );
            SetFirstCmd(firstTicket.firstLevelComment);
            SetSecondCmd(firstTicket.secondLevelComment);
            SetThirdCmd(firstTicket.thirdLevelComment);
            SetStudentName(firstTicket.studentName);
            SetBankAccNumber(firstTicket.bankAccNumber);
            setRegisterNo(firstTicket.registerNo);
            setAccomType(firstTicket.accommodationType);
            setCourse(firstTicket.course);
            setSpecialization(firstTicket.specialization);
            setCollegeName(firstTicket.collegeName);
            setBatchNo(firstTicket.batchNo);

            if (firstTicket.bankAccType == 1) {
              SetBankAccType("Saving");
            } else {
              SetBankAccType("Current");
            }

            // SetImage(data.Image);
            SetImage(
              Axios.defaults.baseURL + "/Uploadfiles/" + firstTicket.image
            );
            SetBankBranch(firstTicket.bankBranch);
            SetBankName(firstTicket.bankName);
            SetIFSCCode(firstTicket.ifscCode);
            SetPassBookUrl(firstTicket.passBookUrl);
            console.log("Fetched ticket data:", firstTicket);

            if (data && data.stepLevels && data.stepLevels.length > 0) {
              const step = data.stepLevels;
              SetStepList(step);
              console.log(step);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    if (StudentId > 0) {
      _ApprovalService
        .Student_CreditedInfo(StudentId)
        .then((res) => {
          if (res != null && res.data != null && res.data.length > 0) {
            const result = res.data;
            SetStudentRequestList(result);
          }
          $(".loader-container").hide();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ID]);

  const handlecommentFileChange = (event) => {
    debugger;
    const file = event.target.files[0];
    if (validateFileExtension(file)) {
      setSelectedcommentFile(file);
    } else {
      setSelectedcommentFile(filepath);
      setfileerror(
        "Invalid file format. Please select a valid file.It allows .jpg, .jpeg, .png, .pdf"
      );
    }
  };

  function SendComment(id) {
    debugger;
    const formData = {
      CommetID: 0,
      TicketID: ID,
      Commnets: Comment,
      SavedBy: employeeId,
      UploadPath: commentselectedFile,
    };

    var flag = true;

    if (formData.Commnets == null || formData.Commnets == "") {
      if (id == 1) {
        Toast("Please fill the Comment", "warning");
      }
      flag = false;
    }

    if (flag) {
      const Baseform = new FormData();

      Baseform.append("CommetID", formData.CommetID);
      Baseform.append("TicketID", formData.TicketID);
      Baseform.append("Commnets", formData.Commnets);
      Baseform.append("SavedBy", formData.SavedBy);
      Baseform.append("UploadPath", formData.UploadPath);
      SaveComment(Baseform);
    }
  }

  function SaveComment(Baseform) {
    RemObj.SaveCommentBox(Baseform)
      .then((response) => {
        $(".loader-container").hide();

        $("#Comment").val("");
        SetComment("");
        setSelectedcommentFile(null);

        setTimeout(() => {
          window.location.reload();
        }, 2000);

        PageRender();
      })
      .catch((error) => {
        $(".loader-container").hide();
        console.error("Error while saving:", error);
      });
  }

  function downloadCommentpath(url) {
    RemObj.Downloadcomment(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }
  const handleAmountChange = (event) => {
    const input = event.target.value;
    const numericInput = input.replace(/[^0-9.]/g, ""); // Allow digits and decimal point

    const parts = numericInput.split(".");
    const integerPart = parts[0];
    let decimalPart = parts[1];

    if (integerPart.length <= 10) {
      if (decimalPart !== undefined) {
        decimalPart = decimalPart.slice(0, 2);
      }

      const formattedIntegerPart = new Intl.NumberFormat("en-IN").format(
        integerPart
      );

      let formattedAmount = formattedIntegerPart;

      if (decimalPart !== undefined) {
        formattedAmount += "." + decimalPart;
      }

      settcamount(formattedAmount);
    } else {
      const trimmedIntegerPart = integerPart.slice(0, 10);
      const formattedIntegerPart = new Intl.NumberFormat("en-IN").format(
        trimmedIntegerPart
      );

      let formattedAmount = formattedIntegerPart;

      if (decimalPart !== undefined) {
        decimalPart = decimalPart.slice(0, 2); // Limit decimal part to 2 digits
        formattedAmount += "." + decimalPart;
      }

      settcamount(formattedAmount);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (validateFileExtension(file)) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      setfileerror(
        "Invalid file format. Please select a valid file.It allows .jpg, .jpeg, .png, .pdf"
      );
    }
  };

  const validateFileExtension = (file) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };

  const HandleSubmit = (e) => {
    e.preventDefault();

    debugger;
    const transferredID = Transidd;
    const transferredAmount = tcamount;
    console.log(Accommet);

    if (Status === 1) {
      if (!transferredID || transferredID == null) {
        seterror1("please enter the Transferred ID ");
        return;
      }

      if (!transferredAmount || transferredAmount == null) {
        seterror2("please enter the  Transferred Amount");
        return;
      }

      if (Accommet == null || Accommet == "") {
        seterror("Please enter your remarks");
        return;
      }

      const regex = /^[a-zA-Z0-9\s]+$/;

      if (!regex.test(transferredID)) {
        seterror1("Special characters are not allowed.");
        return;
      } else {
        seterror1("");
      }
    } else {
      if (Accommet == null || Accommet == "") {
        seterror("Please enter your remarks");
        return;
      }
    }

    const formData = new FormData();
    formData.append("IsApproved", Status);
    formData.append("TicketId", ID);
    formData.append("RoleName", cookies.roleName);
    // formData.append("ApproverStageId", StageId);
    formData.append("ApproverStageId", 0);
    formData.append("FirstLevelApprovedBy", cookies.userID);
    formData.append("FirstLevelComment", Accommet);
    formData.append("TransferId", transferredID);
    formData.append("TransferAmount", transferredAmount);
    formData.append("IsClosed", 1);
    formData.append("StudentId",StudentId);
    formData.append("StudentName",StudentName);
    formData.append("TicketNumber",TicketCode)
    formData.append("Status", Status === 0 ? 'Rejected' : 'Approved');

    // if (Status === 0) {
    //   formData.append("UploadPath", "");
    // } else {
    //   formData.append("UploadPath", e.target.UploadPath.files[0]);
    // }

    formData.append("RoleId", cookies.roleID);

    $(".loader-container").show();
    _ApprovalService
      .Accountant_Submit(formData)
      .then((response) => {
        //$(".loader-container").hide();
        SendComment(0);
        if (ID == 0 && Status == 1) {
          Toast("Request Approved Successfully", "success");
        } else if (Status == 0) {
          Toast("Request Rejectd Successfully", "success");
        } else {
          Toast("Request Approved Successfully", "success");
        }
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        $(".loader-container").hide();
        console.error("Error while submitting transfer details:", error);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };
  const handleInputChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");
    settcamount(numericValue);
    seterror2("");
  };

  const handleInputFocus = (e) => {
    const numericValue = e.target.value.replace(/,/g, "");
    settcamount(numericValue);
  };

  const handleInputBlur = () => {
    if (tcamount) {
      // Format the numeric value with commas when the input loses focus
      const formattedAmount = new Intl.NumberFormat("en-IN").format(
        parseFloat(tcamount)
      );
      settcamount(formattedAmount);
    } else {
      settcamount("");
    }
    seterror2("");
  };
  function download(url) {
    RemObj.Download(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }

  function DownloadPassBook(url) {
    RemObj.DownloadPassBook(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }
  const handleCommentChange = (e) => {
    setAcComment(e.target.value);
  };
  return (
    <div>
      <Accordion expanded={accordion1Open} onChange={handleAccordion1Toggle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <div className="row leftalign">
            Student Name :{" "}{StudentName?StudentName:"-"} &nbsp; | &nbsp; Ticket No :{" "}
            {TicketCode?TicketCode:"-"} &nbsp; | &nbsp; Amount :{" "}{amount?amount:"-"}&nbsp; | &nbsp;Register Number :{" "}{registerNo?registerNo:"-"}
            &nbsp; | &nbsp; Batch No :{" "}{batchNo?batchNo:"-"}
            </div>
            <div className="row leftalign">
             Course :{" "}{course?course:"-"}
            &nbsp; | &nbsp; Specialization :{" "}{specialization?specialization:"-"}
            &nbsp; | &nbsp; College Name :{" "}{collegeName?collegeName:"-"}
            &nbsp; | &nbsp; Accommodation Type  :{" "}{accomType?accomType:"-"}
            </div>
           
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">Request Number</label>
                <input
                  type="text"
                  name="RegNo"
                  class="form-control"
                  placeholder=""
                  value={TicketCode}
                  disabled={true}
                />
              </div>
            </div>

            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">Fund Type</label>
                <input
                  type="text"
                  name="RegNo"
                  class="form-control"
                  value={selectedTicket}
                  disabled={true}
                />
              </div>
            </div>

            <div class="col-lg-4 mb-2">
              <div class="mb-3">
                <label class="text-label form-label">Amount</label>
                <input
                  type="text"
                  name="Amount"
                  class="form-control"
                  value={amount}
                  disabled={true}
                />
              </div>
            </div>
            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">Bill Number</label>
                <input
                  type="text"
                  name="firstName"
                  class="form-control"
                  value={billNumber}
                  disabled={true}
                />
              </div>
            </div>
            <div class="col-lg-4 mb-3" style={{ marginTop: "-17px" }}>
              <div class="mb-3">
                <label class="text-label form-label">{selectedTicket=="Advance Request"?"View Reference / Proof" :"View Bill" }</label>
                <div>
                  <span className="file-info">
                    {/* <a href={downloadpath} download>
                      <CloudDownloadIcon className="download-icon" />
                      <div>{filepath}</div>
                    </a> */}
                    <a onClick={() => download(filepath)}>
                      <CloudDownloadIcon className="download-icon" />
                      {/* <div>{filepath}</div> */}
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-lg-4 mb-3" style={{ marginTop: "-17px" }}>
              <div class="mb-3">
                <label class="text-label form-label">
                  Fund Details<span class="text-danger">*</span>
                </label>
                <div>
                  <textarea
                    name="lastName"
                    class="form-control"
                    value={FundDetail}
                    disabled={true}
                  ></textarea>
                </div>
              </div>
            </div>

            {stepList.map((step, index) => (
              <div className="col-lg-4 mb-3" key={index}>
                <div className="mb-3">
                  <label className="text-label form-label">
                    {step.stepDescription}
                  </label>
                  <div>
                    <textarea
                      name={`remarks-${index}`}
                      className="form-control"
                      value={step.remarks}
                      disabled={true}
                    ></textarea>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{ marginTop: "1%" }}
        expanded={accordion2Open}
        onChange={handleAccordion2Toggle}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            IFSC :{" "} {IFSCCode?IFSCCode:"-"} &nbsp; | &nbsp; Account Number:{" "} {BankAccNumber?BankAccNumber:"-"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row ">
            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">
                  Bank Name<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="BankName"
                  class="form-control"
                  value={BankName}
                  required
                  disabled={true}
                />
              </div>
            </div>
            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">
                  Account Number<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="BankAccNumber"
                  class="form-control"
                  value={BankAccNumber}
                  required
                  disabled={true}
                  maxLength={25}
                />
              </div>
            </div>

            {/* <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">
                  Account Type<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="BankAccType"
                  class="form-control"
                  value={BankAccType}
                  required
                  disabled={true}
                />
              </div>
            </div> */}

            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">
                  Branch<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="BankBranch"
                  class="form-control"
                  value={BankBranch}
                  disabled={true}
                  required
                />
              </div>
            </div>

            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">
                  IFSC Code <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="IFSCCode"
                  class="form-control"
                 maxLength={11}
                  value={IFSCCode}
                  disabled={true}
                  required
                />
              </div>
            </div>

            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">Passbook</label>
                <br></br>
                <a
                  target="_blank"
                  onClick={() => DownloadPassBook(PassBookUrl)}
                  title="Download"
                >
                  <CloudDownloadIcon className="download-icon" />
                  {/* <div>{filepath}</div> */}
                </a>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <form onSubmit={HandleSubmit} style={{ marginTop: "1%" }}>
        <Accordion expanded={accordion3Open} onChange={handleAccordion3Toggle}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Transfer Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div class="row">
              <div class="col-lg-7">
                <div className="row">
                  {Status === 1 ? (
                    <>
                      <div class="col-lg-6 mb-2">
                        <div class="mb-4">
                          <label class="text-label form-label">
                            Transaction ID<span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="TransferredID"
                            class="form-control"
                            maxLength={20}
                            autoComplete="off"
                            value={Transidd}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              const regex = /^[a-zA-Z0-9\s]+$/; // Regular expression to allow only alphanumeric characters and spaces

                              if (!regex.test(inputValue)) {
                                seterror1(
                                  "Special characters are not allowed."
                                );
                              } else {
                                seterror1("");
                              }
                              setTransidd(inputValue);
                            }}
                          />
                        </div>
                        <div
                          class="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {error1}
                        </div>
                      </div>

                      <div class="col-lg-6 mb-2">
                        <div class="mb-4">
                          <label class="text-label form-label">
                            Transferred Amount<span class="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="Amount"
                            className="form-control"
                            placeholder="Amount"
                            value={tcamount}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus} // Handle onFocus event
                            onBlur={handleInputBlur} // Handle onBlur event
                            autoComplete="off"
                            maxLength={12}
                          />
                        </div>
                        <div
                          class="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {error2}
                        </div>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}

                  <div class="col-lg-6 mb-2">
                    {Status === 1 ? (
                      <div class="mb-3">
                        <label class="text-label form-label">
                          Approver Remarks <span class="text-danger">*</span>
                        </label>
                        <div>
                          <textarea
                            id="commmm"
                            name="firstLevelComment"
                            class="form-control"
                            placeholder="Enter your remarks"
                            value={Accommet}
                            onChange={handleCommentChange}
                          ></textarea>
                          <div
                            class="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {error}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div class="mb-3">
                          <label class="text-label form-label">
                            Rejected Remarks <span class="text-danger">*</span>
                          </label>
                          <div>
                            <textarea
                              id="commmm"
                              name="AccountantRemarks"
                              class="form-control"
                              placeholder="Enter your remarks"
                              value={Accommet}
                              onChange={(e) => {
                                setAcComment(e.target.value);
                              }}
                            ></textarea>
                          </div>
                        </div>
                        <div
                          class="invalid-feedback"
                          style={{ display: "block" }}
                        >
                          {error}
                        </div>
                      </>
                    )}
                  </div>
                  {Commentlist != null && StageId != 0 ? (
                    <>
                      {" "}
                      <div className="col-lg-6 col-md-9 col-sm-12">
                        <input type="hidden" name="ID" id="ID" />

                        <label>Comment</label>
                        <textarea
                          name="Comment"
                          class="form-control "
                          id="Comment"
                          onChange={(e) => SetComment(e.target.value)}
                        ></textarea>
                        <span style={{ color: "red" }} id="errValue"></span>
                      </div>
                      <div className="col-lg-6 col-md-9 col-sm-12">
                        <label>Reference File</label>
                        <input
                          type="file"
                          name="UploadPath"
                          className="form-control"
                          accept=".jpg, .jpeg, .png, .pdf"
                          onChange={(e) => {
                            handleFileChange(e); // Call the existing handler
                            //setformError({ ...error, UploadPath: "" }); // Clear the error message
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="col-12 modal-footer no-bd">
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                  <a
                    id="addRowButton"
                    class="btn btn-primary float-right"
                    onClick={() => SendComment(1)}
                    style={{ color: "#ffff" }}
                  >
                    Send Comment
                  </a>
                  <button
                    class="btn btn-primary float-right"
                    style={{ marginLeft: "10px" }}
                    // onClick={HandleSubmit}
                  >
                    {Status === 1
                      ? "Approve & Send Mail"
                      : "Reject & Send Mail"}
                  </button>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12">
                  {Commentlist != null && StageId != 0 ? (
                    <div class="col-md-12">
                      <p style={{ fontWeight: "bold" }}>Comments </p>
                      <div>
                        {Commentlist.map((item, index) =>
                          employeeId == item.SavedBy ? (
                            <>
                              <div class="comment1">
                                <div class="commentlist1">
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      color: "#3e9ae6",
                                    }}
                                  >
                                    <span>@</span>
                                    {item.first_Name} -{" "}
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "left",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {item.createdDate}
                                  </span>
                                  <span style={{ fontSize: "14px" }}>
                                    {" "}
                                    : {item.commnets}
                                  </span>
                                  {item.fileName != null ? (
                                    <span style={{ fontSize: "14px" }}>
                                      {" "}
                                      :{" "}
                                      <a
                                        onClick={() =>
                                          downloadCommentpath(item.fileName)
                                        }
                                      >
                                        <CloudDownloadIcon className="download-icon" />
                                        {/* <div>{filepath}</div> */}
                                      </a>
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <hr/>
                            </>
                          ) : (
                            <>
                              <div class="comment">
                                <div class="commentlist">
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "600",
                                      color: "#3e9ae6",
                                    }}
                                  >
                                    <span>@</span>
                                    {item.first_Name} -{" "}
                                  </span>
                                  <span
                                    style={{
                                      textAlign: "left",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {item.createdDate}
                                  </span>
                                  <span style={{ fontSize: "14px" }}>
                                    {" "}
                                    : {item.commnets}
                                  </span>
                                  {item.fileName != null ? (
                                    <span style={{ fontSize: "14px" }}>
                                      {" "}
                                      :{" "}
                                      <a
                                        onClick={() =>
                                          downloadCommentpath(item.fileName)
                                        }
                                      >
                                        <CloudDownloadIcon className="download-icon" />
                                        {/* <div>{filepath}</div> */}
                                      </a>
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                              <hr/>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div class="col-lg-5">
                {StudentRequestList != null && StudentRequestList.length > 0 ? (
                  <div className="trans">
                    {/* <p> <span style={{ fontWeight: "bold"}}>Allocated Amount :</span> {StudentRequestList[0].allocatedAmount}</p> */}
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Total Credited Amount :
                      </span>{" "}
                      {StudentRequestList[0].totalCreditedAmount}
                    </p>
                    {/* <p><span style={{ fontWeight: "bold"}}>Balance Amount :</span> {StudentRequestList[0].balanceAmount}</p> */}
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className={
                    StudentRequestList.length > 10 ? "trans-scroll" : "trans"
                  }
                >
                  <h3 style={{ fontWeight: "bold", color: "#1da837" }}>
                    {" "}
                    Transaction History
                  </h3>
                  <table
                    id="add-row"
                    className="display table table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <th> Date</th>
                        <th> Fund Type</th>
                        <th> Amount</th>
                        <th> View Bill</th>
                        <th> View Advance Bill</th>
                      </tr>
                    </thead>
                    <tbody>
                      {StudentRequestList.map((item, index) => (
                        <tr key={index}>
                          <td>{item.creditedDate}</td>
                          <td>{item.fundType}</td>
                          <td>{item.creditedAmount}</td>
                          <td>
                            {item.billUrl != null ? (
                              <a
                                target="_blank"
                                onClick={() => download(item.billUrl)}
                                title="Download"
                              >
                                <CloudDownloadIcon className="download-icon" />
                                {/* <div>{filepath}</div> */}
                              </a>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>
                            {item.uploadAdvanceBill != null ? (
                              <a
                                target="_blank"
                                onClick={() => download(item.uploadAdvanceBill)}
                                title="Download"
                              >
                                <CloudDownloadIcon className="download-icon" />
                                {/* <div>{filepath}</div> */}
                              </a>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </form>
    </div>
  );
}
