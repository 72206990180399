import React, { useState, useContext } from "react";
import $, { ajaxPrefilter } from "jquery";
import logo from "../../assets/Final-logo.png";
import { AuthContext } from "../../Context/AuthContext";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import AuthenticationService from "../../api/AuthenticationService";
import Toast from "../Toastify";
import Swal from "sweetalert";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const ChangePassword = () => {
  const { cookies, logout, data, setCookie } = useContext(AuthContext);

  const myOldEyeFunction = () => {
    var x = document.getElementById("OldPassword");
    if (x.type === "password") {
      x.type = "text";
      $("#pswOldicon").removeClass("fa-eye-slash");
      $("#pswOldicon").addClass("fa-eye");
      
    } else {
      $("#pswOldicon").removeClass("fa-eye");
      $("#pswOldicon").addClass("fa-eye-slash");
      x.type = "password";
    }
  };

  const myNewEyeFunction = () => {
    var x = document.getElementById("Password");
    if (x.type === "password") {
      x.type = "text";
      $("#pswNewicon").removeClass("fa-eye-slash");
      $("#pswNewicon").addClass("fa-eye");
    
    } else {
      $("#pswNewicon").removeClass("fa-eye");
      $("#pswNewicon").addClass("fa-eye-slash");
      x.type = "password";
    }
  };
  const myConformEyeFunction = () => {
    var x = document.getElementById("Confirmpassword");
    if (x.type === "password") {
      x.type = "text";
      $("#pswConicon").removeClass("fa-eye-slash");
      $("#pswConicon").addClass("fa-eye");
    } else {
     
      $("#pswConicon").removeClass("fa-eye");
      $("#pswConicon").addClass("fa-eye-slash");
      x.type = "password";
    }
  };
  const [disabled, setDisabled] = useState(false);

  const location = useLocation();
  const emid = location.state;
  const AuthObject = new AuthenticationService();

  const navigate = useNavigate();

  const [dummy, setDummy] = useState({
    prefixName: "",
    endValue: "",

    errNewPassword: "",
    errConfirmPassword: "",
  });
  const Resetsubmit = (e) => {
    e.preventDefault();
    console.log(e.target);
    var data = {
      // Username : emid,
      Username: cookies.userName,
      NewPassword: e.target.Password.value,
      ConfirmPassword: e.target.Confirmpassword.value,
      OldPassword: e.target.OldPassword.value,
    };

    if (data.OldPassword == null || data.OldPassword == "") {
      setDummy({ errOldPassword: "Enter new password" });

      return false;
    }
    if (data.OldPassword != cookies.KACHPPP) {
      setDummy({ errOldPassword: "Old password is not matched" });

      return false;
    }

    if (data.OldPassword != null || data.OldPassword != "") {
      setDummy({ errOldPassword: "" });
    }

    if (data.NewPassword == data.OldPassword) {
      setDummy({ errNewPassword: "New password and old password are same" });

      return false;
    }

    if (data.NewPassword == null || data.NewPassword == "") {
      setDummy({ errNewPassword: "Enter new password" });

      return false;
    }

    if (data.NewPassword != null || data.NewPassword != "") {
      setDummy({ errNewPassword: "" });
    }

    if (data.ConfirmPassword == null || data.ConfirmPassword == "") {
      setDummy({ errConfirmPassword: "Enter confirm password" });

      return false;
    }

    if (data.ConfirmPassword != null || data.ConfirmPassword != "") {
      setDummy({ errConfirmPassword: "" });
    }

    if (
      data.ConfirmPassword != data.NewPassword ||
      data.ConfirmPassword != data.NewPassword
    ) {
      setDummy({ errConfirmPassword: "Password is not matched" });
      return false;
    }

    if (
      data.ConfirmPassword == data.NewPassword ||
      data.ConfirmPassword == data.NewPassword
    ) {
      setDummy({ errConfirmPassword: "" });
    }

    valiedResetSubmit(data);
  };
  const valiedResetSubmit = (data) => {
    debugger;

    AuthObject.ChangePassword(data)
      .then((res) => {
        Toast("Password changed Successfully", "success");

        setTimeout(() => {
          logout();
          setCookie("ISReloadfunction", 1, { path: "/" });
          navigate("/");
        }, 2000);
      })
      .catch((res) => {
        // Handle error if needed
        setDisabled(false);
      });
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div class="wrapper sidebar_minimize login">
        <div class="container d-flex justify-content-center">
          <div class="d-flex flex-column justify-content-between">
            <form onSubmit={Resetsubmit}>
              <div class="card mt-3 p-5 Top_Card">
                <div>
                  <p
                    class="mb-1"
                    style={{
                      marginBottom: "-0.75rem !important",
                      fontFamily: "inherit",
                      fontSize: "30px",
                      textAlign: "center",
                    }}
                  >
                    Change Password
                  </p>
                  <h4 class="mb-5 text-white"></h4>
                </div>
              </div>
              <div class="card two bg-white px-5 py-4 mb-3">
                <div class="form-group">
                  <label
                    class="form-label"
                    id="Lblname"
                    style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                  >
                    Old Password
                  </label>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="password"
                        name="OldPassword"
                        id="OldPassword"
                        class="form-control"
                      />
                      <i
                        className="fa fa-eye-slash"
                        id="pswOldicon"
                        onClick={myOldEyeFunction}
                        style={{
                          float: "right",
                          marginRight: "10px",
                          marginTop: "-28px",
                          position: "relative",
                          zIndex: 2,
                        }}
                      ></i>
                    </div>
                    {/* <div className="col-md-2">
                      <button
                        className="btn pswhide eyeLogin"
                        type="button"
                        onClick={myOldEyeFunction}
                      >
                        <i className="fa fa-eye" id="pswOldicon"></i>
                      </button>
                    </div> */}
                  </div>

                  <span style={{ color: "red" }}>{dummy.errOldPassword}</span>
                </div>
                <div class="form-group">
                  <label
                    class="form-label"
                    id="Lblname"
                    style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                  >
                    New Password
                  </label>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="password"
                        name="Password"
                        id="Password"
                        class="form-control"
                      />
                      <i
                        className="fa fa-eye-slash"
                        id="pswNewicon"
                        onClick={myNewEyeFunction}
                        style={{
                          float: "right",
                          marginRight: "10px",
                          marginTop: "-28px",
                          position: "relative",
                          zIndex: 2,
                        }}
                      ></i>
                    </div>
                    {/* <div className="col-md-2">
                      <button
                        className="btn pswhide eyeLogin"
                        type="button"
                        onClick={myNewEyeFunction}
                      >
                        <i className="fa fa-eye" id="pswNewicon"></i>
                      </button>
                    </div> */}
                  </div>

                  <span style={{ color: "red" }}>{dummy.errNewPassword}</span>
                </div>
                <div class="form-group">
                  <label
                    class="form-label"
                    id="Lblname"
                    style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                  >
                    Confirm Password
                  </label>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="password"
                        name="Confirmpassword"
                        id="Confirmpassword"
                        class="form-control"
                      />
                      <i
                        className="fa fa-eye-slash"
                        id="pswConicon"
                        onClick={myConformEyeFunction}
                        style={{
                          float: "right",
                          marginRight: "10px",
                          marginTop: "-28px",
                          position: "relative",
                          zIndex: 2,
                        }}
                      ></i>
                    </div>
                    {/* <div className="col-md-2">
                      <button
                        className="btn pswhide eyeLogin"
                        type="button"
                        onClick={myConformEyeFunction}
                      >
                        <i className="fa fa-eye" id="pswConicon"></i>
                      </button>
                    </div> */}
                  </div>

                  <span style={{ color: "red" }}>
                    {dummy.errConfirmPassword}
                  </span>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button
                      disabled={disabled}
                      type="submit"
                      className={
                        disabled
                          ? "form-control btn btn-default rounded submit px-3"
                          : "form-control btn btn-primary rounded submit px-3"
                      }
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>

              <div></div>
            </form>
          </div>
          <a href="#" data-click="scroll-top" class="btn-scroll-top fade">
            <i class="fa fa-arrow-up"></i>
          </a>
        </div>
      </div>
    </>
  );
};
