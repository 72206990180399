import React from "react";
import AxiosServices from "./AxiosService";

const Auth = new AxiosServices();

class StudentService {
  Save_Student = async (data) => {
    debugger;
    return Auth.post(`/Student/Student_Save`, data);
  };

  ApproveOrReject = async (data) => {
    debugger;
    return Auth.post(`/Student/ApproveOrReject`, data);
  };

  RequestEdit = async (data) => {
    debugger;
    return Auth.post(`/Student/RequestEdit`, data);
  };
  Create = async (StudentID) => {
    return Auth.get(`/Student/Create?id=${StudentID}`);
  };
  Edit = async (StudentID) => {
    return Auth.get(`/Student/EditStudent?id=${StudentID}`);
  };
  GetSpecialisationList = async (course) => {
    return Auth.get(`/Student/GetSpecialisationList?course=${course}`);
  };
  GetstudentList = async () => {
    return Auth.get("/Student/GetStudentList");
  };

  Student_GetEditList = async () => {
    return Auth.get("/Student/GetEditRequestStudentList");
  };
  DeleteStudent = async (id) => {
    return Auth.get(`/Student/DeleteStudent?Id=${id}`);
  };

  BulkUpload = async (data) => {
    return Auth.post("/Student/BulkUpload", data);
  };

  Download = async (url) => {
    debugger;
    return Auth.post(`Student/Download?url=${url}`, url, {
      responseType: "blob",
    });
  };
  Result_Save = async (data) => {
    return Auth.post(`/Student/Save_Result`, data);
  };
  Get_ResultList = async (ID) => {
    return Auth.get(`/Student/Studet_ResultList?id=${ID}`);
  };
  Result_Download = async (url, Id) => {
    debugger;
    return Auth.post(
      `Student/Result_Download?fileName=${url}&studentId=${Id}`,
      url,
      {
        responseType: "blob",
      }
    );
  };
  UploadImage=async (formdata)=>{
        
    try {
         const response = await Auth
              .post(`Student/UploadStudentImage`, formdata);
         return response.data;
    } catch (err) {
         console.log(err);
    }
}
}

export default StudentService;
