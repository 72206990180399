import React, { useState, useReducer, useRef, useContext } from "react";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
// import Tooltip from "@material-ui/core/Tooltip";
// import AddIcon from "@mui/icons-material/Add";
// import Button from "@material-ui/core/Button";
// import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StudentApproval from "./StudentApproval";
import StudentService from "../../api/StudentService";
import { useEffect } from "react";
import swal from "sweetalert";
import Toast from "../Toastify";
import $, { data } from "jquery";
import axios from "axios";
//import XLSX from "xlsx";
import { AuthContext } from "../../Context/AuthContext";
// import { LaptopWindows } from "@material-ui/icons";
// import DemandTemplate from "../../assets/Students_Master_Data.xlsx";
// import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const StudentServiceObj = new StudentService();

function StudentProfileApprovelist() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [StudentID, SetStudentID] = useState(0);
  const [StudentModel, SetStudentModel] = useState([]);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const { cookies, logout, removeCookie, setCookie } = useContext(AuthContext);

  const fileInputRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(0);
  const handleFileUpload = () => {
    // Trigger a click event on the hidden file input
    fileInputRef.current.click();
  };

  const handleFileSelected = (event) => {
    debugger;
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
      // Handle the selected Excel file here
      console.log("Selected Excel file:", selectedFile);
    } else {
      // Display an error message or handle the invalid file type
      console.error("Invalid file type. Please select an Excel file (.xlsx)");
    }
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = (e) => {
      var filestr = e.target.result.split(",");
      SavaFile(filestr[1]);
    };
  };

  const SavaFile = (str) => {
    debugger;
    const obj = {
      str: str,
      EmployeeID: Number(cookies.userID),
    };
    $(".loader-container").show();
    StudentServiceObj.BulkUpload(obj)
      .then((response) => {
        debugger;

        console.log(response.data.errors);

        if (response.data.totalCount > 0) {
          Toast(
            `${response.data.totalCount} Student(s) Uploaded successfully`,
            "success"
          );
          // PageRender();
          $(".close").click();
          $(".loader-container").hide();
          Window.location.reload();
        } else {
          $(".loader-container").hide();
          //Toast("Student Upload Failed", "error");
          Window.location.reload();
        }

        // Check if there are errors in the response
        if (response.data.errors && response.data.errors.length > 0) {
          response.data.errors.forEach((errorMessage) => {
            console.error(errorMessage);
            // Display the error message using your Toast function
            Toast(errorMessage, "error");
            Window.location.reload();
          });
        }
      })
      .catch((error) => {
        console.error(error);
        $(".loader-container").hide();
        Toast("An error occurred while uploading students", "error");
        refreshPage();
      });
  };

  function HandleClick(id) {
    SetStudentID(id);

    //PageRender();
  }
  useEffect(() => {
    $(".submenu").removeClass("active");
    $("#ProfileApproval").addClass("active");

    $(".loader-container").show();
    //SetStudentID(0);
    window.scrollTo(0, 0);
    StudentServiceObj.Student_GetEditList()
      .then((res) => {
        var data = res.data;

        if (data != null) {
          SetStudentModel(data);
          
        }
        $(".loader-container").hide();
      })
      .catch((error) => {
        console.log(error);
        $(".loader-container").hide();
      });
  }, [GridRender]);

  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
  }
  function DeleteStudent(id) {
    swal({
      title: "Are You Sure?",
      text: "Do you want to delete this request?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        StudentServiceObj.DeleteStudent(id)
          .then((res) => {
            Toast("Student deleted succesfully", "success");

            setGridRender();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const savedPage = sessionStorage.getItem("currentPage");
    if (savedPage !== null) {
      setCurrentPage(parseInt(savedPage, 10));
    }

  }, []);

  useEffect(() => {
    sessionStorage.setItem("currentPage", currentPage);
  }, [currentPage]);
  const columns = [
    {
      name: "studentId",
      label: "studentId",
      options: {
        filter: true,
        sort: true,
        display: "none",
      },
    },
    {
      name: "userName",
      label: "User Name",
    },
    {
      name: "registerNo",
      label: "Register No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "studentName",
      label: "Student Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "joiningDate",
      label: "Batch No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bankName",
      label: "Bank Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "holderName",
      label: "Account Holder Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bankBranch",
      label: "Bank Branch",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ifscCode",
      label: "IFSC Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "bankAccNumber",
      label: "Bank Account Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <span style={{ color: value === "Active" ? "green" : "red" }}>
            {value}
          </span>
        ),
      },
    },

    {
      name: "OPTION",
      label: "Option",
      options: {
        filter: true,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          const value = tableMeta.rowData[0];
          return (
            <div className="center">
              <IconButton
                title="Edit"
                data-bs-toggle="modal"
                data-bs-target="#updateStudent"
                onClick={() => HandleClick(value)}
              >
                <EditIcon />
              </IconButton>
              {/* <IconButton title="Delete" onClick={() => DeleteStudent(value)}>
                <DeleteIcon />
              </IconButton> */}
            </div>
          );
        },
      },
    },
  ];
  //   const HeaderElements = () => (
  //     <>
  //       <Tooltip title="Create Student">
  //         <label htmlFor="icon-button-file">
  //           <IconButton data-bs-toggle="modal" data-bs-target="#addStudent">
  //             <AddIcon />
  //           </IconButton>
  //         </label>
  //       </Tooltip>

  //       <button className="btn btn-primary" onClick={handleFileUpload}>
  //          <CloudUploadIcon className="download-icon" style={{ marginRight: "6px" }} />

  //         Upload File
  //       </button>
  //       <input
  //         type="file"
  //         ref={fileInputRef}
  //         style={{ display: "none" }}
  //         onChange={handleFileSelected}
  //         accept=".xlsx"
  //       />

  //       <a
  //         className="btn btn-primary"
  //         href={DemandTemplate}
  //         download
  //         style={{ marginLeft: "1px" }}
  //       >
  //          <CloudDownloadIcon className="download-icon" />
  //         <span className="ml-1"> Student Template</span>
  //       </a>
  //     </>
  //   );

  return (
    <>
      <div class="main-panel">
        <div class="content">
          <div className="page-inner">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <MUIDataTable
                        data={StudentModel}
                        title="Profile Edit Request"
                        columns={columns}
                        options={{
                          responsive: true,
                          download: true,
                          print: true,
                          selectableRows: false,
                          filter: true,
                          viewColumns: true,
                          //   customToolbar: () => (
                          //     <>
                          //       <HeaderElements />
                          //     </>
                          //   ),
                        page: currentPage,
                        onChangePage: (currentPage) => handlePageChange(currentPage),
                        pagination: true,
                        rowsPerPageOptions: [10, 15, 100, { value: StudentModel.length, label: 'ALL' }],
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div
          class="modal fade"
          id="updateStudent"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title">
                  <span class="fw-mediumbold">Profile Edit Request</span>
                </h3>
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={refreshPage}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <StudentApproval Id={StudentID} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default StudentProfileApprovelist;
