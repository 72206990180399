import React, { useState, useEffect, useContext, useReducer } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReimbursementService from "../../api/ReimbursementService";
import Toast from "../Toastify";
import { AuthContext } from "../../Context/AuthContext";
import $ from "jquery";
import "bootstrap/js/dist/modal";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ApprovalService from "../../api/ApprovalService";
import Axios from "axios";
import { saveAs } from "file-saver";

const RemObj = new ReimbursementService();
const _ApprovalService = new ApprovalService();

const FundView = (props) => {
  const { ID, StudentId } = props;
  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [funTypeList, setFunTypeList] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [TicketCode, setTicket] = useState("");
  const [amount, setAmount] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [billDate, setBillDate] = useState("");
  const [FundDetail, setFundDetails] = useState("");
  const [TicketId, setTicketId] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [filepath, Setfilepath] = useState("");
  const [fileerror, setfileerror] = useState("");
  const [downloadpath, setdownloadpath] = useState("");
  const [StudentRequestList, SetStudentRequestList] = useState([]);
  const isSubmit = 0;
  const [TicketById, setTicketById] = useState([]);
  const [funtyppp, setfuntyppp] = useState("");
  var today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

      function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = date.getFullYear();
      
        return `${day}-${month}-${year}`;
      }

  useEffect(() => {
    var roleid = cookies.roleID;
    if (ID > 0) {
      RemObj.Get_RequestById(ID, roleid, 1)
        .then((response) => {
          const data = response.data;

          if (data && data.ticket && data.ticket.length > 0) {
            const firstTicket = data.ticket[0];
            setTicket(firstTicket.ticketNumber);
            setSelectedTicket({
              value: firstTicket.fundType,
              label: firstTicket.type,
            });
            setfuntyppp(firstTicket.type);
            setAmount(firstTicket.amount);
            setBillNumber(firstTicket.billNumber);
            if(firstTicket.billDate){
              setBillDate(formatDate(firstTicket.billDate));
            }else{
              setBillDate("");
            }
            
            setFundDetails(firstTicket.ticketDetails);
            Setfilepath(firstTicket.fileName);
            setdownloadpath(firstTicket.downloadPath);

            // setdownloadpath(
            //   Axios.defaults.baseURL + "/Uploadfiles/" + firstTicket.fileName
            // );
            console.log("Fetched ticket data:", firstTicket);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    if (StudentId != null) {
      debugger
      _ApprovalService
        .Student_CreditedInfo(StudentId)
        .then((res) => {
          if (res != null && res.data != null && res.data.length > 0) {
            const result = res.data;
            SetStudentRequestList(result);
          }
          $(".loader-container").hide();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ID]);

  useEffect(() => {
    if (ID == 0) {
      RemObj.Get_RequestDetails()
        .then((response) => {
          const data = response.data;

          if (data && data.funType && data.funType.length > 0) {
            setFunTypeList(data.funType);
          }

          if (data && data.ticketCode) {
            setTicket(data.ticketCode);
          }
          console.log(funTypeList);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  const handleAmountChange = (event) => {
    const input = event.target.value;
    const numericInput = input.replace(/[^0-9.]/g, ""); // Allow digits and decimal point

    const parts = numericInput.split(".");
    const integerPart = parts[0];
    let decimalPart = parts[1];

    if (integerPart.length <= 10) {
      if (decimalPart !== undefined) {
        decimalPart = decimalPart.slice(0, 2);
      }

      const formattedIntegerPart = new Intl.NumberFormat("en-IN").format(
        integerPart
      );

      let formattedAmount = formattedIntegerPart;

      if (decimalPart !== undefined) {
        formattedAmount += "." + decimalPart;
      }

      setAmount(formattedAmount);
    } else {
      const trimmedIntegerPart = integerPart.slice(0, 10);
      const formattedIntegerPart = new Intl.NumberFormat("en-IN").format(
        trimmedIntegerPart
      );

      let formattedAmount = formattedIntegerPart;

      if (decimalPart !== undefined) {
        decimalPart = decimalPart.slice(0, 2); // Limit decimal part to 2 digits
        formattedAmount += "." + decimalPart;
      }

      setAmount(formattedAmount);
    }
  };

  const handleBillNumberChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^a-zA-Z0-9/-]/g, ""); // Allow letters, numbers, /, and -
    setBillNumber(sanitizedInput);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (validateFileExtension(file)) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      setfileerror(
        "Invalid file format. Please select a valid file.It allows .jpg, .jpeg, .png, .pdf"
      );
    }
  };

  const validateFileExtension = (file) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };

  const [errors, setErrors] = useState({
    FundType: "",
    Amount: "",
    billNumber: "",
    UploadPath: "",
    FundDetails: "",
  });

  const validateForm = () => {
    let newErrors = { ...errors };
    newErrors.FundType = selectedTicket ? "" : "Fund Type is required.";
    newErrors.Amount = amount ? "" : "Amount is required.";
    newErrors.billNumber = billNumber ? "" : "Bill Number is required.";
    newErrors.FundDetails = FundDetail ? "" : "Fund Details is required.";
    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };

  const HandleSubmit = (e, skipValidation = false) => {
    e.preventDefault();
    if (skipValidation) {
      const formData = new FormData();

      formData.append("TicketId", ID);
      formData.append("TicketNumber", TicketCode);

      if (selectedTicket) {
        formData.append("FundType", selectedTicket.value);
      } else {
        formData.append("FundType", 0);
      }
      formData.append("Amount", amount);
      formData.append("BillNumber", billNumber);

      if (selectedFile !== null) {
        formData.append("UploadPath", selectedFile);
      } else {
        formData.append("FileName", filepath);
      }

      const ticketDetails = FundDetail;
      if (ticketDetails) {
        formData.append("TicketDetails", ticketDetails);
      }

      formData.append("CreatedBy", cookies.userID);
      formData.append("CreatedDate", date);
      formData.append("IsSubmit", 0);

      Submit_Request(formData);
    } else if (validateForm()) {
      const formData = new FormData();

      formData.append("TicketId", ID);
      formData.append("TicketNumber", e.target.TicketNumber.value);
      formData.append("FundType", selectedTicket.value);
      formData.append("Amount", e.target.Amount.value);
      formData.append("BillNumber", e.target.billNumber.value);
      if (filepath == null) {
        formData.append("UploadPath", e.target.UploadPath.files[0]);
      } else {
        formData.append("FileName", filepath);
      }
      formData.append("TicketDetails", e.target.FundDetails.value);
      formData.append("CreatedBy", cookies.userID);
      formData.append("CreatedDate", date);
      formData.append("IsSubmit", 1);

      Submit_Request(formData);
    }
  };

  const Submit_Request = (formData) => {
    $(".loader-container").show();
    RemObj.SubmitRequest(formData)
      .then((response) => {
        console.log(response);
        $(".loader-container").hide();

        if (ID == 0) {
          Toast("Request Created Successfully", "success");
        } else {
          Toast("Request Updated Successfully", "success");
        }
        setTimeout(() => {
          $(".close").trigger("click");
        }, 2000);
      })
      .catch((error) => {
        console.error("Error while saving:", error);
      });
  };

  function refreshPage() {
    window.location.reload(false);
  }
  function download(url) {
    RemObj.Download(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }

  return (
    <form
      id="PersonalDetailsCreate"
      onKeyPress={(e) => {
        if (e.key == "Enter") {
          e.preventDefault();
        }
      }}
      onSubmit={HandleSubmit}
    >
      <div class="row">
        <div class="col-lg-7">
          <div className="row">
            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">Request Number</label>
                <input
                  type="text"
                  name="TicketNumber"
                  class="form-control"
                  placeholder=""
                  value={TicketCode}
                  disabled={true}
                />
              </div>
            </div>

            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Fund Type <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="Amount"
                  className="form-control"
                  placeholder="Amount"
                  value={funtyppp}
                  onChange={(e) => {
                    handleAmountChange(e); // Call the existing handler
                    setErrors({ ...errors, Amount: "" }); // Clear the error message
                  }}
                  disabled={true}
                />

                <div class="invalid-feedback" style={{ display: "block" }}>
                  {errors.FundType}
                </div>
              </div>
            </div>

            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Amount <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="Amount"
                  className="form-control"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => {
                    handleAmountChange(e); // Call the existing handler
                    setErrors({ ...errors, Amount: "" }); // Clear the error message
                  }}
                  disabled={true}
                />
                <div class="invalid-feedback" style={{ display: "block" }}>
                  {errors.Amount}
                </div>
              </div>
            </div>
            {funtyppp === 'Reimbursement Request' &&<>
            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Bill Number <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="billNumber"
                  className="form-control"
                  placeholder="Bill Number"
                  value={billNumber}
                  onChange={(e) => {
                    handleBillNumberChange(e); // Call the existing handler
                    setErrors({ ...errors, billNumber: "" }); // Clear the error message
                  }}
                  disabled={true}
                  maxLength={20}
                />
                <div class="invalid-feedback" style={{ display: "block" }}>
                  {errors.billNumber}
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Bill Date <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="billDate"
                  className="form-control"
                  value={billDate}
                  disabled={true}
                  maxLength={20}
                />
              </div>
            </div>
            </>}

            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  {ID > 0 && filepath !== null
                    ? (funtyppp === 'Reimbursement Request'
                      ? "View Bill"
                      : funtyppp === 'Advance Request'
                        ? "View Proof"
                        : "Upload Proof")
                    : "Upload Bill"}
                </label>


                <div>
                  {ID > 0 && filepath !== null ? (
                    <>
                      {/* <a onClick="download(filepath)" download> */}
                      <a onClick={() => download(filepath)} title="Download">
                        <CloudDownloadIcon className="download-icon" />
                        {/* <div>{filepath}</div> */}
                      </a>
                    </>
                  ) : (
                    <>
                      <h4>No file uploaded</h4>
                    </>
                  )}

                  <div class="invalid-feedback" style={{ display: "block" }}>
                    {fileerror}
                  </div>
                  <div class="invalid-feedback" style={{ display: "block" }}>
                    {errors.UploadPath}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Fund Details<span class="text-danger">*</span>
                </label>
                <div>
                  <textarea
                    name="FundDetails"
                    class="form-control"
                    value={FundDetail}
                    placeholder=""
                    onChange={(e) => {
                      setFundDetails(e.target.value);
                      setErrors({ ...errors, FundDetails: "" }); // Clear the error message
                    }}
                    disabled={true}
                  ></textarea>
                  <div class="invalid-feedback" style={{ display: "block" }}>
                    {errors.FundDetails}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5">
          {StudentRequestList != null && StudentRequestList.length > 0 ? (
            <div className="trans">
              {/* <p> <span style={{ fontWeight: "bold"}}>Allocated Amount :</span> {StudentRequestList[0].allocatedAmount}</p> */}
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Total Credited Amount :
                </span>{" "}
                {StudentRequestList[0].totalCreditedAmount}
              </p>
              {/* <p><span style={{ fontWeight: "bold"}}>Balance Amount :</span> {StudentRequestList[0].balanceAmount}</p> */}
            </div>
          ) : (
            <></>
          )}
          <div
            className={
              StudentRequestList.length > 10 ? "trans-scroll" : "trans"
            }
          >
            <h3 style={{ fontWeight: "bold", color: "#1da837" }}>
              {" "}
              Transaction History
            </h3>
            <table
              id="add-row"
              className="display table table-striped table-hover"
            >
              <thead>
                <tr>
                  <th> Date</th>
                  <th> Fund Type</th>
                  <th> Amount</th>
                  <th> View Bill</th>
                  <th> View Advance Bill</th>
                </tr>
              </thead>
              <tbody>
                {StudentRequestList.map((item, index) => (
                  <tr key={index}>
                    <td>{item.creditedDate}</td>
                    <td>{item.fundType}</td>
                    <td>{item.creditedAmount}</td>
                    <td>
                      {item.billUrl != null ? (
                        <a
                          target="_blank"
                          onClick={() => download(item.billUrl)}
                          title="Download"
                        >
                          <CloudDownloadIcon className="download-icon" />
                          {/* <div>{filepath}</div> */}
                        </a>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      {item.uploadAdvanceBill != null ? (
                        <a
                          target="_blank"
                          onClick={() => download(item.uploadAdvanceBill)}
                          title="Download"
                        >
                          <CloudDownloadIcon className="download-icon" />
                          {/* <div>{filepath}</div> */}
                        </a>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  );
};
export default FundView;
