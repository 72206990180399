import React, { useState, useContext, useEffect } from "react";
import { Outlet, Navigate } from 'react-router';
import Navbar from './Navbar'
import { AuthContext } from "../../Context/AuthContext";
import IdleTimeOutHandler from "../../IdleTimeOutHandler";
import AxiosServices from '../../api/AxiosService'
const URLHR = new AxiosServices();

// import WithoutNav from './WithoutNav';

function WithNav() {
  const [isActive, setIsActive] = useState(true)
  const [isLogout, setLogout] = useState(false)
  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);

  useEffect(() => {


    if (cookies.FirstroleCountLogin == 1) {


      // removeCookie("FirstroleCountLogin");
      setCookie("FirstroleCountLogin", 0, { path: "/" });
      setTimeout(() => {

        LoadCTHrms();
        // setCookie("FirstroleCountLogin", 0, { path: "/" });
      }, 0);


      //   LoadCTHrms();
    }


  }, []);


  function LoadCTHrms(e) {
       

    setCookie("FirstroleCountLogin", 0, { path: "/" });
    var userNameObj = cookies.KuuuDecript;
    var PasswordObj = cookies.KpppDecript;
    var ishrmsObj = 1;
    var url = process.env.CT_HRMS_APP;
    var url = URLHR.geturl();
    var HRMSURL = url + "Login/HRRecumentIndex?UserName=" + userNameObj + "&Password=" + PasswordObj + "&ishrms=" + ishrmsObj;

    window.location.href = HRMSURL;
    // window.reload(HRMSURL);
  }

  return (
    <>
      {/* <h1>hjhh</h1> */}
      <div className="wrapper">
        <IdleTimeOutHandler
          onActive={() => { setIsActive(true) }}
          onIdle={() => { setIsActive(false) }}
          onLogout={() => { setLogout(true) }}
        />
        <Navbar />
        <Outlet />
      </div>



    </>
  )
}
export default WithNav;
