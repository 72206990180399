import React, { useState, useContext, useEffect, useReducer } from "react";
import $ from "jquery";
import { AuthContext } from "../../Context/AuthContext";

import AssociateService from "../../api/AssociateService";
// import "datatables.net";
// import 'datatables.net'
// import { Modal, Button } from "react-bootstrap";
import AddOrEditEmployee from "./AddOrEditEmployee";
import swal from "sweetalert";
import "datatables.net";
import Toast from "../Toastify";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const AssociateServiceObj = new AssociateService();

const MainGrid = (props) => {
  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);
  const [EmpID, SetEmpID] = useState(0);
  const [EmployeeDatailes, SetEmployeeDatailes] = useState([]);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [ModelName, setModelName] = useState("");
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);

  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);
  function HandleClick(id) {
    SetEmpID(id);
    if (id == 0) {
      setModelName("Employee Create");
    } else {
      setModelName("Employee Edit");
    }
    //PageRender();
  }

  function DeleteEmployee(id) {
    swal({
      title: "Are You Sure?",
      text: "Do you want to delete this employee?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        AssociateServiceObj.DeleteEmployee(id)
          .then((res) => {
            Toast("Employee deleted succesfully", "success");

            // toast.success('Employee Deleted Succesfully !', {
            // position: "top-right",
            // autoClose: 5000,
            // hideProgressBar: true,
            // closeOnClick: true,
            // pauseOnHover: true,
            // draggable: true,
            // progress: undefined,
            // theme: "colored",

            // });
            setGridRender();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  const [users, setUsers] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    // $("#AssociateDisplay").dataTable();
    $(".submenu").removeClass("active");
    $("#ConfigurationMenu").addClass("active");

    $(".collapse").removeClass("show");
    $("#Configuration").addClass("show");

    $(".submenu li").removeClass("active");
    $("#Employee").addClass("active");

    AssociateServiceObj.getData()
      .then((res) => {
        if (res != null && res.data != null && res.data.length > 0) {
          SetEmployeeDatailes([...res.data]);
          $("#AssociateDisplay").DataTable().destroy();

          console.log(...res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // setTimeout(() => {
    //   $('#AssociateDisplay').DataTable().destroy();
    //   setUsers([]);
    // },2000);
  }, [GridRender, Render]);

  useEffect(() => {
    $("#AssociateDisplay").DataTable({
      ordering: false,
    });
  }, [EmployeeDatailes]);

  function refreshPage() {
    window.location.reload(false);
  }

  const columns = [
    {
      name: "employeeID",
      label: "employeeID",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "employee_Code",
      label: "Employee Code",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "employee_Name",
      label: "Employee Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "roleNames",
      label: "Role",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "isActive",
      label: "Status",
      options: {
        filter: true,
        sort: false,
        empty: true,

        customBodyRender: (values, tableMeta) => {
          const value = tableMeta.rowData[5];

          var myval = value == 1 ? "Active" : "In Active";
          if (value == 1) {
            return <label class="role-info role-bg-two">Active</label>;
          } else {
            return <label class="role-info role-bg-one">In Active</label>;
          }
        },
      },
    },
    {
      label: "Option",
      name: "id",
      options: {
        filter: true,
        sort: false,
        empty: true,

        customBodyRender: (values, tableMeta) => {
          const value = tableMeta.rowData[0];
          return (
            <div className=" ico-sec" style={{ display: "flex" }}>
              <Tooltip title="Edit Employee">
                <IconButton
                  aria-label="Edit"
                  data-bs-toggle="modal"
                  data-bs-target="#addRowModal1"
                  onClick={() => HandleClick(value)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Employee">
                <IconButton
                  aria-label="Edit"
                  onClick={() => DeleteEmployee(value)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];
  const HeaderElements = () => (
    <>
      <Tooltip title="Add Employee">
        <label htmlFor="icon-button-file">
          <IconButton
            variant="primary"
            data-bs-toggle="modal"
            data-bs-target="#addRowModal1"
          >
            <AddIcon> </AddIcon>
          </IconButton>
        </label>
      </Tooltip>
    </>
  );

  const clearForm = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div class="main-panel">
        <div class="content">
          <div className="page-inner">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <MUIDataTable
                        data={EmployeeDatailes}
                        title="Employee"
                        columns={columns}
                        options={{
                          responsive: true,
                          download: true,
                          print: true,
                          selectableRows: false,
                          filter: true,
                          viewColumns: true,
                          customToolbar: () => (
                            <>
                              <HeaderElements />
                            </>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div
          class="modal fade"
          id="addRowModal1"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title">
                  {EmpID == 0 ? (
                    <span class="fw-mediumbold">Add Employee</span>
                  ) : (
                    <span class="fw-mediumbold">Edit Employee</span>
                  )}
                </h3>
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={clearForm}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <AddOrEditEmployee employeeID={EmpID} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MainGrid;
