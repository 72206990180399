import React, { useState, useEffect, useRef, useContext, useReducer } from "react";
import MUIDataTable from "mui-datatables";
import TablePagination from "@mui/material/TablePagination"
import IconButton from "@mui/material/IconButton";
import ActionPage from "./ActionPage";
import ApproveIcon from "@mui/icons-material/CheckCircle";
import RejectIcon from "@mui/icons-material/Cancel";
import ApprovalService from "../../api/ApprovalService";
import { AuthContext } from "../../Context/AuthContext";
import $ from "jquery";
import AccountantAction from "./AccountantAction";
import ActionPageView from "./ActionPageView";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AccountantActionView from "./AccountantActionView";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Toast from "../Toastify";
import swal from "sweetalert2";


const _ApprovalService = new ApprovalService();

function ApprovalGrid(props) {
  const { cookies, logout } = useContext(AuthContext);
  const [show, setShow] = useState(false);
  const [actionPageOpen, setActionPageOpen] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [RequestList, SetRequestList] = useState([]);
  const AcchandleShow = () => setShow(true);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [RoleName, SetRoleName] = useState("");
  const [RequestId, SetRequestId] = useState(0);
  const [AppRejStatus, SetAppRejStatus] = useState(0);
  const [ApproverStageId, SetApproverStageId] = useState(0);
  const [studentId, setStudentId] = useState(0);
  const [Roles, SetRoles] = useState(cookies.roleName);
  const [stepList, SetStepList] = useState([]);
  const [StatusName, SetStatusName] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [isFundHistory, setIsFundHistory] = useState(true);


  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reset page number when rows per page changes
  };

  const handleMaainRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event, 10));
    setCurrentPage(0); // Reset page number when rows per page changes
  };


  
  useEffect(() => {
    $(".loader-container").show();
    $(".submenu").removeClass("active");
    $("#FundApproval").addClass("active");
    fundRequestList();
   
  }, [GridRender, Render,isFundHistory]);
  
  
  function fundRequestList() {
    $(".loader-container").show();
    const { userID: Id, roleName: role, roleID: RoleId } = cookies;
    SetRoleName(role);

    const roleFilteringMap = {
      "Project Coordinator": ["Requested"], 
      "Senior Manager - Projects": ["Project Coordinator Approved"], 
      "Senior Manager": ["Project Coordinator Approved"], 
      "Head - Projects": ["Project Coordinator Approved"],
      "Head Projects": ["Project Coordinator Approved"],
      "Accountant": [
        "SAVP CE Approved",
        "Senior Manager Approved",
        "Head - Projects Approved",
        "Head Projects Approved"
      ],
      "Head Finance": ["Team Accounts Approved"],
      "SAVP CE": [
        "Head Finance Approved",
        "Head  Finance Approved"
      ],
    };

    _ApprovalService
      .ApproverRequest_List(Id, role, RoleId)
      .then((response) => {
        const data = response.data;
        if (data?.approvalRequests?.length > 0) {
          const allowedStatuses = roleFilteringMap[role];
          
          let filteredRequests;
          if (Array.isArray(allowedStatuses)) {
            // Toggle filtering based on isFundHistory state
            filteredRequests = data.approvalRequests.filter(request => 
              isFundHistory ? allowedStatuses.includes(request.status) : !allowedStatuses.includes(request.status)
            );
          } else {
            filteredRequests = data.approvalRequests;
          }

          SetRequestList(filteredRequests); 
        }

        if (data?.stepLevels?.length > 0) {
          SetStepList(data.stepLevels);
        }
        $(".loader-container").hide();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const toggleFundHistory = () => {
    setIsFundHistory(!isFundHistory);
  };

  function downloadtemplate() {
    var Id = cookies.userID;
    var role = cookies.roleName;
    var RoleId = cookies.roleID;
    _ApprovalService
      .Accountant_Template(Id, role, RoleId)
      .then((response) => {

        if (response.data != 0) {
          const link = document.createElement('a');
          link.href = response.request.responseURL;
          link.setAttribute('download', new Date().getDate() + "_AccountantTemplate.xlsx");
          document.body.appendChild(link);
          link.click();
          link.remove();
        } else {

          Toast("Request not available for closing stage", "warning");
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }

  function HandleClick(id, val, newval, studentId, status) {

    debugger;

    SetStatusName(status);
    SetRequestId(id);
    SetAppRejStatus(val);
    if (newval == null) {
      SetApproverStageId(0);
    }
    SetApproverStageId(newval);
    setStudentId(studentId);
  }

  function AccountantHandleClick(id, val, newval, studentId) {
    debugger;
    SetRequestId(id);
    SetAppRejStatus(val);
    if (newval == null) {
      SetApproverStageId(0);
    }
    SetApproverStageId(newval);
    setStudentId(studentId);
  }

  function ViewClick(id, val, newval, studentId) {
    debugger
    SetRequestId(id);
    SetAppRejStatus(val);
    if (newval == null) {
      SetApproverStageId(0);
    }
    SetApproverStageId(newval);
    setStudentId(studentId);
  }

  function refreshPage() {
    window.location.reload(false);
  }
  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    // Trigger a click event on the hidden file input
    fileInputRef.current.click();
  };

  const handleFileSelected = (event) => {
    debugger;
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.name.endsWith(".xlsx")) {
      // Handle the selected Excel file here
      console.log("Selected Excel file:", selectedFile);
    } else {
      // Display an error message or handle the invalid file type
      console.error("Invalid file type. Please select an Excel file (.xlsx)");
    }
    let reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = (e) => {
      var filestr = e.target.result.split(",");
      SavaFile(filestr[1]);
    };
  };

  const SavaFile = (str) => {
    debugger;
    const obj = {
      str: str,
      EmployeeID: Number(cookies.userID),
      RoleID: Number(cookies.roleID),
    };
    $(".loader-container").show();
    _ApprovalService.BulkUpload(obj)
      .then((response) => {
        debugger;

        $(".loader-container").hide();

        // Check if response.data.value is available
        if (response.data.responseObject) {
          const responseData = JSON.parse(response.data.responseObject);

          const successCount = responseData.TotalCount;
          const errorMessage = responseData.Errors;
          const errorCount = responseData.Errors.length;
          const totalCount = successCount + errorCount;
          if (totalCount == successCount) {
            Toast(
              `${responseData.TotalCount} Transaction Uploaded successfully`,
              "success"
            )
            setTimeout(() => {
              $(".close").trigger("click");
            }, 2000);
            refreshPage();
          } else if (errorCount > 0) {
            swal.fire({
              icon: 'warning',
              title: 'Upload Transaction Details',
              customClass: 'swal-wide',
              html: `
              <style>
                table {
                  border-collapse: collapse;
                  width: 100%;
                  margin-bottom: 10px;
                }
                th, td {
                  border: 1px solid #ddd;
                  padding: 8px;
                  text-align: center;
                }
                .error-container {
                  max-height: 100px;
                  overflow-y: auto;
                  margin-top: 10px;
                  word-wrap: break-word;
                }
                .error-list {
                  list-style-type: none;
                  padding: 0;
                  margin: 0;
                }
                .error-list-item {
                  margin-bottom: 5px;
                  font-size: 12px;
                  text-align:left;
                }
                .error-head{
                  font-size: 12px;
                }
              </style>
              <table>
                <thead>
                  <tr>
                    <th>Total Count</th>
                    <th>Success Count</th>
                    <th>Error Count</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>${totalCount}</td>
                    <td>${successCount}</td>
                    <td>${errorCount}</td>
                  </tr>
                </tbody>
              </table>
                    <div class="error-container">
            <div><b class="error-head">Error Messages:</b></div>
            <div class="error-list">
              ${errorMessage.map(msg => `<div class="error-list-item">${msg}</div>`).join('')}
            </div>
          </div>
            `,
            }).then((result) => {
              if (result.isConfirmed) {
                refreshPage();
              }
            });
          }



        } else {
          // Handle unexpected response format
          console.error("Unexpected response format:", response);
          $(".loader-container").hide();
          Toast("In Excel, fill the all mandatory fields.", "warning");
          setTimeout(() => {
            $(".close").trigger("click");
          }, 2000);
          refreshPage();
        }
      })
      .catch((error) => {
        console.error(error);
        $(".loader-container").hide();
        Toast("An error occurred while uploading transaction", "error");
        refreshPage();
      });
  };
  const HeaderElements = () => (
    <>

      <a className="btn btn-primary"
        // onClick={handleFileUpload}
        style={{ marginLeft: "1px", color: "white", display: RoleName != "Accountant" ? "none" : "" }}
      >
        <CloudUploadIcon className="upload-icon" />

        <span onClick={handleFileUpload} className="ml-1">Upload Transaction</span>
      </a>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelected}
        accept=".xlsx"
      />

      <a
        className="btn btn-primary"
        //href={}
        onClick={downloadtemplate}
        download
        style={{ marginLeft: "1px", color: "white", display: RoleName != "Accountant" ? "none" : "" }}
      >
        <CloudDownloadIcon className="download-icon" />
        <span className="ml-1"> Transaction Template</span>
      </a>
      
      <a
        className="btn btn-primary"
        onClick={toggleFundHistory}
        style={{ marginLeft: "1px", color: "white" }}
      >
        <span className="ml-1">{isFundHistory ? "Fund Request History" : "New Fund Request"}</span>
      </a>
    </>
  );
  const handlePageChange = (page) => {
      if(page?.currentTarget.ariaLabel  == 'Go to next page'){
        setCurrentPage(currentPage + 1);    
      }
      else{
        setCurrentPage(currentPage - 1);    
      }
   };
  const handleMainPageChange = (page) => {
      setCurrentPage(page);
   };

  useEffect(() => {
    const savedPage = sessionStorage.getItem("currentPage");
    if (savedPage !== null) {
      setCurrentPage(parseInt(savedPage, 0));
    }

  }, []);

  useEffect(() => {
    sessionStorage.setItem("currentPage", currentPage);
  }, [currentPage]);


  //for scroll
  useEffect(() => {
    if (RequestList && RequestList.length > 0) {
      if (window.location.pathname === "/ApprovalGrid") {
        const scrollHandler = () => {
          if (window.location.pathname === "/ApprovalGrid") {
          const scrollPosition = $(window).scrollTop();
          localStorage.setItem("Scroll", scrollPosition.toString());
          }
        };
  
        setTimeout(() => {
          if (window.location.pathname === "/ApprovalGrid") {
          window.addEventListener("scroll", scrollHandler);
  
          const storedScrollPosition = localStorage.getItem("Scroll");
          if (storedScrollPosition !== null) {
            $(window).scrollTop(parseInt(storedScrollPosition));
          }
        }
        }, 100);
  
        
        return () => {
          window.removeEventListener("scroll", scrollHandler);
        };
      }
    }
  }, [RequestList]);


  const columns = [
    {
      name: "ticketNumber",
      label: "Ticket No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "studentName",
      label: "Student Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "joiningDate",
      label: "Batch No",
      options: {
        filter: true,
        sort: true,
      },
    },
    
    {
      name: "type",
      label: "Fund Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ticketDetails",
      label: "Ticket Details",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "billNumber",
      label: "Bill Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdDate",
      label: "Requested Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      // options: {
      //   filter: true,
      //   sort: true,
      //   customBodyRender: (value) => {
      //     let statusText = "";
      //     let statusColor = "";

      //     if (value === -1) {
      //       statusText = "Rejected";
      //       statusColor = "red";
      //     } else if (value === 1) {
      //       statusText = "Requested";
      //       statusColor = "green";
      //     }

      //     return <span style={{ color: statusColor }}>{statusText}</span>;
      //   },
      // },
    },

    {
      name: "employeeId",
      label: "employeeId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "roleId",
      label: "roleId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "isApproved",
      label: "roleId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "firstLevelApprovedBy",
      label: "firstLevelApprovedBy",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "isSubmit",
      label: "secondLevelApprovedBy",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "secondLevelApprovedBy",
      label: "thirdLevelApprovedBy",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "roleName",
      label: "roleName",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "approverStageId",
      label: "ApproverStageId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "accountantBy",
      label: "accountantBy",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "studentId",
      label: "studentId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "isClosed",
      label: "isClosed",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "statusId",
      label: "statusId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "ticketId",
      label: "Option",
      options: {
        filter: true,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          debugger
          const value = tableMeta.rowData[16];
          const EmpId = tableMeta.rowData[9];
          const status = tableMeta.rowData[8];
          const roleid = tableMeta.rowData[10];
          const ispprove = tableMeta.rowData[11];
          const role = tableMeta.rowData[12];
          const appid = tableMeta.rowData[13];
          const account = tableMeta.rowData[14];
          const studentId = tableMeta.rowData[18];
          const StatusId = tableMeta.rowData[20];


          return (
            <div className="center">
              {(tableMeta.rowData[12] + 1 == tableMeta.rowData[14] && RoleName === "Accountant") ||
                (tableMeta.rowData[12] == tableMeta.rowData[14] && RoleName === "Accountant") ? (
                tableMeta.rowData[10] === Number(cookies.roleID) &&
                  tableMeta.rowData[8] != "Rejected" &&
                  tableMeta.rowData[8] != "Credited" &&
                  tableMeta.rowData[12] + 1 == tableMeta.rowData[14] &&
                  tableMeta.rowData[19] == 0 ? (
                  <>
                    <IconButton
                      title="Approve"
                      style={{ color: "green" }}
                      variant="primary"
                      data-bs-toggle="modal"
                      data-bs-target="#AccountantModal"
                      onClick={() =>
                        AccountantHandleClick(values, 1, appid, studentId)
                      }
                    >
                      <ApproveIcon />
                    </IconButton>
                    <IconButton
                      title="Reject"
                      style={{ color: "red" }}
                      variant="primary"
                      data-bs-toggle="modal"
                      data-bs-target="#AccountantModal"
                      onClick={() =>
                        AccountantHandleClick(values, 0, appid, studentId)
                      }
                    >
                      <RejectIcon />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    aria-label="View"
                    data-bs-toggle="modal"
                    data-bs-target="#AccountantView"
                    title="View"
                    onClick={() =>
                      AccountantHandleClick(values, 1, appid, studentId)
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                )
              ) : (tableMeta.rowData[9] === Number(cookies.userID) &&
                tableMeta.rowData[10] === Number(cookies.roleID) &&
                tableMeta.rowData[12] == tableMeta.rowData[13] &&
                StatusId != tableMeta.rowData[12] + 1 &&
                tableMeta.rowData[11] === 1) ||
                tableMeta.rowData[19] === 1 ? (
                <>
                  <IconButton
                    aria-label="View"
                    data-bs-toggle="modal"
                    data-bs-target="#ActionViewModal"
                    title="View"
                    onClick={() => ViewClick(values, 1, appid, studentId)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </>
              ) :
                //tableMeta.rowData[8] === Number(cookies.userID) &&
                tableMeta.rowData[10] === Number(cookies.roleID) &&
                  tableMeta.rowData[12] == tableMeta.rowData[13] &&
                  //StatusId !=tableMeta.rowData[11] + 1 &&
                  tableMeta.rowData[8].includes("Rejected") &&
                  tableMeta.rowData[11] === 0 ? (
                  <>
                    <IconButton
                      aria-label="View"
                      data-bs-toggle="modal"
                      data-bs-target="#ActionViewModal"
                      title="View"
                      onClick={() => ViewClick(values, 1, appid, studentId)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      title="Approve"
                      style={{ color: "green" }}
                      variant="primary"
                      data-bs-toggle="modal"
                      data-bs-target="#FundModal"
                      onClick={() => HandleClick(values, 1, appid, studentId, status)}
                    >
                      <ApproveIcon />
                    </IconButton>
                    <IconButton
                      title="Reject"
                      style={{ color: "red" }}
                      variant="primary"
                      data-bs-toggle="modal"
                      data-bs-target="#FundModal"
                      onClick={() => HandleClick(values, 0, appid, studentId, status)}
                    >
                      <RejectIcon />
                    </IconButton>
                    
                  </>
                )}
            </div>
          );
        },
      },
    },
  ];
  return (
    <div class="main-panel">
      <div class="content">
        <div className="page-inner">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                  
                    {/* <TablePagination
                    rowsPerPageOptions={[10, 15, 100, { value: RequestList.length, label: 'ALL' }]}
                    component="div"
                    count={RequestList.length}
                    rowsPerPage={rowsPerPage}
                    page={currentPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    /> */}

                    {/* </div> */}
                    <MUIDataTable
                      data={RequestList}
                      title="Fund Request List"
                      columns={columns}
                      options={{
                        responsive: true,
                        download: true,
                        print: true,
                        selectableRows: false,
                        filter: true,
                        viewColumns: true,
                        customToolbar: () => (

                          <>

                            <HeaderElements />
                          </>
                        ),
                        page: currentPage,
                        pagination: true, // Enable pagination
                        rowsPerPage: rowsPerPage,
                        onChangePage: handleMainPageChange, // For older versions, use onChangePage instead of onPageChange
                        onChangeRowsPerPage: handleMaainRowsPerPageChange,

                        // page: currentPage,
                        // onChangePage: (currentPage) => handlePageChange(currentPage),
                        // pagination: false,
                        // paginationPosition: 'top', 
                        rowsPerPageOptions: [10, 15, 100, { value: RequestList.length, label: 'ALL' }],
                      }}
                    />
                

                    {RoleName === "Accountant" ? (
                      <div>
                        <div
                          class="modal fade"
                          id="AccountantModal"
                          tabindex="-1"
                          role="dialog"
                          aria-hidden="true"
                          data-bs-backdrop="static"
                        >
                          <div class="modal-dialog modal-xl" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h3 class="modal-title">
                                  <span class="fw-mediumbold">
                                    Fund Request List{" "}
                                  </span>
                                </h3>
                                <button
                                  type="button"
                                  class="close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => refreshPage()}
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <AccountantAction
                                  ID={RequestId}
                                  Status={AppRejStatus}
                                  StageId={ApproverStageId}
                                  StudentId={studentId}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <div
                            class="modal fade"
                            id="FundModal"
                            tabindex="-1"
                            role="dialog"
                            aria-hidden="true"
                            data-bs-backdrop="static"
                            style={{ overflowY: 'auto' }}
                          >
                            <div class="modal-dialog modal-xl" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h3 class="modal-title">
                                    <span class="fw-mediumbold">
                                      Fund Request List{" "}
                                    </span>
                                  </h3>
                                  <button
                                    type="button"
                                    class="close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => refreshPage()}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <ActionPage
                                    ID={RequestId}
                                    Status={AppRejStatus}
                                    StageId={ApproverStageId}
                                    StudentId={studentId}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    ) : (
                      <>
                        {" "}
                        <div>
                          <div
                            class="modal fade"
                            id="FundModal"
                            tabindex="-1"
                            role="dialog"
                            aria-hidden="true"
                            data-bs-backdrop="static"
                            style={{ overflowY: 'auto' }}
                          >
                            <div class="modal-dialog modal-xl" role="document">
                              <div class="modal-content">
                                <div class="modal-header">
                                  <h3 class="modal-title">
                                    <span class="fw-mediumbold">
                                      Fund Request List{" "}
                                    </span>
                                  </h3>
                                  <button
                                    type="button"
                                    class="close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => refreshPage()}
                                  >
                                    <span aria-hidden="true">&times;</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <ActionPage
                                    ID={RequestId}
                                    Status={AppRejStatus}
                                    StageId={ApproverStageId}
                                    StudentId={studentId}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div>
                      <div
                        class="modal fade"
                        id="ActionViewModal"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                        style={{ overflowY: 'auto' }}
                      >
                        <div class="modal-dialog modal-xl" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h3 class="modal-title">
                                <span class="fw-mediumbold">
                                  Fund Request List{" "}
                                </span>
                              </h3>
                              <button
                                type="button"
                                class="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                //onClick={() => refreshPage()}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <ActionPageView
                                ID={RequestId}
                                Status={AppRejStatus}
                                StageId={ApproverStageId}
                                StudentId={studentId}
                                Role={cookies.roleName}
                                setApproveGridRender={setGridRender}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        class="modal fade"
                        id="AccountantView"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                      >
                        <div class="modal-dialog modal-xl" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h3 class="modal-title">
                                <span class="fw-mediumbold">
                                  Fund Request List{" "}
                                </span>
                              </h3>
                              <button
                                type="button"
                                class="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => refreshPage()}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <AccountantActionView
                                ID={RequestId}
                                Status={AppRejStatus}
                                StageId={ApproverStageId}
                                StudentId={studentId}
                                setApproveGridRender={setGridRender}
                              />

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ApprovalGrid;
