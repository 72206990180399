import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import AuthenticationService from '../api/AuthenticationService';

import { useCookies } from 'react-cookie';

// import { useNavigate } from 'react-router';

// import { useNavigate } from "react-router";




export const AuthContext = createContext({ user: [], auth: false });

const AuthObject = new AuthenticationService();


export const AuthProvider = ({ children }) => {

    //  const navigate = useNavigate();
    const [data, setData] = useState({
        user: [],
        auth: false,
        priviledge: ''
    });



    const [cookies, setCookie, removeCookie] = useCookies(['email', 'RoleName', 'id', 'username', 'employeename', 'token', 'otpusername', 'departmentid', 'departmentfirstid', 'departmentfirstname', 'UserPrivileges', 'KAPPU', 'KAPPP', 'Rememberme', 'roleID', 'KACHPPP', 'ISReloadfunction']);

    const [authentication, setAuthentication] = useState(false);



    const login = (authentication) => {

        AuthObject.getData(authentication)
            .then((res) => (

                setData(
                    data.user = res.data,
                    data.auth = true
                ),
                setCookie('email', data.user.email, { path: "/" }),
                setCookie('id', data.user.id, { path: "/" }),
                setCookie('username', data.user.user_Name, { path: "/" }),
                setCookie('employeename', data.user.employee_Name, { path: "/" }),
                setCookie('token', data.user.token, { path: "/" }),
                setCookie('departmentid', data.user.departmentids, { path: "/" }),
                setAuthentication(true)

            ))

            .catch((res) => {
            });

    };




    const logout = () => {
        setAuthentication(false);
        setData((data) => ({
            name: [],
            auth: false,
        }));


        AuthObject.SetLogoutTime(Number(cookies.userID))
            .then((res) => ({

            }))
            .catch((res) => {

            });

        removeCookie('userName');
        removeCookie('roleName');
        removeCookie('employeeName');
        removeCookie('token');
        removeCookie('userID');
        removeCookie('emailID');
        removeCookie('departmentid');
        removeCookie('UserPrivileges');
        removeCookie('KAPPU');
        removeCookie('KAPPP');
        removeCookie('id');
        removeCookie('KAPPP');
        removeCookie('KAPPU');
        removeCookie('idleTime');
        sessionStorage.removeItem("currentPage");
        localStorage.removeItem("Scroll");


        // navigate('/login', {replace : true})

    };

    return (
        <AuthContext.Provider value={{ data, cookies, authentication, setCookie, login, logout, removeCookie, setData }}>
            {children}
        </AuthContext.Provider>
    );
}