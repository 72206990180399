import React from "react";
import AxiosServices from "./AxiosService";

const Auth = new AxiosServices();


class AuthenticationService {

    getData = async (authentication) => {



        return Auth.post('/Login/Authenticate', authentication)

    };

    forgotpassword = async (user) => {

        return Auth.post('/Login/ForgetPassword', user)
    }

    verifyotp = async (otp) => {

        return Auth.post('/Login/ForgotpasswordCheckOtp', otp)

    }

    resetpassword = async (resetdata) => {

        return Auth.post('/Login/ChangePasswordFromOTP', resetdata)
    }
    ChangePassword = async (changepass) => {

        return Auth.post('/Login/ChangePassword', changepass)
    }

    getRole = async (EmployeeCode) => {
        return Auth.get('/Login/GetRole?EmployeeCode=' + EmployeeCode,)
    };

    getSidebarDropdownList = async (EmployeeId) => {
        return Auth.get('/Login/SidebarDropdownList?EmployeeId=' + EmployeeId,)
    };

    getSidebarDropdownSelected = async (RoleID) => {
        return Auth.get('/Login/SidebarDropdownSelected?RoleID=' + RoleID,)
    };

    DeleteEmployee = async (EmpID) => {
        return Auth.get(`/Employee/DeleteEmployee?Id=${EmpID}`,)
    };
    SetLogoutTime = async (EmpID) => {
        debugger
        return Auth.get(`/Login/SetLogoutTime?Id=${EmpID}`,)
    }
}

export default AuthenticationService

