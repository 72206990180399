import AxiosServices from "./AxiosService";
const axios = new AxiosServices();

class ReportServices {
    Report_FundReport = (flag, month, year,Coursee) => {
        try {
            return axios.get(`Report/Report_FundReport?flag=${flag}&month=${month}&year=${year}&course=${Coursee}`)

        } catch (error) {
            console.log(error);
        }
    }

    Report_CreditReport = (month, year,Coursee) => {
        try {
            return axios.get(`Report/Report_CreditReport?Month=${month}&Year=${year}&Course=${Coursee}`)

        } catch (error) {
            console.log(error);
        }
    }

    GetCourse = () => {
        try {
            return axios.get(`Report/GetCourses`)

        } catch (error) {
            console.log(error);
        }
    }
}

export default ReportServices;