import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef,
} from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import ReimbursementService from "../../api/ReimbursementService";
import Toast from "../Toastify";
import { AuthContext } from "../../Context/AuthContext";
import $ from "jquery";
import "bootstrap/js/dist/modal";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ApprovalService from "../../api/ApprovalService";
import Axios from "axios";
//import Upload from "antd/lib/upload/Upload";
import { saveAs } from "file-saver";

import 'react-datepicker/dist/react-datepicker.css';
// import { fontWeight } from "@mui/system";

const RemObj = new ReimbursementService();
const _ApprovalService = new ApprovalService();

const FundRequest = (props) => {
  const { ID, StudentId } = props;
  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [funTypeList, setFunTypeList] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [TicketCode, setTicket] = useState("");
  const [amount, setAmount] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [billDate, setBillDate] = useState(null);
  const [FundDetail, setFundDetails] = useState("");
  const [TicketId, setTicketId] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [filepath, Setfilepath] = useState("");
  const [fileerror, setfileerror] = useState("");
  const [downloadpath, setdownloadpath] = useState("");
  const [StudentRequestList, SetStudentRequestList] = useState([]);

  const [TicketById, setTicketById] = useState([]);
  const maxDate = new Date();
  const minDate = new Date(2020, 0, 1);
  var today = new Date(),
    date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();



  useEffect(() => {
    var roleid = cookies.roleID;
    if (ID > 0) {
      RemObj.Get_RequestById(ID, roleid, 0)
        .then((response) => {
          const data = response.data;

          if (data && data.ticket && data.ticket.length > 0) {
            debugger
            const firstTicket = data.ticket[0];
            setTicket(firstTicket.ticketNumber);
            setSelectedTicket({
              value: firstTicket.fundType,
              label: firstTicket.type,
            });
            setAmount(firstTicket.amount);
            setBillNumber(firstTicket.billNumber);
            const billDate = new Date(firstTicket.billDate)
            setBillDate(billDate);
            setFundDetails(firstTicket.ticketDetails);
            Setfilepath(firstTicket.fileName);
            // setdownloadpath(firstTicket.downloadPath);
            setSelectedFile(firstTicket.fileName);
            setdownloadpath("/Uploadfiles/" + firstTicket.fileName);
            console.log("Fetched ticket data:", firstTicket);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    if (StudentId != null) {
      _ApprovalService
        .Student_CreditedInfo(StudentId)
        .then((res) => {
          if (res != null && res.data != null && res.data.length > 0) {
            const result = res.data;
            SetStudentRequestList(result);
            console.log(result);
          }
          $(".loader-container").hide();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ID]);

  useEffect(() => {
    if (ID == 0) {
      RemObj.Get_RequestDetails()
        .then((response) => {
          const data = response.data;

          if (data && data.funType && data.funType.length > 0) {
            setFunTypeList(data.funType);
          }

          // if (data && data.ticketCode) {
          //   setTicket(data.ticketCode);
          // }
          console.log(funTypeList);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, []);

  const handleAmountChange = (event) => {
    const input = event.target.value;
    const numericInput = input.replace(/[^0-9.]/g, ""); // Allow digits and decimal point

    const parts = numericInput.split(".");
    const integerPart = parts[0];
    let decimalPart = parts[1];

    if (integerPart.length <= 10) {
      if (decimalPart !== undefined) {
        decimalPart = decimalPart.slice(0, 2); // Limit decimal part to 2 digits
      }

      const formattedIntegerPart = new Intl.NumberFormat("en-IN").format(
        integerPart
      );

      let formattedAmount = formattedIntegerPart;

      if (decimalPart !== undefined) {
        formattedAmount += "." + decimalPart;
      }

      // Check if the trimmed integer part is empty
      if (formattedAmount === "0.") {
        formattedAmount = "";
      }

      setAmount(formattedAmount);
    } else {
      // Handle the case where there are too many integer digits
      const trimmedIntegerPart = integerPart.slice(0, 10);
      const formattedIntegerPart = new Intl.NumberFormat("en-IN").format(
        trimmedIntegerPart
      );

      let formattedAmount = formattedIntegerPart;

      if (decimalPart !== undefined) {
        decimalPart = decimalPart.slice(0, 2); // Limit decimal part to 2 digits
        formattedAmount += "." + decimalPart;
      }

      setAmount(formattedAmount);
    }
  };

  // const handleMouseOut = () => {
  //   if (amount.trim() !== "") {
  //     const formattedAmount = new Intl.NumberFormat("en-IN").format(
  //       parseFloat(amount.replace(/,/g, ""))
  //     );
  //     setAmount(formattedAmount);
  //   }
  // };
  const inputRef = useRef(null);

  const handleInputChange = (e) => {
    const numericValue = e.target.value.replace(/[^0-9]/g, "");

    // Calculate the new cursor position based on the changes in the input value
    const cursorPosition = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;
    const selectionLength = selectionEnd - cursorPosition;
    const newCursorPosition =
      cursorPosition - (amount.length - numericValue.length);

    setAmount(numericValue);
    // setErrors({ ...errors, Amount: "" }); // Clear the error message

    // Set the new cursor position, considering the selection length
    // e.target.setSelectionRange(
    //   newCursorPosition,
    //   newCursorPosition + selectionLength
    // );
  };

  const handleInputFocus = (e) => {
    // Remove commas from the input value when it receives focus
    const numericValue = e.target.value.replace(/,/g, "");
    setAmount(numericValue);
  };

  const handleInputBlur = () => {
    if (amount) {
      // Format the numeric value with commas when the input loses focus
      const formattedAmount = new Intl.NumberFormat("en-IN").format(
        parseFloat(amount)
      );
      setAmount(formattedAmount);
    } else {
      setAmount("");
    }
    setErrors({ ...errors, Amount: "" });
  };
  const handleBillNumberChange = (event) => {
    const input = event.target.value;
    const sanitizedInput = input.replace(/[^a-zA-Z0-9/-]/g, ""); // Allow letters, numbers, /, and -
    setBillNumber(sanitizedInput);
  };

  const handleFileChange = (event) => {
    debugger;
    const file = event.target.files[0];
    if (validateFileExtension(file)) {
      setSelectedFile(file);
    } else {
      setSelectedFile(filepath);
      setfileerror(
        "Invalid file format. Please select a valid file.It allows .jpg, .jpeg, .png, .pdf"
      );
    }
  };

  const validateFileExtension = (file) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };

  const [errors, setErrors] = useState({
    FundType: "",
    Amount: "",
    billNumber: "",
    billDate: "",
    UploadPath: "",
    FundDetails: "",
  });

  // const validateForm = () => {
  //   let newErrors = { ...errors };
  //   newErrors.FundType = selectedTicket ? "" : "Fund Type is required.";
  //   newErrors.Amount = amount ? "" : "Amount is required.";

  //   if (selectedTicket.value != 1) {
  //     newErrors.billNumber = billNumber ? "" : "Bill Number is required.";
  //   }
  //   newErrors.FundDetails = FundDetail ? "" : "Fund Details is required.";
  //   newErrors.UploadPath = selectedFile ? "" : "Bill Upload is required.";

  //   setErrors(newErrors);
  //   return Object.values(newErrors).every((error) => error === "");
  // };
  const validateForm = () => {
    let newErrors = { ...errors };
    newErrors.FundType = selectedTicket && selectedTicket.value ? "" : "Fund Type is required.";
    newErrors.Amount = amount ? "" : "Amount is required.";

    if (selectedTicket && selectedTicket.value !== 1) {
      newErrors.billNumber = billNumber ? "" : "Bill Number is required.";
      newErrors.billDate = billDate ? "" : "Bill Date is required.";
    }
    newErrors.FundDetails = FundDetail ? "" : "Fund Details is required.";
    newErrors.UploadPath = selectedFile ? "" : "Bill Upload is required.";

    setErrors(newErrors);
    return Object.values(newErrors).every((error) => error === "");
  };


  const HandleSubmit = (e, skipValidation = false) => {
    debugger
    e.preventDefault();
    if (skipValidation) {
      debugger
      const formData = new FormData();

      formData.append("TicketId", ID);
      //formData.append("TicketNumber", TicketCode);

      formData.append("Amount", amount);
      formData.append("BillNumber", billNumber);
      formData.append("BillDate", billDate);
      if (selectedFile !== null) {
        formData.append("UploadPath", selectedFile);
      } else {
        formData.append("FileName", filepath);
      }

      const ticketDetails = FundDetail;
      if (ticketDetails) {
        formData.append("TicketDetails", ticketDetails);
      }

      formData.append("CreatedBy", cookies.userID);
      formData.append("CreatedDate", date);
      formData.append("IsSubmit", -1);
      if (selectedTicket.value != 1) {
        if (selectedTicket) {
          formData.append("FundType", selectedTicket.value);
        } else {
          formData.append("FundType", 0);
        }
        const obj = {
          TicketId: ID,
          BillNumber: e.target.billNumber.value,
        };

        RemObj.GetbillNoExsists(obj)
          .then((res) => {
            console.log(res);

            Submit_Request(formData);
          })
          .catch((err) => {
            $("#errValue").text("Bill Number is already exists");
          });
      } else {
        Submit_Request(formData);
      }
    } else if (validateForm()) {
      const formData = new FormData();

      formData.append("TicketId", ID);
      //formData.append("TicketNumber", e.target.TicketNumber.value);
      formData.append("FundType", selectedTicket.value);
      formData.append("Amount", e.target.Amount.value);
      formData.append("BillDate", billDate);
      if (selectedFile != null) {
        formData.append("UploadPath", selectedFile);
      } else {
        formData.append("FileName", filepath);
      }
      formData.append("TicketDetails", e.target.FundDetails.value);
      formData.append("CreatedBy", cookies.userID);
      formData.append("CreatedDate", date);
      formData.append("IsSubmit", 0);

      if (selectedTicket.value != 1) {
        formData.append("BillNumber", e.target.billNumber.value);
        const obj = {
          TicketId: ID,
          BillNumber: e.target.billNumber.value,
        };

        RemObj.GetbillNoExsists(obj)
          .then((res) => {
            console.log(res);

            Submit_Request(formData);
          })
          .catch((err) => {
            $("#errValue").text("Bill Number is already exists");
          });
      } else {
        Submit_Request(formData);
      }
    }
  };

  const Submit_Request = (formData) => {
    $(".loader-container").show();
    RemObj.SubmitRequest(formData)
      .then((response) => {
        debugger
        if (response.data > 0) {
          Toast("Request Created Successfully", "success");
        } else if (response.data == 0) {
          Toast("Please try again", "warning");
        }
        else {
          Toast("Request Updated Successfully", "success");

        }
        $(".close").trigger("click");
        $(".loader-container").hide();
      })
      .catch((error) => {
        $(".loader-container").hide();
        console.error("Error while saving:", error);
        refreshPage();
      });
  };

  function refreshPage() {
    window.location.reload(false);
  }

  function formatIndianNumber(number) {
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  function checkexitsornot(obj) {
    debugger;
    RemObj.GetbillNoExsists(obj)
      .then((res) => {
        console.log(res);

        Submit_Request(obj);
      })
      .catch((err) => {
        $("#errValue").text("Bill Number is already exists");
      });
  }

  function download(url) {
    RemObj.Download(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }
  return (
    <form
      id="PersonalDetailsCreate"
      onKeyPress={(e) => {
        if (e.key == "Enter") {
          e.preventDefault();
        }
      }}
      onSubmit={HandleSubmit}
    >
      <div class="row">
        <div class="col-lg-7">
          <div className="row">
            {ID > 0 && (
              <div className="col-lg-6 mb-2">
                <div className="form-group">
                  <label className="text-label form-label">Request Number</label>
                  <input
                    type="text"
                    name="TicketNumber"
                    className="form-control"
                    placeholder=""
                    value={TicketCode}
                    disabled={true}
                    autoComplete="off"
                  />
                </div>
              </div>
            )}
            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Request Type <span class="text-danger">*</span>
                </label>
                <Select
                  options={funTypeList.map((item) => ({
                    value: item.fundTypeId,
                    label: item.fundType,
                  }))}
                  value={selectedTicket}
                  onChange={(selectedOption) => {
                    debugger;
                    setSelectedTicket(selectedOption);
                    setErrors({ ...errors, FundType: "" }); // Clear the error message
                  }}
                />

                <div class="invalid-feedback" style={{ display: "block" }}>
                  {errors.FundType}
                </div>
              </div>
            </div>

            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Amount <span class="text-danger">*</span>
                </label>
                {/* <input
                  type="text"
                  name="Amount"
                  className="form-control"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => {
                    handleAmountChange(e); // Call the existing handler
                    setErrors({ ...errors, Amount: "" }); // Clear the error message
                  }}
                  autoComplete="off"
                /> */}

                {/* <input
                  type="text"
                  name="Amount"
                  className="form-control"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => {
                    const numericValue = e.target.value.replace(/[^0-9]/g, "");
                    setAmount(numericValue);
                    setErrors({ ...errors, Amount: "" }); // Clear the error message
                  }}
                  onMouseOut={handleMouseOut}
                  autoComplete="off"
                  maxLength={12}
                /> */}

                <input
                  type="text"
                  name="Amount"
                  className="form-control"
                  placeholder="Amount"
                  value={amount}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur} // Handle onBlur event
                  autoComplete="off"
                  maxLength={12}
                />
                <div class="invalid-feedback" style={{ display: "block" }}>
                  {errors.Amount}
                </div>
              </div>
            </div>

            {selectedTicket != null && selectedTicket.value == 1 ? (
              <></>
            ) : (
              <div class="col-lg-6 mb-2">
                <div class="form-group">
                  <label class="text-label form-label">
                    Bill Number <span class="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="billNumber"
                    className="form-control"
                    placeholder="Bill Number"
                    value={billNumber}
                    onChange={(e) => {
                      handleBillNumberChange(e); // Call the existing handler
                      setErrors({ ...errors, billNumber: "" }); // Clear the error message
                    }}
                    maxLength={20}
                    autoComplete="off"
                  />
                  <div class="invalid-feedback" style={{ display: "block" }}>
                    {errors.billNumber}
                  </div>
                  <span style={{ color: "red" }} id="errValue"></span>
                </div>
              </div>
            )}
            {selectedTicket != null && selectedTicket.value == 1 ? (
              <></>
            ) : (
              <div class="col-lg-6 mb-2">
                <div class="form-group">
                  <label class="text-label form-label">
                    Bill Date <span class="text-danger">*</span>
                  </label>
                  <DatePicker
                    selected={billDate}
                    onChange={(date) => setBillDate(date)}
                    minDate={minDate}
                    maxDate={maxDate}
                    placeholderText="Select Bill Date"
                    className="form-control"
                    dateFormat="dd-MM-yyyy"
                  />
                  <div class="invalid-feedback" style={{ display: "block" }}>
                    {errors.billDate}
                  </div>
                  <span style={{ color: "red" }} id="errValue"></span>
                </div>
              </div>

            )}

            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  {selectedTicket?.value == 1
                    ? filepath !== null
                      ? "Reference / Proof"
                      : "View Reference / Proof"
                    : filepath !== null
                      ? "Upload Bill"
                      : "View Bill"}
                </label>
                <span class="text-danger">*</span>
                <div>
                  <input
                    type="file"
                    name="UploadPath"
                    className="form-control"
                    accept=".jpg, .jpeg, .png, .pdf"
                    onChange={(e) => {
                      handleFileChange(e); // Call the existing handler
                      setErrors({ ...errors, UploadPath: "" }); // Clear the error message
                    }}
                  />
                  {ID > 0 && filepath !== null && (
                    <>
                      {/* <a href={downloadpath} download>
                        <CloudDownloadIcon className="download-icon" />
                        <div>{filepath}</div>
                      </a> */}

                      <a onClick={() => download(filepath)} title="Download">
                        <CloudDownloadIcon className="download-icon" />
                        {/* <div>{filepath}</div> */}
                      </a>
                    </>
                  )}

                  <div class="invalid-feedback" style={{ display: "block" }}>
                    {fileerror}
                  </div>
                  <div class="invalid-feedback" style={{ display: "block" }}>
                    {errors.UploadPath}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 mb-2">
              <div class="form-group">
                <label class="text-label form-label">
                  Fund Details<span class="text-danger">*</span>
                </label>
                <div>
                  <textarea
                    name="FundDetails"
                    class="form-control"
                    value={FundDetail}
                    placeholder=""
                    onChange={(e) => {
                      setFundDetails(e.target.value);
                      setErrors({ ...errors, FundDetails: "" }); // Clear the error message
                    }}
                    autoComplete="off"
                  ></textarea>
                  <div class="invalid-feedback" style={{ display: "block" }}>
                    {errors.FundDetails}
                  </div>
                </div>
              </div>
            </div>
            {ID === 0 ? (
              <>
                <div className="col-12 modal-footer no-bd">
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => refreshPage()}
                  >
                    Cancel
                  </button>
                  {/* <button
                    class="btn btn-info float-right"
                    style={{ marginLeft: "10px" }}
                    onClick={(e) => HandleSubmit(e, true)}
                  >
                    Save
                  </button> */}
                  <button
                    class="btn btn-primary float-right"
                    style={{ marginLeft: "10px" }}
                    onClick={validateForm}
                  >
                    Submit & Send Email
                  </button>
                </div>
              </>
            ) : (
              <>
                <div
                  className="col-12 modal-footer no-bd"
                  style={{ paddingLeft: "60%" }}
                >
                  <button
                    type="button"
                    class="btn btn-danger"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => refreshPage()}
                  >
                    Cancel
                  </button>
                  <button
                    class="btn btn-primary float-right"
                    style={{ marginLeft: "10px" }}
                    onClick={validateForm}
                  >
                    Submit & Send Email
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div class="col-lg-5">
          {StudentRequestList != null && StudentRequestList.length > 0 ? (
            <div className="trans">
              {/* <p> <span style={{ fontWeight: "bold"}}>Allocated Amount :</span> {StudentRequestList[0].allocatedAmount}</p> */}
              <p>
                <span style={{ fontWeight: "bold" }}>
                  Total Credited Amount :
                </span>{" "}
                {StudentRequestList[0].totalCreditedAmount}
              </p>
              {/* <p><span style={{ fontWeight: "bold"}}>Balance Amount :</span> {StudentRequestList[0].balanceAmount}</p> */}
            </div>
          ) : (
            <></>
          )}
          <div
            className={
              StudentRequestList.length > 10 ? "trans-scroll" : "trans"
            }
          >
            <h3 style={{ fontWeight: "bold", color: "#1da837" }}>
              {" "}
              Transaction History
            </h3>
            <table
              id="add-row"
              className="display table table-striped table-hover"
            >
              <thead>
                <tr>
                  <th> Date</th>
                  <th> Fund Type</th>
                  <th> Amount</th>
                  <th> View Bill</th>
                  <th> View Advance Bill</th>
                </tr>
              </thead>
              <tbody>
                {StudentRequestList.map((item, index) => (
                  <tr key={index}>
                    <td>{item.creditedDate}</td>
                    <td>{item.fundType}</td>
                    <td>{item.creditedAmount}</td>
                    <td>
                      {item.billUrl != null ? (
                        <a
                          target="_blank"
                          onClick={() => download(item.billUrl)}
                          title="Download"
                        >
                          <CloudDownloadIcon className="download-icon" />
                          {/* <div>{filepath}</div> */}
                        </a>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      {item.uploadAdvanceBill != null ? (
                        <a
                          target="_blank"
                          onClick={() => download(item.uploadAdvanceBill)}
                          title="Download"
                        >
                          <CloudDownloadIcon className="download-icon" />
                          {/* <div>{filepath}</div> */}
                        </a>
                      ) : (
                        <></>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </form>
  );
};
export default FundRequest;
