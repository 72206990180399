import React, { useContext, useState, useEffect } from "react";
import $ from "jquery";
import logo from "../../assets/Final-logo.png";
import "../../assets/css/LoginIndex.css";
import "../../assets/js/atlantis.js";
// import { hasFormSubmit } from "@testing-library/user-event/dist/utils";
import { useForm } from "react-hook-form";
import { AuthContext } from "../../Context/AuthContext";
import AuthenticationService from "../../api/AuthenticationService";
import { useNavigate } from "react-router";
import swal from "sweetalert";
import MySelect from "../MultiSelect/Myselect";
import "../SideBar/Loader.css";
import AxiosServices from "../../api/AxiosService";

const URLHR = new AxiosServices();

function Login() {
  const { data, setCookie, removeCookie, cookies } = useContext(AuthContext);
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    if (cookies.ISReloadfunction == 1) {
      removeCookie("ISReloadfunction");
      window.location.reload();
    }
    var data = {
      UserName: cookies.KAPPU,
      Password: cookies.KAPPP,
      // RoleID: Number($("#Roleselect").val()),
      rememberMeflag: true,
    };
    if (cookies.ISReload == 1) {
      setCookie("ISReload", 0, { path: "/" });
      window.location.reload();
    }

    if (data.UserName != null && data.Password != null) {
      $(".loaders-containers").show();
      AuthObject.getData(data)
        .then(
          (res) => (
            // localStorage.setItem("token", data.user.token),

            setTimeout(() => {
              debugger;
              RedirectFunctions(res.data.roleName.trim().toLowerCase());
            }),
            setDisabled(false)
            // setTimeout(() => { Toast('Logged in Succesfully', 'success')}, 500)
          )
        )
        .catch((res) => {
          console.log(res);

          removeCookie("KAPPU");
          removeCookie("KAPPP");

          window.location.reload();
        });
      function RedirectFunctions(RoleName) {
        debugger;
        navigate("/Index");
        // switch (RoleName) {
        //   case "hod":
        //     navigate("/Index", { replace: true });
        //     ////$('.main-loader').hide();
        //     break;
        //   case "engineer":
        //     navigate("/Index", { replace: true });
        //     ////$('.main-loader').hide();
        //     break;
        //   case "admin":
        //     navigate("/Index", { replace: true });
        //     ////$('.main-loader').hide();
        //     break;
        //   case "md":
        //     navigate("/Index", { replace: true });
        //     ////$('.main-loader').hide();
        //     break;
        //   default:
        //     navigate("/Index", { replace: true });
        //     ////$('.main-loader').hide();
        //     break;
        // }
      }
    }
    // if (data.UserName == null && data.Password == null) {
    //   removeCookie("FirstroleCountLogin");
    // }
  });

  const AuthObject = new AuthenticationService();
  const [RoleList, setRoleList] = useState([]);
  const getRole = () => {
    var EmployeeCode = $("#Username").val();
    debugger;
    AuthObject.getRole(EmployeeCode)
      .then((res) => {
        console.log(res.data.roleList);

        setRoleList([...res.data.roleList]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [EmployeeModel, setEmployeeModel] = useState([]);
  const myEyeFunction = () => {
    var x = document.getElementById("Password");
    if (x.type === "password") {
      x.type = "text";
      $("#pswicon").removeClass("fa-eye-slash");
      $("#pswicon").addClass("fa-eye");
    } else {
      $("#pswicon").removeClass("fa-eye");
      $("#pswicon").addClass("fa-eye-slash");
      x.type = "password";
    }
  };

  const [disabled, setDisabled] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data, e) => {
    setDisabled(true);
    e.preventDefault();

    Login(data);
  };

  const [dummy, setDummy] = useState({
    prefixName: "",
    endValue: "",

    errUserName: "",
    errPassword: "",
    errRoleID: "",
  });

  const navigate = useNavigate();

  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };

  const loginSubmit = (e) => {
    e.preventDefault();

    debugger;
    const obj = new FormData(e.currentTarget);
    console.log(e);
    var data = {
      UserName: document.getElementById("Username").value.trim(),
      Password: document.getElementById("Password").value.trim(),
      // RoleID: Number($("#Roleselect").val()),
      rememberMeflag: document.querySelector("#RememberFlagCkboxs").checked,
    };

    if (data.UserName == "" && data.Password == "") {
      $("#errUsername").text("Enter Username");
      $("#errPassword").text("Enter password");

      return false;
    }

    if (data.UserName == "" && data.Password == "") {
      //     setDummy({ errUserName: "Enter username" });
      // setDummy({ errPassword: "Enter password" });
      $("#errUsername").text("Enter username");
      $("#errPassword").text("Enter password");
      return false;
    }

    if (data.UserName == null || data.UserName == "") {
      //  setDummy({ errUserName: "Enter username" });
      $("#errUsername").text("Enter Username");

      return false;
    }

    if (data.UserName != null || data.UserName != "") {
      // setDummy({ errUserName: "" });
      $("#errUsername").text("");
    }

    if (data.Password == null || data.Password == "") {
      //  setDummy({ errPassword: "Enter password" });
      $("#errPassword").text("Enter password");

      return false;
    }

    if (data.Password != null || data.Password != "") {
      $("#errPassword").text("");
      // setDummy({ errPassword: "" });
    }

    login(data);
  };
  const login = (authentication) => {
    debugger;
    if (authentication.rememberMeflag == true) {
      setCookie("KAPPU", authentication.UserName, { path: "/" });
      setCookie("KAPPP", authentication.Password, { path: "/" });
    }

    $("#btnLoginSubmit").prop("disabled", true);
    // $(".main-loader").show();
    debugger;
    AuthObject.getData(authentication)

      .then(
        (res) => (
          console.log(res.data),
          setCookie("emailID", res.data?.emailID, { path: "/" }),
          // setCookie("departmentid", data.user.departmentids, { path: "/" }),
          // setCookie(
          //   "departmentfirstid",
          //   data.user.departmentids.split(",")[0],
          //   { path: "/" }
          // ),
          // setCookie(
          //   "departmentfirstname",
          //   data.user.departmentNames.split(",")[0],
          //   { path: "/" }
          // ),
          // setCookie("departmentnames", data.user.departmentNames, {
          //   path: "/",
          // }),
          setCookie("isprofileComplete", res.data.isprofileComplete, {
            path: "/",
          }),
          setCookie('MainUserID', res.data.dummyUserID, { path: "/" }),
          setCookie("locationStatus", res.data.locationStatus, { path: "/" }),
          setCookie("userID", res.data?.userID, { path: "/" }),
          setCookie("userName", res.data?.userName, { path: "/" }),
          setCookie("employeeName", res.data?.employeeName, { path: "/" }),
          setCookie("token", res.data?.token, { path: "/" }),
          setCookie("Rememberme", authentication.rememberMeflag, { path: "/" }),
          setCookie("roleName", res.data?.roleName, { path: "/" }),
          setCookie("roleID", res.data?.roleID, { path: "/" }),
          setCookie("KpppDecript", res.data?.decriptPassword, { path: "/" }),
          setCookie("KuuuDecript", res.data?.decriptUsername, { path: "/" }),
          setCookie("CT_HRMS_APP", process.env.CT_HRMS_APP, { path: "/" }),
          setCookie("roleCount", res.data?.roleCount, { path: "/" }),
          setCookie("FirstroleCountLogin", res.data?.firstroleCountLogin, {
            path: "/",
          }),
          setCookie("KACHPPP", authentication.Password, { path: "/" }),
          setCookie("UserPrivileges", res.data?.userPrivileges.split(","), {
            path: "/",
          }),
          setCookie("SidebarReload", 1, { path: "/" }),
          setCookie("idleTime", res.data?.idletime, { path: "/" }),
          localStorage.setItem("token", data.user.token),
          setTimeout(() => {
            var checkfirstroleCountLogin = res.data?.firstroleCountLogin;

            if (checkfirstroleCountLogin == 1) {
              LoadCTHrms();
            } else {
              RedirectFunction(res.data.roleName.trim().toLowerCase());
            }
          }, 2000),
          setDisabled(false),
          $("#btnLoginSubmit").prop("disabled", false)
          // setTimeout(() => { Toast('Logged in Succesfully', 'success')}, 500)
        )
      )

      .catch((res) => {
        console.log(res.response.data.message);
        debugger;
        $("#btnLoginSubmit").prop("disabled", false);
        if (
          res.response.data.message ==
            "Username or Password should not be empty." ||
          res.response.data == "Invalid username" ||
          res.response.data == "Invalid Employee Code" ||
          res.response.data.message == "No user with the username" ||
          res.response.data.message == "Multiple Users With Same Username" ||
          res.response.data.message == "Enter a valid username or password"
        ) {
          if (
            res.response.data.message == "Invalid username" ||
            res.response.data.message == "Invalid Employee Code"
          ) {
            swal({
              text: "Invalid Username or password",
              icon: "warning",
            });
          } else {
            swal({
              text: res.response.data.message,
              icon: "warning",
            });
          }
        } else if (res.response.data.message == "Invalid username") {
          swal({
            text: res.response.data.message,
            icon: "warning",
          });
        } else {
          window.location.reload();
        }

        $("#btnLoginSubmit").prop("disabled", false);
        removeCookie("KAPPU");
        removeCookie("KAPPP");
        // window.location.reload();
        // setDisabled(false);
      });

    function LoadCTHrms(e) {
      debugger;

      setCookie("FirstroleCountLogin", 0, { path: "/" });

      var userNameObj = cookies.KuuuDecript;

      var PasswordObj = cookies.KpppDecript;

      var ishrmsObj = 1;

      var url = process.env.CT_HRMS_APP;

      var url = URLHR.geturl();

      var HRMSURL =
        url +
        "Login/HRRecumentIndex?UserName=" +
        userNameObj +
        "&Password=" +
        PasswordObj +
        "&ishrms=" +
        ishrmsObj;

      window.location.href = HRMSURL;

      // window.reload(HRMSURL);
    }
    function RedirectFunction(RoleName) {
      debugger;
      navigate("/Index");
      // switch (RoleName) {
      //   case "hod":
      //     navigate("/Associate", { replace: true });
      //     ////$('.main-loader').hide();
      //     break;
      //   case "engineer":
      //     navigate("/Associate", { replace: true });
      //     ////$('.main-loader').hide();
      //     break;
      //   case "admin":
      //     navigate("/Associate", { replace: true });
      //     ////$('.main-loader').hide();
      //     break;
      //   case "md":
      //     navigate("/Associate", { replace: true });
      //     ////$('.main-loader').hide();
      //     break;
      //   default:
      //     navigate("/Associate", { replace: true });
      //     ////$('.main-loader').hide();
      //     break;
      // }
    }
  };
  function ROleOnchange(e) {
    setEmployeeModel((prevState) => ({
      ...prevState,
      ["roleSeletedList"]: e,
    }));
  }
  const getApplicationName = () => {
    const hostname = window.location.hostname;

    if (hostname.includes("localhost")) {
      return "AMM Foundation - Dev";
    } else if (hostname.includes("qa")) {
      return "AMM Foundation - QA Env";
    } else if (hostname.includes("uat")) {
      return "AMM Foundation - UAT Env";
    } else {
      return "AMM Foundation"; 
    }
  };

  return (
    <>
      <div className="main-loader" style={{ display: "none" }}>
        <div className="large-indicator">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <ul className="slideshow">
        <li>
          <span></span>
        </li>
        <li>
          <span>2</span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
      </ul>

      <div>
        <div
          className="loaders-containers"
          style={{
            background: "#f9fbfd",
            width: "100%",
            height: "100vh",
            position: "fixed",
            zIndex: "1",
            display: "none",
          }}
        >
          <span
            className="loadercube"
            style={{ position: "fixed", right: "50%", bottom: "50%" }}
          ></span>
        </div>
        <div className="wrapper sidebar_minimize login">
          <div className="container d-flex justify-content-center">
            <div className="d-flex flex-column justify-content-between">
              <div className="card mt-3 p-5 Top_Card">
                <img
                  src={logo}
                  style={{
                    marginTop: "-25px",
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "10px",
                    height: "150px",
                    width: "150px",
                    margin: "auto",
                  }}
                />

                <div>
                  <p
                    className="mb-3 pt-1 loginclass"
                    style={{
                      marginBottom: "-0.75rem !important",
                      fontFamily: "Poppins",

                      fontSize: "25px",
                      display: "flex",
                      justifyContent: "center",
                      color: "white",
                    }}
                  >
                   {getApplicationName()}
                  </p>
                  {/* <h4 className="mb-5 text-white"></h4> */}
                </div>
              </div>
              <form onSubmit={loginSubmit}>
                <div className="card two bg-white px-5 py-4 mb-3">
                  <div className="form-group">
                    <label
                      className="form-label"
                      id="Lblname"
                      style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                    >
                      Username
                    </label>

                    <input
                      type="text"
                      name="UserName"
                      id="Username"
                      maxLength={10}
                      style={{ textTransform: "uppercase" }}
                      className="form-control"
                      {...register("UserName", {
                        required: "Employee Code is Required",
                      })}
                      value={register.UserName}
                      onChange={getRole}
                    />
                    <span style={{ color: "red" }} id="errUsername">
                      {dummy.errUserName}
                    </span>
                  </div>
                  <div className="form-group">
                    <label
                      name="Lblpassword"
                      className="form-label"
                      id="Lblpassword"
                      style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                    >
                      Password
                    </label>
                    <div className="row">
                      <div className="col-md-12">
                        <input
                          type="password"
                          className="form-control"
                          name="Password"
                          id="Password"
                          // style={{ textTransform: "uppercase" }}
                          {...register("Password", {
                            required: "Password is Required",
                          })}
                          value={register.Password}
                          onKeyDown={(event) => onKeyDown(event)}
                        />
                        <i
                          className="fa fa-eye-slash"
                          id="pswicon"
                          onClick={myEyeFunction}
                          style={{
                            float: "right",
                            marginRight: "10px",
                            marginTop: "-28px",
                            position: "relative",
                            zIndex: 2,
                          }}
                        ></i>
                      </div>
                      {/* <div className="col-md-2">
                      <button
                        className="btn pswhide eyeLogin"
                        type="button"
                        onClick={myEyeFunction}
                      >
                        <i className="fa fa-eye" id="pswicon"></i>
                      </button>
                    </div> */}
                      <div className="col-md-12">
                        <span style={{ color: "red" }} id="errPassword">
                          {dummy.errPassword}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* <div className="form-group">
                  <label
                    className="form-label"
                    id="Lblname"
                    style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                  >
                    Role
                  </label>
                  <select
                    id="Roleselect"
                    className="form-control"
                    name="Roleselect"
                    value={EmployeeModel.roleSeletedList}
                  >
                    {RoleList != null ? (
                      RoleList.map((item) => {
                        debugger;
                        return <option value={item.value}>{item.label}</option>;
                      })
                    ) : (
                      <option value="0">ljkhg </option>
                    )}
                  </select>

                  <span style={{ color: "red" }}>{dummy.errRoleID}</span>
                </div> */}
                  <div
                    className="form-group text-left  px-2 ml-3"
                    style={{ marginBottom: "0 !important" }}
                  >
                    <input
                      className="form-check-input mr-2"
                      type="checkbox"
                      style={{ color: "black" }}
                      value="true"
                      name="RememberMeflag"
                      id="RememberFlagCkboxs"
                    />
                    <label
                      className="form-check-label fw-500"
                      for="RememberFlagCkbox"
                      id="remembermeflag"
                      style={{ color: "black" }}
                    >
                      Remember me
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        // disabled={disabled}
                        type="submit"
                        id="btnLoginSubmit"
                        className={
                          disabled
                            ? "form-control btn btn-default rounded submit px-3"
                            : "form-control btn btn-primary rounded submit px-3"
                        }
                        style={{ marginBottom: "0" }}
                      >
                        Sign In
                      </button>
                      <a
                        href="/Username"
                        className="btn btn-link box-shadow-0 px-3 forgotpass"
                      >
                        Forgot Password?
                      </a>
                    </div>
                    <div className="col-12"></div>
                  </div>
                </div>
              </form>
              <div>
                <p className="" style={{ textAlign: "center" }}>
                  &copy; Copyright {currentYear}. All rights reserved.
                </p>
              </div>
            </div>

            <a href="#" data-click="scroll-top" className="btn-scroll-top fade">
              <i className="fa fa-arrow-up"></i>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
export default Login;
