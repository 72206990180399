import Axios from "./AxiosService";

const axios = new Axios();

export default class RoleConfigurationService {
  GetConfigurationData() {
    return axios.get("Settings/GetConfigurationData");
  }
  SaveEmailConfiguration(data) {

    return axios.post("Settings/SaveEmailConfiguration", data);
  }
  SaveSharedFolder(data) {
    return axios.post("Settings/SaveSharedFolder", data);
  }
  SaveSLAConfiguration(data) {
    return axios.post("Settings/SaveSLAConfiguration", data);
  }
  GetprivileageUsingRoleId() {
    return axios.get("Settings/SaveSLAConfiguration");
  }
  Create_Role(roleID) {
    return axios.get("Role/Create_Role?roleID=" + roleID);
  }
  Register(data) {
    return axios.post("Role/Register", data);
  }
  RoleList() {
    return axios.get("Role/RoleList");
  }
  DeleteRole(roleID, empid) {
    return axios.get(`Role/DeleteRole?roleId=${roleID}&employeeId=${empid}`)
  }


  KPIConfig_GetKpiConfigDetails(data) {
    return axios.get(`Settings/GetKPIList?year=${data}`);
  }

  KPIConfig_SaveKPI(data) {
    return axios.post("Settings/SaveKPI", data);
  }

  KPIConfig_GetEmployee(data) {
    return axios.get(`Settings/GetEmployee?DeptId=${data}`)
  }
  KPIConfig_KPIApprove(data, flag, year) {
    return axios.get(`Settings/KPIApprove?KPIID=${data}&flag=${flag}&year=${year}`)
  }
  KPIConfig_KPITargetSave(data) {
    return axios.post("Settings/UploadKPIstring", data)
  }

  KPIConfig_CheckKPIName(KPIID, data) {
    return axios.get(`Settings/CheckKpiName?KPIID=${KPIID}&KPIName=${data}`)
  }
  KPIConfig_DeleteKPI(KPIID, EmpId) {
    return axios.get(`Settings/DeleteKPI?KPIID=${KPIID}&EmpId=${EmpId}`)
  }

  Department_GetDeptList() {
    debugger
    return axios.get(`Settings/GetDeptList`);
  }
  Department_SaveDept(data) {
    return axios.post("Settings/SaveDept", data);
  }
  Department_DeleteDept(DeptId, EmpId) {
    return axios.get(`Settings/DeleteDept?DeptId=${DeptId}&EmpId=${EmpId}`)
  }

  Notification_GetNotificationById(EmpId) {
    return axios.get(`Settings/GetNotification?EmpId=${EmpId}`)
  }
  Notification_ClearCnt(EmpId) {
    return axios.get(`Settings/ClearCnt?EmpId=${EmpId}`)
  }

  Notification_GetCnt(EmpId) {
    return axios.get(`Settings/GetCnt?EmpId=${EmpId}`)
  }
  Notification_ClearAll(EmpId) {
    return axios.get(`Settings/ClearAll?EmpId=${EmpId}`)
  }

  PDSA_Notification(kpitype, year, month, kpiid, deptid, EmpID) {
    debugger
    return axios.get(`/Settings/PDSA_Notification?KPIType=${kpitype}&&year=${year}&&month=${month}&&KPiID=${kpiid}&&deptID=${deptid == undefined ? 0 : deptid
      }&&EmpID=${EmpID == undefined ? 0 : EmpID}`)
  }

  IsApplicableKPI(KPIID, Yr,IsApplicable ) {
    return axios.get(`Settings/IsApplicableKPI?KPIID=${KPIID}&year=${Yr}&IsApplicable=${IsApplicable}`)
  }
}