import AxiosServices from "./AxiosService";

const axios = new AxiosServices();

class NotificationService {
    Notification_GetNotificationById(EmpId) {

        return axios.get(`Report/GetNotification?EmpId=${EmpId}`)
    }
    Notification_ClearCnt(NotificationID) {

        return axios.get(`Report/Notification_Read?NotificationID=${NotificationID}`)
    }

    Notification_GetCnt(EmpId) {

        return axios.get(`Report/GetNotificationCount?EmpId=${EmpId}`)
    }
    Notification_ClearAll(EmpId) {

        return axios.get(`Report/Notification_ClearAll?EmpId=${EmpId}`)
    }
}

export default NotificationService