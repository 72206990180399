import React from 'react'
import { toast } from 'react-toastify';

const Toast = (message, type) => {
    switch (type) {
      case "success":
        return toast.success(
          message , { autoClose: 1000 }
        );
      case "error":
        return toast.error(
          message
          , { autoClose: 2000 }
        );
      case "warning":
        return toast.warning(
          message
          , { autoClose: 3000 }
        );
      default:
        return toast.warning(
          message
          , { autoClose: 2000 }
        );
    }
  };
  export default Toast;