import React, { useState, useContext } from "react";
import $ from "jquery";
import logo from "../../assets/Final-logo.png";
import { AuthContext } from "../../Context/AuthContext";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import AuthenticationService from "../../api/AuthenticationService";
import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Forgotpassword = () => {
  const [disabled, setDisabled] = useState(false);
  const { data, setCookie, cookies } = useContext(AuthContext);

  const location = useLocation();
   
  const[username,setuserName]=useState(location.state && location.state.UserName)
  const AuthObject = new AuthenticationService();
  const currentYear = new Date().getFullYear();
  const navigate = useNavigate();

  const [dummy, setDummy] = useState({
    prefixName: "",
    endValue: "",

    errNewPassword: "",
    errConfirmPassword: "",
  });
  useEffect(()=>{
    toast.success("OTP Verified Successfully", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  },[])
  const myEyeFunction = () => {
    var x = document.getElementById("Password");
    if (x.type === "password") {
      x.type = "text";
      $("#pswicon").removeClass("fa-eye-slash");
      $("#pswicon").addClass("fa-eye");
    } else {
      $("#pswicon").removeClass("fa-eye");
      $("#pswicon").addClass("fa-eye-slash");
      x.type = "password";
    }
  };
  const myConformEyeFunction = () => {
    var x = document.getElementById("Confirmpassword");
    if (x.type === "password") {
      x.type = "text";
      $("#pswconicon").removeClass("fa-eye-slash");
      $("#pswconicon").addClass("fa-eye");
    } else {
      $("#pswconicon").removeClass("fa-eye");
      $("#pswconicon").addClass("fa-eye-slash");
      x.type = "password";
    }
  };
  const Resetsubmit = (e) => {
    debugger;
    e.preventDefault();
    console.log(e.target);
    var data = {
      Username: username,
      NewPassword: e.target.Password.value,
      ConfirmPassword: e.target.Confirmpassword.value,
    };

    if (data.NewPassword == null || data.NewPassword == "") {
      setDummy({ errNewPassword: "Enter new password" });

      return false;
    }

    if (data.NewPassword != null || data.NewPassword != "") {
      setDummy({ errNewPassword: "" });
    }

    if (data.ConfirmPassword == null || data.ConfirmPassword == "") {
      setDummy({ errConfirmPassword: "Enter confirm password" });

      return false;
    }

    if (data.ConfirmPassword != null || data.ConfirmPassword != "") {
      setDummy({ errConfirmPassword: "" });
    }

    if (
      data.ConfirmPassword != data.NewPassword ||
      data.ConfirmPassword != data.NewPassword
    ) {
      setDummy({ errConfirmPassword: "Password is not matched" });
      return false;
    }

    if (
      data.ConfirmPassword == data.NewPassword ||
      data.ConfirmPassword == data.NewPassword
    ) {
      setDummy({ errConfirmPassword: "" });
    }

    valiedResetSubmit(data);
  };
  const valiedResetSubmit = (data) => {
    AuthObject.resetpassword(data)
      .then((res) => {
        debugger;
        setDisabled(false);
        toast.success("Password Reset Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
        
      })
      .catch((error) => {
        setDisabled(false);
        toast.error("Something went wrong. Please try again");
        navigate("/");
      });
  };
  
  return (
    <>
        <ToastContainer />
        <div className="main-loader" style={{ display: "none" }}>
        <div className="large-indicator">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <ul className="slideshow">
        <li>
          <span></span>
        </li>
        <li>
          <span>2</span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
      </ul>

      <div>
      <div class="wrapper sidebar_minimize login">
        <div class="container d-flex justify-content-center">
          <div class="d-flex flex-column justify-content-between">
            <form onSubmit={Resetsubmit}>
              <div class="card mt-3 p-5 Top_Card">
                <img
                  src={logo}
                  style={{
                    marginTop: "-25px",
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "10px",
                    height: "150px",
                    width: "150px",
                    margin: "auto",
                  }}
                />

                <div>
                  <p
                    class="mb-3 pt-1"
                    style={{
                      marginBottom: "-0.75rem !important",
                      fontFamily: "inherit",
                      fontSize: "25px",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    Reset Password
                  </p>
                  {/* <h4 class="mb-5 text-white"></h4> */}
                </div>
              </div>
              <div class="card two bg-white px-5 py-4 mb-3">
                <div class="form-group">
                  <label
                    class="form-label"
                    id="Lblname"
                    style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                  >
                    New Password
                  </label>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="password"
                        name="Password"
                        id="Password"
                        class="form-control"
                      />
                      <i
                        className="fa fa-eye-slash"
                        id="pswicon"
                        onClick={myEyeFunction}
                        style={{
                          float: "right",
                          marginRight: "10px",
                          marginTop: "-28px",
                          position: "relative",
                          zIndex: 2,
                        }}
                      ></i>
                    </div>
                    {/* <div className="col-md-2">
                      <button
                        className="btn pswhide eyeLogin"
                        type="button"
                        onClick={myEyeFunction}
                      >
                        <i className="fa fa-eye" id="pswicon"></i>
                      </button>
                    </div> */}
                  </div>

                  <span style={{ color: "red" }}>{dummy.errNewPassword}</span>
                </div>
                <div class="form-group">
                  <label
                    class="form-label"
                    id="Lblname"
                    style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                  >
                    Confirm Password
                  </label>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="password"
                        name="Confirmpassword"
                        id="Confirmpassword"
                        class="form-control"
                      />
                      <i className="fa fa-eye-slash" id="pswconicon"
                        onClick={myConformEyeFunction}
                        style={{
                          float: "right",
                          marginRight: "10px",
                          marginTop: "-28px",
                          position: "relative",
                          zIndex: 2,
                        }}
                        ></i>
                    </div>
                    {/* <div className="col-md-2">
                      <button
                        className="btn pswhide eyeLogin"
                        type="button"
                        onClick={myConformEyeFunction}
                      >
                        <i className="fa fa-eye" id="pswconicon"></i>
                      </button>
                    </div> */}
                  </div>
                  <span style={{ color: "red" }}>
                    {dummy.errConfirmPassword}
                  </span>
                </div>
                <div class="row">
                  <div class="col-12">
                    <button
                      disabled={disabled}
                      type="submit"
                      className={
                        "form-control btn btn-primary rounded submit px-3"
                      }
                    >
                      Submit
                    </button>
                  </div>
                  <div class="col-12">
                    {/* <!-- <button type="submit" class="btn btn-gray btn-lg d-block w-100 fw-500 mb-3">Cancel</button> --> */}
                    <a
                      href="/"
                      class="btn btn-danger btn-lg d-block w-100 fw-500 mb-3"
                    >
                      Cancel
                    </a>
                  </div>
                </div>
              </div>

              <div>
                <p class="" style={{ textAlign: "center" }}>
                  &copy; Copyright {currentYear}. All rights reserved.
                </p>
              </div>
            </form>
          </div>
          <a href="#" data-click="scroll-top" class="btn-scroll-top fade">
            <i class="fa fa-arrow-up"></i>
          </a>
        </div>
      </div>
      </div>
    </>
  );
};
