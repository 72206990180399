import React, { useEffect, useState, useContext } from "react";
import Request from "../../assets/img/Dashboard/Requested.png";
import Pending from "../../assets/img/Dashboard/Pending.png";
import Credited from "../../assets/img/Dashboard/Credited.png";
import Rejected from "../../assets/img/Dashboard/Rejected.png";
import $, { data } from "jquery";
import ApprovalService from "../../api/ApprovalService";
import MUIDataTable from "mui-datatables";
import { AuthContext } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";


const _ApprovalService = new ApprovalService();
function Index() {
  const { cookies, logout, removeCookie, setCookie } = useContext(AuthContext);

  const [isfirst, SetIsFirst] = useState(0);
  const [requestedCount, setrequestedCount] = useState("");
  const [pendingCount, SetpendingCount] = useState("");
  const [creditedCount, setcreditedCount] = useState("");
  const [rejectedCount, setrejectedCount] = useState("");
  const [title, settitle] = useState("");


  const [TotalActiveStudents, setTotalActiveStudents] = useState("");
  const [TotalBudgetallocated, setTotalBudgetallocated] = useState("");
  const [TotalCreditedamount, setTotalCreditedamount] = useState("");
  const [BalanceBudgetamount, setBalanceBudgetamount] = useState("");

  const [Month, setMonth] = useState(0);
  const [Year, setYear] = useState(0);
  const [listvalues, Setlistvalues] = useState([]);
  const [selectedMont, setSelectedMonth] = useState(0);
  const [code, SetCode] = useState(20);
  const navigate = useNavigate();

  var monthList = [
    { number: 0, name: "ALL" },
    { number: 1, name: "Jan" },
    { number: 2, name: "Feb" },
    { number: 3, name: "Mar" },
    { number: 4, name: "Apr" },
    { number: 5, name: "May" },
    { number: 6, name: "Jun" },
    { number: 7, name: "July" },
    { number: 8, name: "Aug" },
    { number: 9, name: "Sep" },
    { number: 10, name: "Oct" },
    { number: 11, name: "Nov" },
    { number: 12, name: "Dec" },
  ];


  function yearchange(e) {
    setYear(e.target.value);
  }
  function Monthchange(e) {
    debugger;
    setMonth(e.target.value);
    setSelectedMonth(e.target.value);
    SetIsFirst(1);
  }




  useEffect(() => {
    window.scrollTo(0, 0);
    $(".submenu").removeClass("active");
    $("#Dashboard").addClass("active");

    $(".collapse").removeClass("show");
    $("#Dashboard").addClass("show");

    $(".submenu li").removeClass("active");
    debugger;

    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth() + 1;

    document.getElementById("ReportYear").innerHTML = "";
    var selectYearElement = document.getElementById("ReportYear");
    var optionAllElement = document.createElement("option");
    optionAllElement.value = 0;
    optionAllElement.textContent = "ALL";
    selectYearElement.appendChild(optionAllElement);

    for (var i = 0; i < 3; i++) {
      var optionYearElement = document.createElement("option");
      optionYearElement.value = currentYear - i;
      optionYearElement.textContent = currentYear - i;
      selectYearElement.appendChild(optionYearElement);
    }

    var Id = 0;
    if (cookies.roleName === "Student") {
      Id = cookies.userID;
    }

    // function formatIndianNumber(number) {
    //   var x=12345678;
    //   x=x.toString();
    //   var lastThree = x.substring(x.length-3);
    //   var otherNumbers = x.substring(0,x.length-3);
    //   if(otherNumbers != '')
    //       lastThree = ',' + lastThree;
    //   var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    //   return res;
    // }
    $(".loader-container").show();
    _ApprovalService
      .DashboardData(code, selectedMont, Year, Id)
      .then((res) => {
        debugger
        $(".loader-container").hide();
        const data = res.data;
        setrejectedCount(data.rejected);
        //setrequestedCount(data.requested);
        setcreditedCount(data.credited);
        SetpendingCount(data.pending);
        settitle("Overall Ticket Details")


        // let totalBudgetallocated = formatIndianNumber(data.totalBudgetallocated);
        // let totalCreditedamount = formatIndianNumber(data.totalCreditedamount);
        // let balanceBudgetamount = formatIndianNumber(data.balanceBudgetamount.substring(1);

        // setTotalActiveStudents(data.totalActiveStudents);
        // setTotalBudgetallocated(data.totalBudgetallocated);
        // setTotalCreditedamount(data.totalCreditedamount);
        // setBalanceBudgetamount(data.balanceBudgetamount);

        if (data.list != null || data.list.length > 0) {
          if (cookies.roleName == 'Project Coordinator' || cookies.roleName == 'AMMF Admin' || cookies.roleName == 'Student') {
            const filterData = data.list.filter(
              request => request.status === 'Requested'
            );
            console.log(filterData.length);
            setrequestedCount(filterData.length)

          }
          else if (cookies.roleName == 'Head - Projects' || cookies.roleName == 'Head Projects') {
            const filterData = data.list.filter(
              request => request.status === 'Project Coordinator Approved'
            );
            setrequestedCount(filterData.length)
          }
          else if (cookies.roleName == 'Accountant') {
            const filterData = data.list.filter(
              request => request.status === 'SAVP CE Approved' || request.status === 'Head - Projects Approved'
            );
            setrequestedCount(filterData.length)
          }
          else if (cookies.roleName == 'Head Finance') {
            const filterData = data.list.filter(
              request => request.status === 'Team Accounts Approved'
            );
            setrequestedCount(filterData.length)
          }
          else if (cookies.roleName == 'SAVP CE') {
            const filterData = data.list.filter(
              request => request.status === 'Head Finance Approved' || request.status === 'Head Finance Approved'
            );
            setrequestedCount(filterData.length)
          }
          Setlistvalues(data.list);
        }
      })
      .catch((res) => { });
    var selectMonthElement = document.getElementById("ReportMonth");
    selectMonthElement.innerHTML = "";
    for (var i = 0; i < monthList.length; i++) {
      var optionMonthElement = document.createElement("option");
      optionMonthElement.value = monthList[i].number;
      optionMonthElement.textContent = monthList[i].name;

      if (currentYear === +Year) {
        if (monthList[i].number > currentMonth) {
          optionMonthElement.disabled = true;
        } else if (monthList[i].number === currentMonth) {
          optionMonthElement.selected = true;
        }
      }

      selectMonthElement.appendChild(optionMonthElement);
      if (isfirst == 1) {
        document.getElementById("ReportMonth").value = selectedMont;
      }
    }
  }, [Month, Year]);

  const columns = [
    {
      name: "ticketNumber",
      label: "Ticket No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "studentName",
      label: "Student Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "joiningDate",
      label: "Batch No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "fundType",
      label: "Fund Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ticketDetails",
      label: "Ticket Details",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "actualAmount",
      label: "Credited Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "billNumber",
      label: "Bill Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdDate",
      label: "Requested Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "isSubmit",
      label: "IsSubmit",
      options: {
        display: false,
      },
    },
  ];


  function NavigatePage() {
    if (cookies.roleName !== 'AMMF Admin' && cookies.roleName !== 'Student') {
      navigate("/ApprovalGrid");
    }
  }


  const changevalue = (val) => {
    var Id = 0;
    if (cookies.roleName === "Student") {
      Id = cookies.userID;
    }
    if (val == 20) {
      settitle("Requested Ticket Details")
    }
    else if (val == 10) {
      settitle("Pending Ticket Details")
    }
    else if (val == 5) {
      settitle("Credited Ticket Details")
    }
    else if (val == 0) {
      settitle("Overall Ticket Details")
    } else {
      settitle("Rejected Ticket Details")
    }


    _ApprovalService
      .DashboardData(val, selectedMont, Year, Id)
      .then((res) => {
        debugger
        const data = res.data;
        setrejectedCount(data.rejected);
        setrequestedCount(requestedCount);
        setcreditedCount(data.credited);
        SetpendingCount(data.pending);

        if (data.list != null || data.list.length > 0) {
          
          // if (cookies.roleName == 'Project Coordinator' || cookies.roleName == 'AMMF Admin' || cookies.roleName == 'Student') {
          //   const filterData = data.list.filter(
          //     request => request.status === 'Requested'
          //   );
          //     console.log(filterData.length);
          //   setrequestedCount(filterData.length)
          // }
          // else if (cookies.roleName == 'Head - Projects' || cookies.roleName == 'Head Projects') {
          //   const filterData = data.list.filter(
          //     request => request.status === 'Project Coordinator Approved'
          //   );
          //   setrequestedCount(filterData.length)
          // }
          // else if (cookies.roleName == 'Accountant') {
          //   const filterData = data.list.filter(
          //     request => request.status === 'SAVP CE Approved' || request.status === 'Head - Projects Approved'
          //   );
          //   setrequestedCount(filterData.length)
          // }
          // else if (cookies.roleName == 'Head Finance') {
          //   const filterData = data.list.filter(
          //     request => request.status === 'Team Accounts Approved'
          //   );
          //   setrequestedCount(filterData.length)
          // }
          // else if (cookies.roleName == 'SAVP CE') {
          //   const filterData = data.list.filter(
          //     request => request.status === 'Head  Finance Approved' || request.status === 'Head Finance Approved'
          //   );
          //   setrequestedCount(filterData.length)
          // }
          Setlistvalues(data.list);
        }
      })
      .catch((res) => { });
  };
  return (
    <>
      <div class="main-panel">
        <div class="content">
          <div class="panel-header">
            <div class="page-inners" style={{ padding: "40px" }}>
              <div
                class=""
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <h2
                    class="card-title mr-auto"
                    style={{
                      fontWeight: "bold",
                      color: "green",
                    }}
                  >
                    {" "}
                    Dashboard
                  </h2>
                </div>
                <div className="d-flex">

                  <label className=" lbl font-weight-bold mr-2">Year </label>
                  <select
                    className="form-control mr-2 formwidth"
                    value={Year}
                    name="Year"
                    id={"ReportYear"}
                    onChange={(e) => yearchange(e)}
                  >

                  </select>
                  <label className=" lbl font-weight-bold mr-2">Month </label>
                  <select
                    className="form-control mr-2 formwidth"
                    defaultValue={Month}
                    // defaultValue={"September"}
                    name="Month"
                    id={"ReportMonth"}
                    onChange={(e) => Monthchange(e)}
                  >

                  </select>
                </div>
              </div>
            </div>
            {/* {cookies.roleName != "Student" ?
            <div class="page-inner mt--5">
              <div class="row row-card-no-pd mt-2">
                {cookies.roleName != "Student" ?
                <div class="col-sm-6 col-md-3">
                  <div class="card card-stats card-round">
                    <div class="card-body ">
                      <div class="row">
                      
                        <div class="col-12 col-stats dashcol">
                          <div class="numbers num">
                            
                            <i class="fa fa-user-graduate dashicon"></i>    
                            <h2  class="card-title dash">{TotalActiveStudents}</h2>
                            <p class="card-category">Total Active Students </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>:<div class="col-sm-6 col-md-3">
                  <div class="card card-stats card-round">
                    <div class="card-body ">
                      <div class="row">
                      
                        <div class="col-12 col-stats dashcol">
                          <div class="numbers num">
                           
                            <i class="fa fa-user-graduate dashicon"></i>    
                            <h2 class="card-title dash">{cookies.employeeName}</h2>
                            <p class="card-category">Student  </p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}
                <div class="col-sm-6 col-md-3">
                  <div class="card card-stats card-round">
                    <div class="card-body ">
                      <div class="row">
                      <div class="col-12 col-stats dashcol">
                          <div class="numbers num">
                          
                            <i class="fas fa-rupee-sign dashicon"></i>    
                            <h2 class="card-title dash">{TotalBudgetallocated}</h2>
                            <p class="card-category">Total Budget allocated </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="card card-stats card-round">
                    <div class="card-body">
                      <div class="row">
                      <div class="col-12 col-stats dashcol">
                          <div class="numbers num">
                          
                            <i class="fas fa-hands-helping dashicon"></i>     
                            <h2 class="card-title dash">{TotalCreditedamount}</h2>
                            <p class="card-category">Total Credited amount </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="card card-stats card-round">
                    <div class="card-body">
                      <div class="row">
                      <div class="col-12 col-stats dashcol">
                          <div class="numbers num">
                           
                            <i class="fas fa-piggy-bank dashicon"></i>
                            
                            <h2 class="card-title dash">    {BalanceBudgetamount}</h2>
                            <p class="card-category">Balance Budget amount </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> : <></>} */}
            <div class="page-inner mt--5">
              <div class="row row-card-no-pd mt-2">
                <div class="col-sm-6 col-md-3">
                  <div class="card card-stats card-round">
                    <div class="card-body " onClick={() => NavigatePage()}>
                      <div class="row" onClick={() => changevalue(cookies.roleName == 'AMMF Admin' ? 0 : 20)} style={{ cursor: "pointer" }}>
                        <div class="col-6">
                          <div class="icon-big text-center">
                            <img
                              src={Request}
                              alt="indonesia"
                              style={{ width: "50%" }}
                            />
                          </div>
                        </div>
                        <div class="col-6 col-stats">
                          <div class="numbers">
                            <p class="card-category">Requested </p>
                            <h4 class="card-title">{requestedCount}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="card card-stats card-round">
                    <div class="card-body ">
                      <div class="row" onClick={() => changevalue(10)} style={{ cursor: "pointer" }}>
                        <div class="col-6">
                          <div class="icon-big text-center">
                            <img
                              src={Pending}
                              alt="indonesia"
                              style={{ width: "50%" }}
                            />
                          </div>
                        </div>
                        <div class="col-6 col-stats">
                          <div class="numbers">
                            <p class="card-category">Pending</p>
                            <h4 class="card-title">{pendingCount}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="card card-stats card-round">
                    <div class="card-body">
                      <div class="row" onClick={() => changevalue(5)} style={{ cursor: "pointer" }}>
                        <div class="col-6">
                          <div class="icon-big text-center">
                            <img
                              src={Credited}
                              alt="indonesia"
                              style={{ width: "50%" }}
                            />
                          </div>
                        </div>
                        <div class="col-6 col-stats">
                          <div class="numbers">
                            <p class="card-category">Credited</p>
                            <h4 class="card-title">{creditedCount}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6 col-md-3">
                  <div class="card card-stats card-round">
                    <div class="card-body">
                      <div class="row" onClick={() => changevalue(-1)} style={{ cursor: "pointer" }}>
                        <div class="col-6">
                          <div class="icon-big text-center">
                            <img
                              src={Rejected}
                              alt="indonesia"
                              style={{ width: "50%" }}
                            />
                          </div>
                        </div>
                        <div class="col-6 col-stats">
                          <div class="numbers">
                            <p class="card-category">Rejected</p>
                            <h4 class="card-title">{rejectedCount}</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="page-inner mt--5">
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="table-responsive">
                        <MUIDataTable
                          data={listvalues}
                          title={title}
                          columns={columns}
                          options={{
                            responsive: true,
                            download: true,
                            print: true,
                            selectableRows: false,
                            filter: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Index;
