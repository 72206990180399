import React, { useState, useEffect, useContext, useReducer } from "react";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import AddIcon from "@mui/icons-material/Add";
// import Button from "@material-ui/core/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// import Dialog from "@material-ui/core/Dialog";
import FundRequest from "./FundRequest";
import { AuthContext } from "../../Context/AuthContext";
import ReimbursementService from "../../api/ReimbursementService";
import swal from "sweetalert";
import Toast from "../Toastify";
import $ from "jquery";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FundView from "./FundView";
import AdvanceFund from "./AdvanceBillupload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const RemObj = new ReimbursementService();

function FundRequestList(props) {
  const { cookies, logout, removeCookie, setCookie } = useContext(AuthContext);

  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [RequestList, SetRequestList] = useState([]);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [RequestId, SetRequestId] = useState(0);
  const [studentId, setStudentId] = useState(cookies.userID);

  useEffect(() => {
    $(".submenu").removeClass("active");
    $("#FundRequest").addClass("active");

    var Id = cookies.userID;
    $(".loader-container").show();
    window.scrollTo(0, 0);
    RemObj.Get_RequestList(Id)
      .then((res) => {
        debugger;
        if (res != null && res.data != null && res.data.length > 0) {
          const result = res.data;
          SetRequestList(result);
        }
        $(".loader-container").hide();
      })
      .catch((err) => {
        console.log(err);
      });

    setCookie("AdvanceBill", 0, { path: "/" });
  }, [GridRender, Render]);

  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  function HandleClick(id, studentId) {
    SetRequestId(id);
    setStudentId(studentId);
  }
  function refreshPage() {
    window.location.reload(false);
  }

  function Updateprofile() {
    Toast("Kindly update bank details before request!", "warning");
  }
  function Updateadvance() {
    debugger
    if (cookies.AdvanceBillRequest == 1) {
      Toast(
        "Advance bill in review stage Kindly wait for approval!",
        "warning"
      );
    } else {
      Toast("Kindly Upload your previous Advance bill!", "warning");
    }
  }
  function Delete_Request(id) {
    swal({
      title: "Are You Sure?",
      text: "Do you want to delete this request?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        RemObj.DeleteRequest(id)
          .then((res) => {
            Toast("Request deleted succesfully", "success");

            setGridRender();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }
  const columns = [
    {
      name: "ticketNumber",
      label: "Ticket No",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "type",
      label: "Fund Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "ticketDetails",
      label: "Ticket Details",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "billNumber",
      label: "Bill Number",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "billDate",
    //   label: "Bill Date",
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta) => {
    //       if (!value) {
    //         return ''; 
    //       }
    //       const date = new Date(value);
    //       const day = String(date.getDate()).padStart(2, '0');
    //       const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    //       const year = date.getFullYear();
    //       return `${day}-${month}-${year}`;
    //     }
    //   },
    // },
    {
      name: "createdDate",
      label: "Requested Date",
      options: {
        filter: true,
        sort: true,
       
      },
    },
    {
      name: "status",
      label: "Status",
      // options: {
      //   filter: true,
      //   sort: true,
      //   customBodyRender: (value) => {
      //     let statusText = "";
      //     let statusColor = "";

      //     if (value === -1) {
      //       statusText = "Rejected";
      //       statusColor = "red";
      //     } else if (value === 0) {
      //       statusText = "Draft";
      //       statusColor = "green";
      //     } else if (value === 1) {
      //       statusText = "Requested";
      //       statusColor = "green";
      //     } else if (value === 2) {
      //       statusText = "1st Level Approved";
      //       statusColor = "blue"; // You can choose your color for 1st level approved
      //     } else if (value === 3) {
      //       statusText = "2nd Level Approved";
      //       statusColor = "purple"; // You can choose your color for 2nd level approved
      //     } else if (value === 4) {
      //       statusText = "3rd Level Approved";
      //       statusColor = "orange"; // You can choose your color for 3rd level approved
      //     } else if (value === 5) {
      //       statusText = "Credited";
      //       statusColor = "green"; // You can choose your color for credited
      //     }

      //     return <span style={{ color: statusColor }}>{statusText}</span>;
      //   },
      // },
    },
    {
      name: "isSubmit",
      label: "studentId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "studentId",
      label: "studentId",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "advanceBillStatus",
      label: "AdvanceBillStatus",
      options: {
        display: "excluded",
        filter: false,
      },
    },
    {
      name: "ticketId",
      label: "Option",
      options: {
        filter: true,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (value, tableMeta) => {
          debugger
          const values = tableMeta.rowData[10];
          const type = tableMeta.rowData[1];
          const billstatus = tableMeta.rowData[9];
          const status = tableMeta.rowData[6];
          const isSubmit = tableMeta.rowData[7]; // Assuming isSubmit is at index 6
          const shouldShowIcons = isSubmit === 0 || isSubmit === -1;
          const studentId = cookies.userID;

          if (status == "Credited") {
            if (type == "Advance Request" && billstatus != "Approved") {
              setCookie("AdvanceBill", 1, { path: "/" });

              if (billstatus != null && billstatus != "Rejected") {
                setCookie("AdvanceBillRequest", 1, { path: "/" });
              } else {
                setCookie("AdvanceBillRequest", 0, { path: "/" });
              }
            }
          } else {
            setCookie("AdvanceBill", 0, { path: "/" });
            setCookie("AdvanceBillRequest", 0, { path: "/" });
          }

          return (
            <div className="center">
              {isSubmit == 0 || isSubmit == -1 ? (
                <div className="center">
                  <IconButton
                    aria-label="Edit"
                    data-bs-toggle="modal"
                    data-bs-target="#RequestModal"
                    title="Edit"
                    onClick={() => HandleClick(values, studentId)}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    title="Delete"
                    onClick={() => Delete_Request(values)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ) : (
                <>
                  <IconButton
                    aria-label="View"
                    data-bs-toggle="modal"
                    data-bs-target="#RequestView"
                    title="View"
                    onClick={() => HandleClick(values, studentId)}
                  >
                    <VisibilityIcon />
                  </IconButton>

                  {billstatus != "Approved" &&
                  billstatus != "Requested" &&
                  type == "Advance Request" ? (
                    <IconButton
                      aria-label="View"
                      data-bs-toggle="modal"
                      data-bs-target="#AdvanceFundView"
                      title="Upload Bill"
                      onClick={() => HandleClick(values, studentId)}
                    >
                      <CloudUploadIcon />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          );
        },
      },
    },
  ];
  const HeaderElements = () => (
    <>
      <Tooltip title="Request Fund">
        {cookies.AdvanceBill == 1 ? (
          <label htmlFor="icon-button-file">
            <IconButton variant="primary" onClick={Updateadvance}>
              <AddIcon> </AddIcon>
            </IconButton>
          </label>
        ) : (
          <label htmlFor="icon-button-file">
            {cookies.isprofileComplete != 1 ? (
              <IconButton
                variant="primary"
                data-bs-toggle="modal"
                data-bs-target="#RequestModal"
              >
                <AddIcon> </AddIcon>
              </IconButton>
            ) : (
              <IconButton
                variant="primary"
                data-bs-toggle="modal"
                data-bs-target="#UpadteStudent"
                onClick={Updateprofile}
              >
                <AddIcon> </AddIcon>
              </IconButton>
            )}
          </label>
        )}
      </Tooltip>
    </>
  );

  return (
    <div class="main-panel">
      <div class="content">
        <div className="page-inner">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="table-responsive">
                    <MUIDataTable
                      data={RequestList}
                      title="Reimbursement Request List"
                      columns={columns}
                      options={{
                        responsive: true,
                        download: true,
                        print: true,
                        selectableRows: false,
                        filter: true,
                        viewColumns: true,
                        customToolbar: () => (
                          <>
                            <HeaderElements />
                          </>
                        ),
                      }}
                    />

                    <div>
                      <div
                        class="modal fade"
                        id="RequestModal"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                      >
                        <div class="modal-dialog modal-xl" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h3 class="modal-title">
                                <span class="fw-mediumbold">
                                  Scholarship Reimbursement Request{" "}
                                </span>
                              </h3>
                              <button
                                type="button"
                                class="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => refreshPage()}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <FundRequest
                                ID={RequestId}
                                StudentId={studentId}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        class="modal fade"
                        id="RequestView"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                      >
                        <div class="modal-dialog modal-xl" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h3 class="modal-title">
                                <span class="fw-mediumbold">
                                  Scholarship Reimbursement Request{" "}
                                </span>
                              </h3>
                              <button
                                type="button"
                                class="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => refreshPage()}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <FundView ID={RequestId} StudentId={studentId} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div
                        class="modal fade"
                        id="AdvanceFundView"
                        tabindex="-1"
                        role="dialog"
                        aria-hidden="true"
                        data-bs-backdrop="static"
                      >
                        <div class="modal-dialog modal-xl" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h3 class="modal-title">
                                <span class="fw-mediumbold">
                                  Upload Advance Bill{" "}
                                </span>
                              </h3>
                              <button
                                type="button"
                                class="close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => refreshPage()}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <AdvanceFund
                                ID={RequestId}
                                StudentId={studentId}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FundRequestList;
