import React, { useState, useReducer, useEffect, useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ApprovalService from "../../api/ApprovalService";
import $ from "jquery";
import Toast from "../Toastify";
import Axios from "axios";
import { saveAs } from "file-saver";
import ReimbursementService from "../../api/ReimbursementService";

const RemObj = new ReimbursementService();

const _ApprovalService = new ApprovalService();

const ActionPage = (props) => {
  const { ID, Status, StageId, StudentId,setApproveGridRender } = props;

  const { cookies, logout } = useContext(AuthContext);

  const [funTypeList, setFunTypeList] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [TicketCode, setTicket] = useState("");
  const [amount, setAmount] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [billDate,setBillDate]=useState("");
  const [FundDetail, setFundDetails] = useState("");
  const [TicketId, setTicketId] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [commentselectedFile, setSelectedcommentFile] = useState(null);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [filepath, Setfilepath] = useState("");
  const [fileerror, setfileerror] = useState("");
  const [downloadpath, setdownloadpath] = useState("");
  const [commet, setComment] = useState("");
  const [error, seterror] = useState("");
  const [StudentName, SetStudentName] = useState("");
  const [registerNo, setRegisterNo] = useState("");
  const [accomType, setAccomType] = useState("");
  const [course, setCourse] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [batchNo, setBatchNo] = useState("");
  const [StudentRequestList, SetStudentRequestList] = useState([]);
  const [employeeId, setemployeeId] = useState(cookies.userID);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [Commentlist, SetCommentlist] = useState([]);
  const [Comment, SetComment] = useState("");



  useEffect(() => {
    RemObj.CommentBox(ID)
      .then((res) => {
        if (res != null && res.data != null && res.data.length > 0) {
          const result = res.data;
          SetCommentlist(result);
        }
        $(".loader-container").hide();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ID, Commentlist, Render]);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
  
    return `${day}-${month}-${year}`;
  }

  useEffect(() => {
    var roleId = cookies.roleID;
    if (ID > 0) {
      _ApprovalService
        .Get_RequestById(ID, roleId, 0)
        .then((response) => {
          const data = response.data;

          debugger;
          if (
            data &&
            data.approvalRequests &&
            data.approvalRequests.length > 0
          ) {
            const firstTicket = data.approvalRequests[0];
            setTicket(firstTicket.ticketNumber);
            // setSelectedTicket({
            //   value: firstTicket.fundType,
            //   label: firstTicket.type,
            // });
            setSelectedTicket(firstTicket.type);
            setAmount(firstTicket.amount);
            setBillNumber(firstTicket.billNumber);
            if (firstTicket?.billDate) {
              setBillDate(formatDate(firstTicket.billDate));
            } else {
              setBillDate("");
            }
            
            setFundDetails(firstTicket.ticketDetails);
            Setfilepath(firstTicket.fileName);
            // setdownloadpath(firstTicket.downloadPath);
            setdownloadpath(
              Axios.defaults.baseURL + "/Uploadfiles/" + firstTicket.fileName
            );
            SetStudentName(firstTicket.studentName);
            setRegisterNo(firstTicket.registerNo);
            setAccomType(firstTicket.accommodationType);
            setCourse(firstTicket.course);
            setSpecialization(firstTicket.specialization);
            setCollegeName(firstTicket.collegeName);
            setBatchNo(firstTicket.batchNo);

            console.log("Fetched ticket data:", firstTicket);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    debugger;
    if (StudentId > 0) {
      _ApprovalService
        .Student_CreditedInfo(StudentId)
        .then((res) => {
          if (res != null && res.data != null && res.data.length > 0) {
            const result = res.data;
            SetStudentRequestList(result);
          }
          $(".loader-container").hide();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ID]);

  const handleFileChange = (event) => {
    debugger;
    const file = event.target.files[0];
    if (validateFileExtension(file)) {
      setSelectedcommentFile(file);
    } else {
      setSelectedcommentFile(filepath);
      setfileerror(
        "Invalid file format. Please select a valid file.It allows .jpg, .jpeg, .png, .pdf"
      );
    }
  };

  const validateFileExtension = (file) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };

  function SendComment(id) {
    debugger;
    const formData = {
      CommetID: 0,
      TicketID: ID,
      Commnets: Comment,
      SavedBy: employeeId,
      UploadPath: commentselectedFile,
    };

    var flag = true;
    if (formData.Commnets == null || formData.Commnets == "") {
      if (id == 1) {
        Toast("Please fill the Commnet", "warning");
      }
      flag = false;
    }

    if (flag) {
      const Baseform = new FormData();

      Baseform.append("CommetID", formData.CommetID);
      Baseform.append("TicketID", formData.TicketID);
      Baseform.append("Commnets", formData.Commnets);
      Baseform.append("SavedBy", formData.SavedBy);
      Baseform.append("UploadPath", formData.UploadPath);
      SaveComment(Baseform);
    }
  }

  function SaveComment(Baseform) {
    RemObj.SaveCommentBox(Baseform)
      .then((response) => {
        $(".loader-container").hide();

        $("#Comment").val("");
        SetComment("");
        setSelectedcommentFile(null);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
        PageRender();
      })
      .catch((error) => {
        $(".loader-container").hide();
        console.error("Error while saving:", error);
      });
  }

  function downloadCommentpath(url) {
    RemObj.Downloadcomment(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }
  const HandleSubmit = () => {
    debugger
    var flag = false;
    if (Status !=1) {
      if (commet == null || commet == "") {
        seterror("Please enter your remarks");
        flag = true;
      }
    }

    if (flag == false) {

      const requestData = {

        TicketId: ID,
        // ApproverStageId: StageId,
        ApproverStageId: 0,
        FirstLevelApprovedBy: cookies.userID,
        FirstLevelComment: commet,
        RoleName: cookies.roleName,
        IsApproved: Status,
        RoleId: cookies.roleID,
        IsClosed: Number(0),
        StudentId: StudentId,

      };

      $(".loader-container").show();

      _ApprovalService
        .SubmitRequest(requestData)
        .then((response) => {
          SendComment(0);
         

          if (ID == 0 && Status == 1) {
            Toast("Request Approved Successfully", "success");
          } else if (Status == 0) {
            Toast("Request Rejectd Successfully", "success");
          } else {
            Toast("Request Approved Successfully", "success");
          }
          $("#ApproveClose").trigger("click");
          $(".loader-container").hide();
          setApproveGridRender();
          // setTimeout(() => {
          //   window.location.reload();
          //   setGridRender();
          // }, 500);
          
         
          //$(".loader-container").hide();
        })
        .catch((error) => {
          $(".loader-container").hide();
          console.error("Error while saving:", error);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
    }
  };
  function download(url) {
    RemObj.Download(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }

  return (
    <div class="row">
      <div class="col-lg-7">
        <div class="row">
          <div class="col-lg-6 mb-2">
            <label class="text-label form-label">Request Number</label>
            <input
              type="text"
              name="RegNo"
              class="form-control"
              placeholder=""
              value={TicketCode}
              disabled={true}
            />
          </div>
          <div className="col-lg-6 mb-2">
            <label class="text-label form-label">Student Name &nbsp;<span data-toggle="modal" data-target="#studentModal"><i className="fa fa-eye"></i></span> </label>
            <input
              type="text"
              name="RegNo"
              className="form-control"
              placeholder=""
              value={StudentName}
              disabled={true}
            />
          </div>
          {/*  Modal component */}
          <div className="modal " id="studentModal" tabIndex="-1" role="dialog" aria-labelledby="studentModalTitle" aria-hidden="true"  >
            <div className="modal-dialog " role="document">
            <div className="modal-content studentinfomodal" >
                
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span className="btnclose" aria-hidden="true">&times;</span>
  
                    </button>
                  <div className="modal-body">
                    {[
                      { label: 'Student Name', value: StudentName },
                      { label: 'Register Number', value: registerNo },
                      { label: 'Course', value: course },
                      { label: 'Specialization', value: specialization },
                      { label: 'College Name', value: collegeName },
                      { label: 'Batch No', value: batchNo },
                      { label: 'Accommodation Type', value: accomType },
                    ].map((item, index) => (
                      <div className="row mb-2" key={index}>
                        <div className="col-5">
                          <b>{item.label}</b>
                        </div>
                        <div className="col-1 text-center">
                          :
                        </div>
                        <div className="col-6">
                          {item.value}
                        </div>
                      </div>
                    ))}
                  </div>
  
  
                </div>
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">Fund Type</label>
            <input
              type="text"
              name="RegNo"
              class="form-control"
              disabled={true}
              value={selectedTicket}
            />
          </div>

          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">Amount</label>
            <input
              type="text"
              name="Amount"
              class="form-control"
              value={amount}
              disabled={true}
            />
          </div>
                  {selectedTicket == "Reimbursement Request" && <>
          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">Bill Number</label>
            <input
              type="text"
              name="firstName"
              class="form-control"
              value={billNumber}
              disabled={true}
            />
          </div>
          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">Bill Date</label>
            <input
              type="text"
              name="billDate"
              class="form-control"
              value={billDate}
              disabled={true}
            />
          </div>
          </>}

          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">
              {selectedTicket == "Advance Request" ? downloadpath != null ? "View Reference / Proof" : "Reference / Proof" : filepath !== null ? "View Bill" : "Upload Bill"}
            </label>
            <div>
              {downloadpath != null ? (
                <span className="file-info">
                  {/* <a href={downloadpath} download>
                    <CloudDownloadIcon className="download-icon" />
                    <div>{filepath}</div>
                  </a> */}

                  <a onClick={() => download(filepath)}>
                    <CloudDownloadIcon className="download-icon" />
                    {/* <div>{filepath}</div> */}
                  </a>
                </span>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div class="col-lg-6 mb-3">
            <label class="text-label form-label">
              Fund Details<span class="text-danger">*</span>
            </label>
            <div>
              <textarea
                name="lastName"
                class="form-control"
                value={FundDetail}
                disabled={true}
              ></textarea>
            </div>
          </div>

          <div class="col-lg-6 mb-3">
            {Status === 1 ? (
              <div class="mb-3">
                <label class="text-label form-label">Approver Remarks</label>
                <div>
                  <textarea
                    name="firstLevelComment"
                    class="form-control"
                    placeholder="Enter your remarks"
                    value={commet}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                </div>
              </div>
            ) : (
              <div class="mb-3">
                <label class="text-label form-label">
                  Rejected Remarks <span class="text-danger">*</span>
                </label>
                <div>
                  <textarea
                    name="firstLevelComment"
                    class="form-control"
                    placeholder="Enter your remarks"
                    value={commet}
                    onChange={(e) => {
                      setComment(e.target.value);
                      seterror("");
                    }}
                  ></textarea>
                </div>
                <div class="invalid-feedback" style={{ display: "block" }}>
                  {error}
                </div>
              </div>
            )}
          </div>
          {Commentlist != null && StageId != 0 ? (
            <>
              {" "}
              <div className="col-lg-6 col-md-9 col-sm-12">
                <input type="hidden" name="ID" id="ID" />
                <div class="form-group">
                  <label>Comment</label>
                  <textarea
                    type="text"
                    class="form-control "
                    id="Comment"
                    onChange={(e) => SetComment(e.target.value)}
                    value={Comment}
                  >
                    {Comment}
                  </textarea>
                  <span style={{ color: "red" }} id="errValue"></span>
                </div>
              </div>
              <div className="col-lg-6 col-md-9 col-sm-12">
                <div class="form-group">
                  <label>Reference File</label>
                  <input
                    type="file"
                    name="UploadPath"
                    className="form-control"
                    accept=".jpg, .jpeg, .png, .pdf"
                    onChange={(e) => {
                      handleFileChange(e); // Call the existing handler
                      //setformError({ ...error, UploadPath: "" }); // Clear the error message
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="col-12 modal-footer no-bd">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="ApproveClose"
            >
              Cancel
            </button>

            {Commentlist != null && StageId != 0 ? (
              <button
                id="addRowButton"
                class="btn btn-primary float-right"
                onClick={() => SendComment(1)}
              >
                Send Comment
              </button>
            ) : (
              <></>
            )}
            <button
              class="btn btn-primary float-right"
              style={{ marginLeft: "10px" }}
              onClick={HandleSubmit}
            >
              {Status === 1 ? "Approve & Send Mail" : "Reject & Send Mail"}
            </button>
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12">
            {Commentlist != null && StageId != 0 ? (
              <div class="col-md-12">
                <p style={{ fontWeight: "bold" }}>Comments </p>
                <div>
                  {Commentlist.map((item, index) =>
                    employeeId == item.SavedBy ? (
                      <>
                        <div class="comment1">
                          <div class="commentlist1">
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#3e9ae6",
                              }}
                            >
                              <span>@</span>
                              {item.first_Name} -{" "}
                            </span>
                            <span
                              style={{ textAlign: "left", fontSize: "10px" }}
                            >
                              {item.createdDate}
                            </span>
                            <span style={{ fontSize: "14px" }}>
                              {" "}
                              : {item.commnets}
                            </span>
                            {item.fileName != null ? (
                              <span style={{ fontSize: "14px" }}>
                                {" "}
                                :{" "}
                                <a
                                  onClick={() =>
                                    downloadCommentpath(item.fileName)
                                  }
                                >
                                  <CloudDownloadIcon className="download-icon" />
                                  {/* <div>{filepath}</div> */}
                                </a>
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <hr />
                      </>
                    ) : (
                      <>
                        <div class="comment">
                          <div class="commentlist">
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "600",
                                color: "#3e9ae6",
                              }}
                            >
                              <span>@</span>
                              {item.first_Name} -{" "}
                            </span>
                            <span
                              style={{ textAlign: "left", fontSize: "10px" }}
                            >
                              {item.createdDate}
                            </span>
                            <span style={{ fontSize: "14px" }}>
                              {" "}
                              : {item.commnets}
                            </span>
                            {item.fileName != null ? (
                              <span style={{ fontSize: "14px" }}>
                                {" "}
                                :{" "}
                                <a
                                  onClick={() =>
                                    downloadCommentpath(item.fileName)
                                  }
                                >
                                  <CloudDownloadIcon className="download-icon" />
                                  {/* <div>{filepath}</div> */}
                                </a>
                              </span>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <hr />
                      </>
                    )
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div class="col-lg-5">
        {StudentRequestList != null && StudentRequestList.length > 0 ? (
          <div className="trans">
            {/* <p> <span style={{ fontWeight: "bold"}}>Allocated Amount :</span> {StudentRequestList[0].allocatedAmount}</p> */}
            <p>
              <span style={{ fontWeight: "bold" }}>
                Total Credited Amount :
              </span>{" "}
              {StudentRequestList[0].totalCreditedAmount}
            </p>
            {/* <p><span style={{ fontWeight: "bold"}}>Balance Amount :</span> {StudentRequestList[0].balanceAmount}</p> */}
          </div>
        ) : (
          <></>
        )}
        <div
          className={StudentRequestList.length > 10 ? "trans-scroll" : "trans"}
        >
          <h3 style={{ fontWeight: "bold", color: "#1da837" }}>
            {" "}
            Transaction History
          </h3>
          <table
            id="add-row"
            className="display table table-striped table-hover"
          >
            <thead>
              <tr>
                <th> Date</th>
                <th> Fund Type</th>
                <th> Amount</th>
                <th> View Bill</th>
                <th> View Advance Bill</th>
              </tr>
            </thead>
            <tbody>
              {StudentRequestList.map((item, index) => (
                <tr key={index}>
                  <td>{item.creditedDate}</td>
                  <td>{item.fundType}</td>
                  <td>{item.creditedAmount}</td>
                  <td>
                    {item.billUrl != null ? (
                      <a
                        target="_blank"
                        onClick={() => download(item.billUrl)}
                        title="Download"
                      >
                        <CloudDownloadIcon className="download-icon" />
                        {/* <div>{filepath}</div> */}
                      </a>
                    ) : (
                      <></>
                    )}
                  </td>
                  <td>
                    {item.uploadAdvanceBill != null ? (
                      <a
                        target="_blank"
                        onClick={() => download(item.uploadAdvanceBill)}
                        title="Download"
                      >
                        <CloudDownloadIcon className="download-icon" />
                        {/* <div>{filepath}</div> */}
                      </a>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default ActionPage;
