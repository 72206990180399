import React from "react";
import AxiosServices from "./AxiosService";

const Auth = new AxiosServices();


class AssociateService {

     getData = async () => {
          return Auth.get('/Employee/List')        
    };
    Create = async (EmpID) => {
     return Auth.get(`/Employee/Create?id=${EmpID}`)        
     };
     Register = async (data) => {
          return Auth.post(`/Employee/Register`,data)        
     };
     DeleteEmployee = async (EmpID) => {
          return Auth.get(`/Employee/DeleteEmployee?Id=${EmpID}`,)        
     };

}

export default AssociateService

