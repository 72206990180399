import { useNavigate } from "react-router";
import React, { useEffect, useState, useContext } from "react";
import { UserRoleConfig } from "./UserRoleConfig";
import $ from "jquery";
import RoleConfigurationService from "../../api/RoleconfigurationService";
import swal from "sweetalert";
import { useReducer } from "react";
import Toast from "../Toastify";
import { ToastContainer, toast } from "react-toastify";
import { AuthContext } from "../../Context/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import MUIDataTable from "mui-datatables";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
// import Button from "@material-ui/core/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
// import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// import CloseIcon from "@material-ui/icons/Close";

export const RoleConfig = (props) => {
  const ConfigurationServiceobj = new RoleConfigurationService();

  const { cookies, setCookie, removeCookie } = useContext(AuthContext);

  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);

  //  const [sessionRole, setPriviledge] = useState(cookies.roleName);
  //  console.log(sessionRole);
  const [employeeID, setEmployeeID] = useState(0);
  const [RoleData, SetRoleData] = useState([]);
  const [FilteredRoleData, SetFilteredRoleData] = useState([]);
  const [Refresh, SetRefresh] = useState(0);
  const [RoleID, setRoleID] = useState(0);
  const navigate = useNavigate();
  const [modelRefresh, setmodelRefresh] = useState(0);
  const onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const { onClose, value: valueProp, open, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  useEffect(() => {
    getRoleData();
  }, [GridRender]);

  const getRoleData = () => {
    $(".main-loader").show();

    $(".submenu").removeClass("active");
    $("#ConfigurationMenu").addClass("active");

    $(".collapse").removeClass("show");
    $("#Configuration").addClass("show");

    $(".submenu li").removeClass("active");

    $("#roleConf").addClass("active");

    ConfigurationServiceobj.RoleList()
      .then((response) => {
        SetRoleData(response.data);

        SetFilteredRoleData(response.data);
        $(".main-loader").hide();
      })
      .catch((error) => {
        $(".main-loader").hide();
      });
  };

  const EditRole = (val) => {
    setRoleID(val);
    setmodelRefresh(modelRefresh + 1);
  };

  function DeleteRole(val) {
    swal({
      title: "Are You Sure?",
      text: "Do You Want to Delete this Role?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        ConfigurationServiceobj.DeleteRole(val, employeeID)
          .then((res) => {
            Toast("Role Deleted Succesfully", "sucess");

            setGridRender();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  }

  function refreshPage() {
    window.location.reload(false);
  }

  const columns = [
    {
      name: "roleID",
      label: "roleID",
      options: {
        display: "none",
      },
    },
    { name: "roleName", label: "Role Name" },
    { name: "description", label: "Description" },
    // {
    //   label: "Action",
    //   name: "roleID",
    //   options: {
    //     filter: true,
    //     sort: false,
    //     empty: true,

    //     responsive: true,

    //     customBodyRender: (values, tableMeta) => {
    //       const value = tableMeta.rowData[2];
    //       return (
    //         <div className=" ico-sec">
    //           {/* <Tooltip title="Edit Role">

    //                             <IconButton aria-label="Edit" data-bs-toggle="modal" data-bs-target="#Role" onClick={() => EditRole(value)}>

    //                                 <EditIcon />

    //                             </IconButton>

    //                         </Tooltip> */}

    //           {/* <IconButton aria-label="Delete"
    //                           onClick={() => DeleteRole(value)}
    //                         >

    //                             <DeleteIcon />

    //                         </IconButton> */}
    //           {/* <TrashFill   /> */}
    //         </div>
    //       );
    //     },
    //   },
    // },
    {
      name: "OPTION",
      label: "Option",
      options: {
        filter: true,
        sort: false,
        empty: true,

        responsive: true,

        customBodyRender: (values, tableMeta) => {
          const value = tableMeta.rowData[0];
          return (
            <div className="center">
              <IconButton
                title="Edit"
                data-bs-toggle="modal"
                data-bs-target="#Add_Role"
                onClick={() => EditRole(value)}
              >
                <EditIcon />
              </IconButton>
              <IconButton title="Delete" onClick={() => DeleteRole(value)}>
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];

  function tablerefresh(id) {
    SetRefresh(Refresh + 1);
  }

  const HeaderElements = () => (
    <>
      <Tooltip title="Add Role">
        <label htmlFor="icon-button-file">
          <IconButton
            variant="primary"
            data-bs-toggle="modal"
            data-bs-target="#Add_Role"
          >
            <AddIcon> </AddIcon>
          </IconButton>
        </label>
      </Tooltip>
    </>
  );

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div>
        <div class="main-panel">
          <div class="content">
            <div className="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="table-responsive">
                        <MUIDataTable
                          data={RoleData}
                          title="Role"
                          columns={columns}
                          options={{
                            responsive: true,
                            download: true,
                            print: true,
                            selectableRows: false,
                            filter: true,
                            viewColumns: true,
                            customToolbar: () => (
                              <>
                                <HeaderElements />
                              </>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          {/* <!-- Modal --> */}
          <div
            class="modal fade"
            id="Add_Role"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
            data-backdrop="static"
          >
            <div class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h3 class="modal-title">
                    <span class="fw-mediumbold">Role Configuration</span>
                  </h3>
                  <button
                    type="button"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={refreshPage}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <UserRoleConfig roleID={RoleID} tablerefresh={tablerefresh} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
