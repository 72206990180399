import React, { useState, useReducer, useEffect, useContext } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ApprovalService from "../../api/ApprovalService";
import $ from "jquery";
import Toast from "../Toastify";
import { AuthContext } from "../../Context/AuthContext";
import Axios from "axios";
import { saveAs } from "file-saver";
import ReimbursementService from "../../api/ReimbursementService";
import FundChatBox from "./FundChatBox";

const RemObj = new ReimbursementService();
const _ApprovalService = new ApprovalService();
export default function AccountantActionView(props) {
  const { ID, Status, StageId, StudentId } = props;

  const { cookies, logout } = useContext(AuthContext);

  const [funTypeList, setFunTypeList] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [TicketCode, setTicket] = useState("");
  const [amount, setAmount] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [FundDetail, setFundDetails] = useState("");
  const [TicketId, setTicketId] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [GridRender, setGridRender] = useReducer((x) => x + 1, 0);
  const [filepath, Setfilepath] = useState("");
  const [fileerror, setfileerror] = useState("");
  const [downloadpath, setdownloadpath] = useState("");
  const [commet, setComment] = useState("");
  const [error, seterror] = useState("");
  const [FirstCmd, SetFirstCmd] = useState("");
  const [SecondCmd, SetSecondCmd] = useState("");
  const [ThirdCmd, SetThirdCmd] = useState("");
  const [PassBookUrl, SetPassBookUrl] = useState("");
  const [StudentName, SetStudentName] = useState("");
  const [BankAccNumber, SetBankAccNumber] = useState("");
  const [BankAccType, SetBankAccType] = useState("");
  const [registerNo, setRegisterNo] = useState("");
  const [accomType, setAccomType] = useState("");
  const [course, setCourse] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [collegeName, setCollegeName] = useState("");
  const [batchNo, setBatchNo] = useState("");
  const [BankBranch, SetBankBranch] = useState("");
  const [BankName, SetBankName] = useState("");
  const [IFSCCode, SetIFSCCode] = useState("");

  const [error1, seterror1] = useState("");
  const [error2, seterror2] = useState("");

  const [trasnid, settransid] = useState("");
  const [Accommet, setAcComment] = useState("");
  const [tcamount, settcamount] = useState("");
  const [StudentRequestList, SetStudentRequestList] = useState([]);
  const [TransImage, setTransImage] = useState("");

  const [accordion1Open, setAccordion1Open] = useState(false);
  const [accordion2Open, setAccordion2Open] = useState(false);
  const [accordion3Open, setAccordion3Open] = useState(true);
  const [stepList, SetStepList] = useState([]);

  const handleAccordion1Toggle = () => {
    setAccordion1Open(!accordion1Open);
  };

  const handleAccordion2Toggle = () => {
    setAccordion2Open(!accordion2Open);
  };

  const handleAccordion3Toggle = () => {
    setAccordion3Open(!accordion3Open);
  };
  useEffect(() => {
    var roleID = cookies.roleID;
    if (ID > 0) {
      _ApprovalService
        .Get_RequestById(ID, roleID, 1)
        .then((response) => {
          const data = response.data;

          console.log(data);

          if (
            data &&
            data.approvalRequests &&
            data.approvalRequests.length > 0
          ) {
            const firstTicket = data.approvalRequests[0];
            setTicket(firstTicket.ticketNumber);
            setSelectedTicket(firstTicket.type);
            setAmount(firstTicket.amount);
            setBillNumber(firstTicket.billNumber);
            setFundDetails(firstTicket.ticketDetails);
            Setfilepath(firstTicket.fileName);
            // setdownloadpath(firstTicket.downloadPath);
            setdownloadpath(
              Axios.defaults.baseURL + "/Uploadfiles/" + firstTicket.fileName
            );
            SetFirstCmd(firstTicket.firstLevelComment);
            SetSecondCmd(firstTicket.secondLevelComment);
            SetThirdCmd(firstTicket.thirdLevelComment);
            SetStudentName(firstTicket.studentName);
            SetBankAccNumber(firstTicket.bankAccNumber);
            if (firstTicket.bankAccType == 1) {
              SetBankAccType("Saving");
            } else {
              SetBankAccType("Current");
            }
            SetBankBranch(firstTicket.bankBranch);
            SetBankName(firstTicket.bankName);
            SetIFSCCode(firstTicket.ifscCode);
            SetPassBookUrl(firstTicket.passBookUrl);
            setAcComment(firstTicket.accountantComment);
            settransid(firstTicket.transferId);
            settcamount(firstTicket.transferAmount);
            setTransImage(firstTicket.imgae);
            setRegisterNo(firstTicket.registerNo);
            setAccomType(firstTicket.accommodationType);
            setCourse(firstTicket.course);
            setSpecialization(firstTicket.specialization);
            setCollegeName(firstTicket.collegeName);
            setBatchNo(firstTicket.batchNo);

            console.log("Fetched ticket data:", firstTicket);
          }

          if (data && data.stepLevels && data.stepLevels.length > 0) {
            const step = data.stepLevels;
            SetStepList(step);
            console.log(step);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }

    if (StudentId > 0) {
      _ApprovalService
        .Student_CreditedInfo(StudentId)
        .then((res) => {
          if (res != null && res.data != null && res.data.length > 0) {
            const result = res.data;
            SetStudentRequestList(result);
          }
          $(".loader-container").hide();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ID]);

  const handleAmountChange = (event) => {
    const input = event.target.value;
    const numericInput = input.replace(/[^0-9.]/g, ""); // Allow digits and decimal point

    const parts = numericInput.split(".");
    const integerPart = parts[0];
    let decimalPart = parts[1];

    if (integerPart.length <= 10) {
      if (decimalPart !== undefined) {
        decimalPart = decimalPart.slice(0, 2);
      }

      const formattedIntegerPart = new Intl.NumberFormat("en-IN").format(
        integerPart
      );

      let formattedAmount = formattedIntegerPart;

      if (decimalPart !== undefined) {
        formattedAmount += "." + decimalPart;
      }

      settcamount(formattedAmount);
    } else {
      const trimmedIntegerPart = integerPart.slice(0, 10);
      const formattedIntegerPart = new Intl.NumberFormat("en-IN").format(
        trimmedIntegerPart
      );

      let formattedAmount = formattedIntegerPart;

      if (decimalPart !== undefined) {
        decimalPart = decimalPart.slice(0, 2); // Limit decimal part to 2 digits
        formattedAmount += "." + decimalPart;
      }

      settcamount(formattedAmount);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (validateFileExtension(file)) {
      setSelectedFile(file);
    } else {
      setSelectedFile(null);
      setfileerror(
        "Invalid file format. Please select a valid file.It allows .jpg, .jpeg, .png, .pdf"
      );
    }
  };

  const validateFileExtension = (file) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };

  const HandleSubmit = (e) => {
    debugger;
    e.preventDefault();

    const transferredID = tcamount;
    const transferredAmount = tcamount;

    if (!transferredID || transferredID == null) {
      seterror1("please enter the Transferred ID ");
      return;
    }

    if (!transferredAmount || transferredAmount == null) {
      seterror2("please enter the  Transferred Amount");
      return;
    }
    if (Status == -1) {
      if (Accommet == null || Accommet == "") {
        seterror("Please enter your remarks");
        return;
      }
    }

    const formData = new FormData();
    formData.append("IsApproved", Status);
    formData.append("TicketId", ID);
    formData.append("RoleName", cookies.roleName);
    formData.append("ApproverStageId", StageId);
    formData.append("FirstLevelApprovedBy", cookies.userID);
    formData.append("FirstLevelComment", Accommet);
    formData.append("TransferId", transferredID);
    formData.append("TransferAmount", transferredAmount);
    formData.append("Status", Status === 0 ? 'Rejected' : 'Approved');
    // formData.append("UploadPath", e.target.UploadPath.files[0]);

    $(".loader-container").show();
    _ApprovalService
      .Accountant_Submit(formData)
      .then((response) => {
        $(".loader-container").hide();

        if (ID == 0) {
          Toast("Request Approved Successfully", "success");
        } else {
          Toast("Request Approved Successfully", "success");
        }
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        console.error("Error while submitting transfer details:", error);
      });
  };

  function download(url) {
    RemObj.Download(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }
  function DownloadPassBook(url) {
    RemObj.DownloadPassBook(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }
  return (
    <div>
      <Accordion expanded={accordion1Open} onChange={handleAccordion1Toggle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>
            <div className="row leftalign">
            Student Name :{" "}{StudentName?StudentName:"-"} &nbsp; | &nbsp; Ticket No :{" "}
            {TicketCode?TicketCode:"-"} &nbsp; | &nbsp; Amount :{" "}{amount?amount:"-"}&nbsp; | &nbsp;Register Number :{" "}{registerNo?registerNo:"-"}
            &nbsp; | &nbsp; Batch No :{" "}{batchNo?batchNo:"-"}
            </div>
            <div className="row leftalign">
             Course :{" "}{course?course:"-"}
            &nbsp; | &nbsp; Specialization :{" "}{specialization?specialization:"-"}
            &nbsp; | &nbsp; College Name :{" "}{collegeName?collegeName:"-"}
            &nbsp; | &nbsp; Accommodation Type  :{" "}{accomType?accomType:"-"}
            </div>
           
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row">
            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">Request Number</label>
                <input
                  type="text"
                  name="RegNo"
                  class="form-control"
                  placeholder=""
                  value={TicketCode}
                  disabled={true}
                />
              </div>
            </div>

            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">Fund Type</label>
                <input
                  type="text"
                  name="RegNo"
                  class="form-control"
                  value={selectedTicket}
                  disabled={true}
                />
              </div>
            </div>

            <div class="col-lg-4 mb-2">
              <div class="mb-3">
                <label class="text-label form-label">Amount</label>
                <input
                  type="text"
                  name="Amount"
                  class="form-control"
                  value={amount}
                  disabled={true}
                />
              </div>
            </div>
            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">Bill Number</label>
                <input
                  type="text"
                  name="firstName"
                  class="form-control"
                  value={billNumber}
                  disabled={true}
                />
              </div>
            </div>
            <div class="col-lg-4 mb-3" style={{ marginTop: "-17px" }}>
              <div class="mb-3">
                <label class="text-label form-label">{selectedTicket=="Advance Request"?"View Reference / Proof" :"View Bill" }</label>
                <div>
                  <span className="file-info">
                    {/* <a href={downloadpath} download>
                      <CloudDownloadIcon className="download-icon" />
                      <div>{filepath}</div>
                    </a> */}
                    <a onClick={() => download(filepath)}>
                      <CloudDownloadIcon className="download-icon" />
                      {/* <div>{filepath}</div> */}
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div class="col-lg-4 mb-3" style={{ marginTop: "-17px" }}>
              <div class="mb-3">
                <label class="text-label form-label">
                  Fund Details<span class="text-danger">*</span>
                </label>
                <div>
                  <textarea
                    name="lastName"
                    class="form-control"
                    value={FundDetail}
                    disabled={true}
                  ></textarea>
                </div>
              </div>
            </div>

            {stepList.map((step, index) => (
              <div className="col-lg-4 mb-3" key={index}>
                <div className="mb-3">
                  <label className="text-label form-label">
                    {step.stepDescription}
                  </label>
                  <div>
                    <textarea
                      name={`remarks-${index}`}
                      className="form-control"
                      value={step.remarks}
                      disabled={true}
                    ></textarea>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        style={{ marginTop: "1%" }}
        expanded={accordion2Open}
        onChange={handleAccordion2Toggle}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>
            IFSC :{" "} {IFSCCode?IFSCCode:"-"} &nbsp; | &nbsp; Account Number:{" "} {BankAccNumber?BankAccNumber:"-"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div class="row ">
            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">
                  Bank Name<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="BankName"
                  class="form-control"
                  value={BankName}
                  required
                  disabled={true}
                />
              </div>
            </div>
            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">
                  Account Number<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="BankAccNumber"
                  class="form-control"
                  value={BankAccNumber}
                  required
                  disabled={true}
                  maxLength={25}
                />
              </div>
            </div>

            {/* <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">
                  Account Type<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="BankAccType"
                  class="form-control"
                  value={BankAccType}
                  required
                  disabled={true}
                />
              </div>
            </div> */}

            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">
                  Branch<span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="BankBranch"
                  class="form-control"
                  value={BankBranch}
                  disabled={true}
                  required
                />
              </div>
            </div>

            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">
                  IFSC Code <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  name="IFSCCode"
                  class="form-control"
                  value={IFSCCode}
                  maxLength={11}
                  disabled={true}
                  required
                />
              </div>
            </div>

            <div class="col-lg-4 mb-2">
              <div class="mb-4">
                <label class="text-label form-label">Passbook</label>
                <br></br>
                <a
                  target="_blank"
                  onClick={() => DownloadPassBook(PassBookUrl)}
                  title="Download"
                >
                  <CloudDownloadIcon className="download-icon" />
                  {/* <div>{filepath}</div> */}
                </a>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <form onSubmit={HandleSubmit} style={{ marginTop: "1%" }}>
        <Accordion expanded={accordion3Open} onChange={handleAccordion3Toggle}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Transfer Details</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div class="row">
              <div class="col-lg-7">
                <div className="row">
                  <div class="col-lg-6 mb-2">
                    <div class="mb-4">
                      <label class="text-label form-label">
                        Transaction ID<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="TransferredID"
                        class="form-control"
                        maxLength={20}
                        autoComplete="off"
                        onChange={(e) => {
                          seterror1("");
                        }}
                        value={trasnid}
                        disabled={true}
                      />
                    </div>
                    <div class="invalid-feedback" style={{ display: "block" }}>
                      {error1}
                    </div>
                  </div>

                  <div class="col-lg-6 mb-2">
                    <div class="mb-4">
                      <label class="text-label form-label">
                        Transferred Amount<span class="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="TransferredAmount"
                        class="form-control"
                        value={tcamount}
                        autoComplete="off"
                        onChange={(e) => {
                          handleAmountChange(e);
                          seterror2("");
                        }}
                        disabled={true}
                      />
                    </div>
                    <div class="invalid-feedback" style={{ display: "block" }}>
                      {error2}
                    </div>
                  </div>

                  <div>
                    <FundChatBox ID={ID} />
                  </div>
                  {/* <div class="col-lg-6 mb-2">
                    <div class="form-group">
                    
      
                    
                    </div>
                  </div> */}
                </div>
              </div>

              <div class="col-lg-5">
                {StudentRequestList != null && StudentRequestList.length > 0 ? (
                  <div className="trans">
                    {/* <p> <span style={{ fontWeight: "bold"}}>Allocated Amount :</span> {StudentRequestList[0].allocatedAmount}</p> */}
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Total Credited Amount :
                      </span>{" "}
                      {StudentRequestList[0].totalCreditedAmount}
                    </p>
                    {/* <p><span style={{ fontWeight: "bold"}}>Balance Amount :</span> {StudentRequestList[0].balanceAmount}</p> */}
                  </div>
                ) : (
                  <></>
                )}
                <div
                  className={
                    StudentRequestList.length > 10 ? "trans-scroll" : "trans"
                  }
                >
                  <h3 style={{ fontWeight: "bold", color: "#1da837" }}>
                    {" "}
                    Transaction History
                  </h3>
                  <table
                    id="add-row"
                    className="display table table-striped table-hover"
                  >
                    <thead>
                      <tr>
                        <th> Date</th>
                        <th> Fund Type</th>
                        <th> Amount</th>
                        <th> View Bill</th>
                        <th> View Advance Bill</th>
                      </tr>
                    </thead>
                    <tbody>
                      {StudentRequestList.map((item, index) => (
                        <tr key={index}>
                          <td>{item.creditedDate}</td>
                          <td>{item.fundType}</td>
                          <td>{item.creditedAmount}</td>
                          <td>
                            {item.billUrl != null ? (
                              <a
                                target="_blank"
                                onClick={() => download(item.billUrl)}
                                title="Download"
                              >
                                <CloudDownloadIcon className="download-icon" />
                                {/* <div>{filepath}</div> */}
                              </a>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td>
                            {item.uploadAdvanceBill != null ? (
                              <a
                                target="_blank"
                                onClick={() => download(item.uploadAdvanceBill)}
                                title="Download"
                              >
                                <CloudDownloadIcon className="download-icon" />
                                {/* <div>{filepath}</div> */}
                              </a>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </form>
    </div>
  );
}
