import React, { useEffect } from "react";
import $ from "jquery";
import DropdownList from "./DropDownList";
import EmailConfig from "./EmailConfig";
import SharedFolder from "./SharedFolder";

import LoginTracker from "./LoginTracker";

function ApplicationIndex() {
  useEffect(() => {
    window.scrollTo(0, 0);
    $(".submenu").removeClass("active");
    $("#ConfigurationMenu").addClass("active");

    $(".collapse").removeClass("show");
    $("#Configuration").addClass("show");

    $(".submenu li").removeClass("active");
    $("#appconf").addClass("active");
  });
  return (
    <>
      <div class="main-panel">
        <div class="content">
          <div className="page-inner">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">Application Configuration</h4>
              </div>
              <div class="card-body">
                <ul
                  class="nav nav-pills nav-secondary"
                  id="pills-tab"
                  role="tablist"
                >
                  <li class="nav-item submenu">
                    <a
                      class="nav-link active show"
                      id="pills-Dropdown-tab"
                      data-toggle="pill"
                      href="#pills-Dropdown"
                      role="tab"
                      aria-controls="pills-Dropdown"
                      aria-selected="true"
                    >
                      Dropdown Configuration
                    </a>
                  </li>

                  <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-Email-tab"
                      data-toggle="pill"
                      href="#pills-Email"
                      role="tab"
                      aria-controls="pills-Email"
                      aria-selected="false"
                    >
                      Email Configuration
                    </a>
                  </li>

                  {/* <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-Shared-tab"
                      data-toggle="pill"
                      href="#pills-Shared"
                      role="tab"
                      aria-controls="pills-Shared"
                      aria-selected="false"
                    >
                      FTP Server
                    </a>
                  </li> */}

                  {/* <li class="nav-item submenu">
                    <a
                      class="nav-link"
                      id="pills-LoginTracker-tab"
                      data-toggle="pill"
                      href="#pills-LoginTracker"
                      role="tab"
                      aria-controls="pills-LoginTracker"
                      aria-selected="false"
                    >
                      Login Tracker
                    </a>
                  </li> */}
                </ul>
                <div class="tab-content mt-2 mb-3" id="pills-tabContent">
                  <div
                    class="tab-pane fade active show"
                    id="pills-Dropdown"
                    role="tabpanel"
                    aria-labelledby="pills-Dropdown-tab"
                  >
                    <DropdownList></DropdownList>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="pills-Email"
                    role="tabpanel"
                    aria-labelledby="pills-Email-tab"
                  >
                    <EmailConfig></EmailConfig>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-Shared"
                    role="tabpanel"
                    aria-labelledby="pills-Shared-tab"
                  >
                    <SharedFolder></SharedFolder>
                  </div>

                  {/* <div
                    class="tab-pane fade"
                    id="pills-LoginTracker"
                    role="tabpanel"
                    aria-labelledby="pills-LoginTracker-tab"
                  >
                    <LoginTracker></LoginTracker>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ApplicationIndex;
