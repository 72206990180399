import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useContext,
} from "react";
import { AuthContext } from "../../Context/AuthContext";
import ApplicarionConfigService from "../../api/ApplicationConfigService";
import $ from "jquery";
import ReimbursementService from "../../api/ReimbursementService";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { saveAs } from "file-saver";
import Toast from "../Toastify";

const RemObj = new ReimbursementService();

function DropdownList(props) {
  const { ID } = props;
  const [Commentlist, SetCommentlist] = useState([]);
  const { cookies, logout } = useContext(AuthContext);
  const [Render, PageRender] = useReducer((x) => x + 1, 0);
  const [employeeId, setemployeeId] = useState(cookies.userID);
  const [filepath, Setfilepath] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileerror, setfileerror] = useState("");

  const onKeyDown = (event) => {
    // if (event.code === 'Space') event.preventDefault()
  };

  useEffect(() => {
    RemObj.CommentBox(ID)
      .then((res) => {
        if (res != null && res.data != null && res.data.length > 0) {
          const result = res.data;
          SetCommentlist(result);
        }
        $(".loader-container").hide();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [ID, Commentlist, Render]);

  function downloadCommentpath(url) {
    RemObj.Downloadcomment(url).then((response, status, xhr) => {
      const contentType = response.headers["content-type"];
      if (contentType === "application/octet-stream") {
        // Handle binary file download here
        saveAs(new Blob([response.data], { type: contentType }), url);
      } else {
        // Handle JSON or XML response
        // Parse the response based on the content type
      }
    });
  }
  const handleFileChange = (event) => {
    debugger;
    const file = event.target.files[0];
    if (validateFileExtension(file)) {
      setSelectedFile(file);
    } else {
      setSelectedFile(filepath);
      setfileerror(
        "Invalid file format. Please select a valid file.It allows .jpg, .jpeg, .png, .pdf"
      );
    }
  };

  const validateFileExtension = (file) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "pdf"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    return allowedExtensions.includes(fileExtension);
  };
  function SendComment() {
    debugger;
    const formData = {
      CommetID: 0,
      TicketID: ID,
      Commnets: $("#values").val(),
      SavedBy: employeeId,
      UploadPath: selectedFile,
    };

    if (formData.Commnets == null || formData.Commnets == "") {
      $("#errValue").text("Please Enter Commnet");
      Toast("Please fill the Commnet", "warning");
      return false;
    }

    const Baseform = new FormData();

    Baseform.append("CommetID", formData.CommetID);
    Baseform.append("TicketID", formData.TicketID);
    Baseform.append("Commnets", formData.Commnets);
    Baseform.append("SavedBy", formData.SavedBy);

    if (filepath == null || filepath == "null" || filepath == "") {
      Baseform.append("UploadPath", formData.UploadPath);
    } else {
      Baseform.append("FileName", filepath);
    }

    SaveComment(Baseform);
  }

  function SaveComment(Baseform) {
    RemObj.SaveCommentBox(Baseform)
      .then((response) => {
        $(".loader-container").hide();

        //   if (ID == 0 && Status == 1) {
        //     Toast("Request Approved Successfully", "success");
        //   } else if (Status == 0) {
        //     Toast("Request Rejectd Successfully", "success");
        //   } else {
        //     Toast("Request Approved Successfully", "success");
        //   }
        setTimeout(() => {
          window.location.reload();
        }, 2000);

        PageRender();
      })
      .catch((error) => {
        $(".loader-container").hide();
        console.error("Error while saving:", error);
      });
  }

  function hideErrorMessage() {
    $("#errValue").text("");
    $("#errStepLevel").text("");
    $("#errSortOrder").text("");
  }

  return (
    <>
      <div className="row card-body">
        <div className="col-lg-6 col-md-9 col-sm-12">
          <input type="hidden" name="ID" id="ID" />
          <div class="form-group">
            <label>Comment</label>
            <textarea type="text" class="form-control " id="values"></textarea>
            <span style={{ color: "red" }} id="errValue"></span>
          </div>
        </div>
        <div className="col-lg-6 col-md-9 col-sm-12">
          <div class="form-group">
            <label>Reference File</label>
            <input
              type="file"
              name="UploadPath"
              className="form-control"
              accept=".jpg, .jpeg, .png, .pdf"
              onChange={(e) => {
                handleFileChange(e); // Call the existing handler
                //setformError({ ...error, UploadPath: "" }); // Clear the error message
              }}
            />
          </div>
        </div>
        <div
          className="col-lg-12 mt-6 col-md-9 col-sm-12"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <button
            type="button"
            id="addRowButton"
            class="btn btn-primary btn-sm"
            style={{ marginTop: "1%" }}
            onClick={SendComment}
          >
            Send Comment
          </button>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12">
          {Commentlist != null ? (
            <div class="col-md-12">
              <p style={{ fontWeight: "bold" }}>Comments </p>
              <div>
                {Commentlist.map((item, index) =>
                  employeeId == item.SavedBy ? (
                    <>
                      <div class="comment1">
                        <div class="commentlist1">
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#3e9ae6",
                            }}
                          >
                            <span>@</span>
                            {item.first_Name} -{" "}
                          </span>
                          <span style={{ textAlign: "left", fontSize: "10px" }}>
                            {item.createdDate}
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {" "}
                            : {item.commnets}
                          </span>
                          {item.fileName != null ? (
                            <span style={{ fontSize: "14px" }}>
                              {" "}
                              :{" "}
                              <a
                                onClick={() =>
                                  downloadCommentpath(item.fileName)
                                }
                              >
                                <CloudDownloadIcon className="download-icon" />
                                {/* <div>{filepath}</div> */}
                              </a>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <hr />
                    </>
                  ) : (
                    <>
                      <div class="comment">
                        <div class="commentlist">
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#3e9ae6",
                            }}
                          >
                            <span>@</span>
                            {item.first_Name} -{" "}
                          </span>
                          <span style={{ textAlign: "left", fontSize: "10px" }}>
                            {item.createdDate}
                          </span>
                          <span style={{ fontSize: "14px" }}>
                            {" "}
                            : {item.commnets}
                          </span>
                          {item.fileName != null ? (
                            <span style={{ fontSize: "14px" }}>
                              {" "}
                              :{" "}
                              <a
                                onClick={() =>
                                  downloadCommentpath(item.fileName)
                                }
                              >
                                <CloudDownloadIcon className="download-icon" />
                                {/* <div>{filepath}</div> */}
                              </a>
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                      <hr />
                    </>
                  )
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export default DropdownList;
