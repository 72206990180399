import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useRef,
} from "react";
import Select from "react-select";
import StudentService from "../../api/StudentService";
import Toast from "../Toastify";
import { EightK } from "@mui/icons-material";
import { AuthContext } from "../../Context/AuthContext";
import $ from "jquery";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { saveAs } from "file-saver";

const StudentServiceObj = new StudentService();
const SemesterResult = (props) => {
  const { Id } = props;

  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);

  const allowedFileTypes = ["jpg", "pdf", "docx", "png", "jpeg"];
  const [fileInputs, setFileInputs] = useState({
    "1stSem": null,
    "2ndSem": null,
    "3rdSem": null,
    "4thSem": null,
    "5thSem": null,
    "6thSem": null,
    "7thsem": null,
    "8thSem": null,
  });

  const [FirstSemfileName, SetFirstSemfileName] = useState("");
  const [SecondeSemfileName, SetSecondeSemfileName] = useState("");
  const [ThirdSemfileName, SetThirdSemfileName] = useState("");
  const [FourthSemfileName, SetFourthSemfileName] = useState("");
  const [FivethSemfileName, SetFivethSemfileName] = useState("");
  const [SixSemfileName, SetSixSemfileName] = useState("");
  const [SeventhsemfileName, SetSeventhsemfileName] = useState("");
  const [EigthSemfileName, SetEigthSemfileName] = useState("");

  const [firstsemfile, Setfirstsemfile] = useState("");
  const [secsemfile, Setsecsemfile] = useState("");
  const [thirdsemfile, Setthirdsemfile] = useState("");
  const [fourthsemfile, Setfourthsemfilefile] = useState("");
  const [fivethsemfile, Setfivethsemfile] = useState("");
  const [sixthsemfile, SetSixthsemfile] = useState("");
  const [seventhfile, Setseventhsemfile] = useState("");
  const [eigthsemfile, Seteightsemfile] = useState("");

  useEffect(() => {


      $(".submenu").removeClass("active");
      $("#StudentView").addClass("active");

    
    var roleid = cookies.roleID;

    StudentServiceObj.Get_ResultList(cookies.userID)
      .then((response) => {
        const data = response.data;

        console.log(data);
        debugger;

        if (data && data.resultUploads && data.resultUploads.length > 0) {
          debugger;
          SetFirstSemfileName(data.resultUploads[0].firstSemfileName);
          SetSecondeSemfileName(data.resultUploads[0].secondeSemfileName);
          SetThirdSemfileName(data.resultUploads[0].thirdSemfileName);
          SetFourthSemfileName(data.resultUploads[0].fourthSemfileName);
          SetFivethSemfileName(data.resultUploads[0].fivethSemfileName);
          SetSixSemfileName(data.resultUploads[0].sixSemfileName);
          SetSeventhsemfileName(data.resultUploads[0].seventhsemfileName);
          SetEigthSemfileName(data.resultUploads[0].eigthSemfileName);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  });

  const HandleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("StudentSemResultDetId", 0);
    formData.append("StudentId", cookies.userID);
    formData.append("FirstSemfile", firstsemfile);
    formData.append("SecondeSemfile", secsemfile);
    formData.append("ThirdSemfile", thirdsemfile);
    formData.append("FourthSemfile", fourthsemfile);
    formData.append("FivethSemfile", fivethsemfile);
    formData.append("SixSemfile", sixthsemfile);
    formData.append("Seventhsemfile", seventhfile);
    formData.append("EigthSemfile", eigthsemfile);
    formData.append("FirstSemfileName", FirstSemfileName);
    formData.append("SecondeSemfileName", SecondeSemfileName);
    formData.append("ThirdSemfileName", ThirdSemfileName);
    formData.append("FourthSemfileName", FourthSemfileName);
    formData.append("FivethSemfileName", FivethSemfileName);
    formData.append("SixSemfileName", SixSemfileName);
    formData.append("SeventhsemfileName", SeventhsemfileName);
    formData.append("EigthSemfileName", EigthSemfileName);

    StudentServiceObj.Result_Save(formData)
      .then((response) => {
        Toast("Result Uploaded Successfully", "success");
        $(".close").trigger("click");
      })
      .catch((error) => {
        console.error("Error while saving:", error);
      });
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const handleFileChange = (event) => {
    debugger;
    const file = event.target.files[0];

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split(".").pop().toLowerCase();

      if (allowedFileTypes.includes(fileExtension)) {
        // File type is allowed, you can proceed
        setSelectedFile(file);
      } else {
        // File type is not allowed, show an error message or handle it as needed
        Toast(
          "File type not allowed. Please select a file with one of the following extensions: jpg, pdf, docx, png, jpeg",
          "warning"
        );
        event.target.value = ""; // Clear the input
      }
    }
    if (event.target.name == "1stSem") {
      Setfirstsemfile(file);
    } else if (event.target.name == "2ndSem") {
      Setsecsemfile(file);
    } else if (event.target.name == "3rdSem") {
      Setthirdsemfile(file);
    } else if (event.target.name == "4thSem") {
      Setfourthsemfilefile(file);
    } else if (event.target.name == "5thSem") {
      Setfivethsemfile(file);
    } else if (event.target.name == "6thSem") {
      SetSixthsemfile(file);
    } else if (event.target.name == "7thsem") {
      Setseventhsemfile(file);
    } else if (event.target.name == "8thSem") {
      Seteightsemfile(file);
    }
  };

  function download(url) {
    debugger;
    StudentServiceObj.Result_Download(url, cookies.userID).then(
      (response, status, xhr) => {
        const contentType = response.headers["content-type"];
        if (contentType === "application/octet-stream") {
          saveAs(new Blob([response.data], { type: contentType }), url);
          Toast("Download Successfully", "success");
        } else {
        }
      }
    );
  }
  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <div class="main-panel">
      <div class="content">
        <div className="page-inner">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <h3>
                    <b>Result Upload</b>
                  </h3>
                  <div class="">
                    <form
                      id="PersonalDetailsCreate"
                      onKeyPress={(e) => {
                        if (e.key == "Enter") {
                          e.preventDefault();
                        }
                      }}
                      onSubmit={HandleSubmit}
                    >
                      <div class="row">
                        <table
                          id="add-row"
                          className="display table table-striped table-hover"
                        >
                          <thead>
                            <tr>
                              <th>Semester</th>
                              <th> Upload Result</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1st Semester</td>
                              <td>
                                <input
                                  type="file"
                                  name="1stSem"
                                  onChange={handleFileChange}
                                ></input>
                                {FirstSemfileName !== "null" &&
                                  FirstSemfileName !== "" &&
                                  FirstSemfileName !== null&& (
                                    <>
                                      <a
                                        title="Download"
                                        onClick={() =>
                                          download(FirstSemfileName)
                                        }
                                      >
                                        <CloudDownloadIcon className="download-icon" />
                                      </a>
                                    </>
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td>2nd Semester</td>
                              <td>
                                <input
                                  type="file"
                                  name="2ndSem"
                                  onChange={handleFileChange}
                                ></input>
                                {SecondeSemfileName != "null" &&
                                SecondeSemfileName !== "" &&
                                SecondeSemfileName !== null ? (
                                  <a
                                    title="Download"
                                    onClick={() => download(SecondeSemfileName)}
                                  >
                                    <CloudDownloadIcon className="download-icon" />
                                  </a>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>3rd Semester</td>

                              <td>
                                <input
                                  type="file"
                                  name="3rdSem"
                                  onChange={handleFileChange}
                                ></input>
                                {ThirdSemfileName !== "null" &&
                                  ThirdSemfileName !== "" &&
                                  ThirdSemfileName !== null && (
                                    <>
                                      <a
                                        title="Download"
                                        onClick={() =>
                                          download(ThirdSemfileName)
                                        }
                                      >
                                        <CloudDownloadIcon className="download-icon" />
                                      </a>
                                    </>
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td>4th Semester</td>

                              <td>
                                <input
                                  type="file"
                                  name="4thSem"
                                  onChange={handleFileChange}
                                ></input>
                                {FourthSemfileName !== "null" &&
                                  FourthSemfileName !== "" &&
                                  FourthSemfileName !== null && (
                                    <>
                                      <a
                                        title="Download"
                                        onClick={() =>
                                          download(FourthSemfileName)
                                        }
                                      >
                                        <CloudDownloadIcon className="download-icon" />
                                      </a>
                                    </>
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td>5th Semester</td>

                              <td>
                                <input
                                  type="file"
                                  name="5thSem"
                                  onChange={handleFileChange}
                                ></input>
                                {FivethSemfileName !== "null" &&
                                  FivethSemfileName !== "" &&
                                  FivethSemfileName !== null && (
                                    <>
                                      <a
                                        title="Download"
                                        onClick={() =>
                                          download(FivethSemfileName)
                                        }
                                      >
                                        <CloudDownloadIcon className="download-icon" />
                                      </a>
                                    </>
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td>6th Semester</td>

                              <td>
                                <input
                                  type="file"
                                  name="6thSem"
                                  onChange={handleFileChange}
                                ></input>
                                {SixSemfileName !== "null" &&
                                  SixSemfileName !== "" &&
                                  SixSemfileName !== null && (
                                    <>
                                      <a
                                        title="Download"
                                        onClick={() => download(SixSemfileName)}
                                      >
                                        <CloudDownloadIcon className="download-icon" />
                                      </a>
                                    </>
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td>7th Semester</td>

                              <td>
                                <input
                                  type="file"
                                  name="7thsem"
                                  onChange={handleFileChange}
                                ></input>
                                {SeventhsemfileName !== "null" &&
                                  SeventhsemfileName !== "" &&
                                  SeventhsemfileName !== null && (
                                    <>
                                      <a
                                        title="Download"
                                        onClick={() =>
                                          download(SeventhsemfileName)
                                        }
                                      >
                                        <CloudDownloadIcon className="download-icon" />
                                      </a>
                                    </>
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <td>8th Semester</td>

                              <td>
                                <input
                                  type="file"
                                  name="8thSem"
                                  onChange={handleFileChange}
                                ></input>
                                {EigthSemfileName !== "null" &&
                                  EigthSemfileName !== "" &&
                                  EigthSemfileName !== null && (
                                    <>
                                      <a
                                        title="Download"
                                        onClick={() =>
                                          download(EigthSemfileName)
                                        }
                                      >
                                        <CloudDownloadIcon className="download-icon" />
                                      </a>
                                    </>
                                  )}
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="col-12 modal-footer no-bd">
                          <button
                            type="button"
                            class="btn btn-danger"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => refreshPage()}
                          >
                            Cancel
                          </button>

                          <button
                            class="btn btn-primary float-right"
                            style={{ marginLeft: "10px" }}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SemesterResult;
