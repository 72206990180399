import Axios from "axios";

//dev
//hr_rec
// Axios.defaults.baseURL = "http://localhost:57917/api";
// //hrmsapp
// var urlHRMS = "http://localhost:57917/";
// export const imgURL = "http://localhost:57917/";




//QA
//hr_rec
//  Axios.defaults.baseURL = "http://ammqaapi.e21designs.com/api";
//hrmsapp
//  var urlHRMS = "http://ammqaapi.e21designs.com/"
//  export const imgURL = "http://ammqaapi.e21designs.com/";

//UAT
//hr_rec
//  Axios.defaults.baseURL = "http://ammuatapi.e21designs.com/api";
//hrmsapp
//  var urlHRMS = "http://ammuatapi.e21designs.com/"
// export const imgURL = "http://ammuatapi.e21designs.com/";


//Production
Axios.defaults.baseURL = "https://ammscholarshipapi.e21designs.com/api";
var urlHRMS = "https://ammscholarshipapi.e21designs.com/"
export const imgURL = "https://ammscholarshipapi.e21designs.com/"


Axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

export default class AxiosServices {
  post(url, data, Header = false) {
    return Axios.post(url, data, Header);
  }
  get(url, header = null) {
    return Axios.get(url);
  }
  geturl() {
    return urlHRMS;
  }
}
