
// /*
// ***********************************************************************
//  * Author		:	Tinu G
//  * Create date	:	24/10/2022
//  * Module       :   Email Template
// ***********************************************************************
//  * */
import EmailTemplateList from "./EmailTemplateList";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import $ from 'jquery'


function EmailTemplate() {
  //var UserValidate = JSON.parse(localStorage.getItem("UserDetails"));
  let redriect = useNavigate();


  const navigateToContacts = (val) => {
    // debugger;
    //   redriect.push({
    //     pathname: "/EmailTemplateCreate",
    //   state: val
    //   });
    // redriect('/EmailTemplateCreate');
    redriect("/EmailTemplateCreate", { state: val });
  };


  useEffect(() => {
    
    $('.submenu').removeClass('active');
    $('#ConfigurationMenu').addClass('active');

    $('.collapse').removeClass('show')
    $('#Configuration').addClass('show')

    $(".submenu li").removeClass("active");

    $("#EmailTemplate").addClass("active");
  }, []);

  return (
    <>
    
                  
                  <EmailTemplateList></EmailTemplateList>
            
    </>
  );
}

export default EmailTemplate;
