import React, { useState, useContext, useEffect } from "react";

import { AuthContext } from "../../Context/AuthContext";
import $ from "jquery";
import "../../assets/css/atlantis.min.css";
import "../../assets/css/demo.css";
// import "../../assets/js/plugin/sweetalert/sweetalert.min"
// import '../../assets/js/plugin/webfont/webfont.min.js'
import logofinal from "../../assets/icon2.png";
import Prof from "../../assets/img/user.jpg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import "../SideBar/Loader.css";
import NotificationService from "../../api/NotificationService";
import AxiosServices from "../../api/AxiosService";
import { ToastContainer, toast } from "react-toastify";
import StudentMain from "../Student/StudentEdit";
import Toast from "../Toastify";
import not from "../../assets/img/Dashboard/add-group.png";

// import "../../assets/js/atlantis.js"
// import "../../assets/js/atlantis2.js"

import AuthenticationService from "../../api/AuthenticationService";
// import '../../assets/js/atlantis.min'
const URLHR = new AxiosServices();

function Sidebar() {
  const { cookies, logout, data, removeCookie, setCookie } =
    useContext(AuthContext);

  const navigate = useNavigate();
  const AuthObject = new AuthenticationService();

  const ConfigurationServiceobj = new NotificationService();

  const [EmployeeModel, setEmployeeModel] = useState([]);
  const [selectDropdownvalue, setselectDropdownvalue] = useState("");
  const [Dropdownvalue, setDropdownvalue] = useState("");

  const [NotifiList, setNotifiList] = useState([]);
  const [NotifiCnt, setNotifiCnt] = useState([]);
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    var seconds = 0;
    var el = document.getElementById("licnt");
    function incrementSeconds() {
      seconds += 1;
      el.innerText = "You have been here for " + seconds + " seconds.";
      if (seconds === 5) {
        console.log("5");
      }
      // Run getNoti() and GetCount() every second
      getNoti();
      GetCount();
    }
    var cancel = setInterval(incrementSeconds, 1000);
    if (cookies.SidebarReload == 1) {
      setCookie("SidebarReload", 0, { path: "/" });
      if (cookies.Rememberme == false) {
        setCookie("ISReload", 1, { path: "/" });
      }
      window.location.reload();
    }
    var EmployeeId = cookies.userID;
    AuthObject.getSidebarDropdownList(EmployeeId)
      .then((res) => {
        setEmployeeModel(res.data);
        setDropdownvalue(res.data.sidebarList);
        setselectDropdownvalue(cookies.roleID);
      })
      .catch((err) => {
        console.log(err);
      });
    // Initial call for getNoti() and GetCount()
    getNoti();
    GetCount();
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(cancel);
  }, []);

  useEffect(() => {
    function handleClickDashboard(event) {
      if (event.currentTarget.classList.contains("sidebar-toggle")) {
        document.documentElement.classList.remove("nav_open");
      }
    }

    const sidebarToggleElements =
      document.getElementsByClassName("sidebar-toggle");

    for (let i = 0; i < sidebarToggleElements.length; i++) {
      sidebarToggleElements[i].addEventListener("click", handleClickDashboard);
    }

    return () => {
      for (let i = 0; i < sidebarToggleElements.length; i++) {
        sidebarToggleElements[i].removeEventListener(
          "click",
          handleClickDashboard
        );
      }
    };
  }, []);

  const AddNavOpen = () => {
    document.documentElement.classList.add("nav_open");
  };

  const ClearCnt = (id) => {
    debugger;
    ConfigurationServiceobj.Notification_ClearCnt(id)
      .then((response) => {
        GetCount();
        getNoti();
      })
      .catch((error) => {});
  };

  const getNoti = () => {
    ConfigurationServiceobj.Notification_GetNotificationById(
      Number(cookies.MainUserID)
    )
      .then((response) => {
        var notifi = response.data;
        setNotifiList(notifi);
        // PageRender();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const GetCount = () => {
    ConfigurationServiceobj.Notification_GetCnt(Number(cookies.MainUserID))
      .then((response) => {
        setNotifiCnt(response.data.count);
      })
      .catch((error) => {});
  };

  const ClearAll = () => {
    ConfigurationServiceobj.Notification_ClearAll(Number(cookies.MainUserID))
      .then((response) => {
        GetCount();
        getNoti();
      })
      .catch((error) => {});
  };

  const [priviledge, setPriviledge] = useState(cookies.UserPrivileges);

  function ReportOnchange(e) {
    this.setState({ selectValue: e.target.selectValue });
  }
  function LoadCTHrms(e) {
    setCookie("FirstroleCountLogin", 0, { path: "/" });
    var userNameObj = cookies.KuuuDecript;
    var PasswordObj = cookies.KpppDecript;
    var Role = cookies.roleID;
    var ishrmsObj = 1;
    var url = URLHR.geturl();

    var HRMSURL =
      url +
      "Login/HRRecumentIndex?UserName=" +
      userNameObj +
      "&Password=" +
      PasswordObj +
      "&Role=" +
      Role +
      "&ishrms=" +
      ishrmsObj;

    window.location.href = HRMSURL;
    // window.reload(HRMSURL);
  }

  // console.log(priviledge);
  const btnlogout = () => {
    setCookie("ISReload", 1, { path: "/" });
    logout();
    navigate("/");
  };

  function RolechangeID(e) {
    setselectDropdownvalue(e.target.value);
    var RoleID = e.target.value;
    // var ROleName = e.target.text;
    var ROleName = $("#SwitchRoledropdown option:selected").text();

    AuthObject.getSidebarDropdownSelected(RoleID)

      .then(
        (res) => (
          // console.log(res),
          removeCookie("UserPrivileges"),
          removeCookie("roleID"),
          removeCookie("roleName"),
          sessionStorage.removeItem("currentPage"),
          localStorage.removeItem("Scroll"),
          setselectDropdownvalue(RoleID),
          setCookie("roleID", RoleID, { path: "/" }),
          setCookie("roleName", ROleName, { path: "/" }),
          setCookie("UserPrivileges", res.data?.userPrivileges.split(","), {
            path: "/",
          }),
          navigate("/ApprovalGrid", { replace: true }),
          window.location.reload()
        )
      )
      .catch((err) => {
        console.log(err);
      });
  }

  const showNotification = (iscomplete, response) => {
    // create a new notification
    if (iscomplete == 0) {
      const notification = new Notification("Recruitment Tool Profile Upload", {
        body: response,
        icon: { not },
      });
    }
    if (iscomplete == 1) {
      const notification = new Notification("Recruitment Tool Profile Upload", {
        body: "Profile uploaded successfully",
        icon: { not },
      });
    }

    // close the notification after 10 seconds
    // setTimeout(() => {
    //     notification.close();
    // }, 10 * 1000);

    // navigate to a URL when clicked
    // notification.addEventListener('click', () => {

    //   window.open('https://www.javascripttutorial.net/web-apis/javascript-notification/', '_blank');
    // });
  };
  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 1000);
  }
  const showError = (iscomplete, response) => {
    var type = "";
    var msg = "";
    if (iscomplete == 0) {
      toast.warn(response, {
        position: "top-right",
        autoClose: 50000000000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      // Toast(response, "warning");
    }

    if (iscomplete == 1) {
      type = "success";
      msg = "Profile uploaded successfully";
      toast.success(msg, {
        position: "top-right",
        autoClose: 50000000000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // Toast(msg, type);
    }
  };
  const getApplicationName = () => {
    const hostname = window.location.hostname;

    if (hostname.includes("localhost")) {
      return "AMM Foundation - Dev";
    } else if (hostname.includes("qa")) {
      return "AMM Foundation - QA Env";
    } else if (hostname.includes("uat")) {
      return "AMM Foundation - UAT Env";
    }
  };

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={50000000000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="loader-container" style={{ display: "none" }}>
        <span
          className="loadercube"
          style={{ position: "fixed", right: "46%", bottom: "50%" }}
        ></span>
      </div>

      <div className="main-header">
        {/* <!-- Logo Header --> */}
        <div className="logo-header" data-background-color="blue">
          <a
            href="#"
            className="logo"
            // style={{
            //   display: "flex",
            //   justifyContent: "center",
            //   background: "white",
            // }}
          >
            <img
              id="loginimgWrap"
              src={logofinal}
              alt="navbar brand"
              className="navbar-brand"
            />
          </a>
          <button
            className="navbar-toggler sidenav-toggler ml-auto"
            type="button"
            data-toggle="collapse"
            data-target="collapse"
            aria-expanded={isNavOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleNav}
          >
            <span className="navbar-toggler-icon">
              <i className="icon-menu"></i>
            </span>
          </button>
          <button className="topbar-toggler more">
            <i className="icon-options-vertical"></i>
          </button>
          {/* <div className="nav-toggle">
            <button className="btn btn-toggle toggle-sidebar">
              <i className="icon-menu"></i>
            </button>
          </div> */}
        </div>
        {/* <!-- End Logo Header --> */}

        {/* <!-- Navbar Header --> */}
        <nav
          className="navbar navbar-header navbar-expand-lg"
          data-background-color="blue2"
        >
          <div className="container-fluid">
            <div className="collapse" id="search-nav">
              <form
                className="navbar-left navbar-form nav-search mr-md-3"
                hidden
              >
                {/* <div className="input-group">
                  <div className="input-group-prepend">
                    <button type="submit" className="btn btn-search pr-1">
                      <i className="fa fa-search search-icon"></i>
                    </button>
                  </div>
                  <input
                    type="text"
                    placeholder="Search ..."
                    className="form-control"
                  />
                </div> */}
              </form>
            </div>
            <h5 className="text-white">{getApplicationName()}</h5>

            <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
              <li className="nav-item dropdown hidden-caret">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="messageDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  hidden
                >
                  <i className="fa fa-envelope"></i>
                </a>
                <ul
                  className="dropdown-menu messages-notif-box animated fadeIn"
                  aria-labelledby="messageDropdown"
                >
                  <li>
                    <div className="dropdown-title d-flex justify-content-between align-items-center">
                      Messages
                      <a href="#" className="small">
                        Mark all as read
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="message-notif-scroll scrollbar-outer">
                      <div className="notif-center">
                        <a href="#">
                          <div className="notif-img">
                            <img
                              id="loginimgWrap"
                              src={Prof}
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="subject">Jimmy Denis</span>
                            <span className="block">How are you ?</span>
                            <span className="time">5 minutes ago</span>
                          </div>
                        </a>
                        <a href="#">
                          <div className="notif-img">
                            <img
                              id="loginimgWrap"
                              src={Prof}
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="subject">Chad</span>
                            <span className="block">Ok, Thanks !</span>
                            <span className="time">12 minutes ago</span>
                          </div>
                        </a>
                        <a href="#">
                          <div className="notif-img">
                            <img
                              id="loginimgWrap"
                              src={Prof}
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="subject">Jhon Doe</span>
                            <span className="block">
                              Ready for the meeting today...
                            </span>
                            <span className="time">12 minutes ago</span>
                          </div>
                        </a>
                        <a href="#">
                          <div className="notif-img">
                            <img
                              id="loginimgWrap"
                              src={Prof}
                              alt="Img Profile"
                            />
                          </div>
                          <div className="notif-content">
                            <span className="subject">Talha</span>
                            <span className="block">Hi, Apa Kabar ?</span>
                            <span className="time">17 minutes ago</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a className="see-all" href="javascript:void(0);">
                      See all messages<i className="fa fa-angle-right"></i>{" "}
                    </a>
                  </li>
                </ul>
              </li>
              <li id="licnt" hidden></li>
              <li className="nav-item dropdown hidden-caret">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="notifDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-bell"></i>
                  <span className="notification">{NotifiCnt}</span>
                </a>
                <ul
                  className="dropdown-menu notif-box animated fadeIn"
                  aria-labelledby="notifDropdown"
                >
                  <li>
                    <div
                      className="dropdown-title"
                      style={{ textAlign: "right" }}
                    >
                      <span style={{ cursor: "pointer" }} onClick={ClearAll}>
                        Clear All
                      </span>
                    </div>
                  </li>
                  <li style={{ overflowY: "scroll", maxHeight: "50vh" }}>
                    {NotifiList != null && NotifiList.length > 0 ? (
                      NotifiList.map((e) => {
                        return (
                          <>
                            {cookies.roleName == "Student" ? (
                              <div className="notif-center">
                                <a
                                  href="#"
                                  onClick={() => ClearCnt(e.notificationID)}
                                >
                                  <div className="notif-icon notif-success">
                                    {" "}
                                    <i className="fa fa-comment"></i>{" "}
                                  </div>
                                  <div className="notif-content">
                                    <span
                                      href="/FundRequest"
                                      onClick={() => ClearCnt(e.notificationID)}
                                      className="block"
                                    >
                                      {e.message}
                                    </span>
                                    <span className="time">
                                      {e.notificationTime}
                                    </span>
                                  </div>
                                </a>
                              </div>
                            ) : (
                              <>
                                <div className="notif-center">
                                  <a
                                    href="/ApprovalGrid"
                                    onClick={() => ClearCnt(e.notificationID)}
                                  >
                                    <div className="notif-icon notif-success">
                                      {" "}
                                      <i className="fa fa-comment"></i>{" "}
                                    </div>
                                    <div className="notif-content">
                                      <span
                                        href="/ApprovalGrid"
                                        onClick={() =>
                                          ClearCnt(e.notificationID)
                                        }
                                        className="block"
                                      >
                                        {e.message}
                                      </span>
                                      <span className="time">
                                        {e.notificationTime}
                                      </span>
                                    </div>
                                  </a>
                                </div>
                              </>
                            )}
                          </>
                        );
                      })
                    ) : (
                      <div className="notif-icon notif-success">
                        <div className="notif-center">
                          <div
                            className="notif-content m-3"
                            style={{ textAlign: "center" }}
                          >
                            <span className="block">No Notification</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  {/* <li>
                    <a className="see-all" href="javascript:void(0);">
                      See all notifications
                      <i className="fa fa-angle-right"></i>{" "}
                    </a>
                  </li> */}
                </ul>
              </li>
              <li className="nav-item dropdown hidden-caret">
                <a
                  className="nav-link"
                  data-toggle="dropdown"
                  href="#"
                  aria-expanded="false"
                  hidden
                >
                  <i className="fas fa-layer-group"></i>
                </a>
                <div className="dropdown-menu quick-actions quick-actions-info animated fadeIn">
                  <div className="quick-actions-header">
                    <span className="title mb-1">Quick Actions</span>
                    <span className="subtitle op-8">Shortcuts</span>
                  </div>
                  <div className="quick-actions-scroll scrollbar-outer">
                    <div className="quick-actions-items">
                      <div className="row m-0">
                        <a className="col-6 col-md-4 p-0" href="#">
                          <div className="quick-actions-item">
                            <i className="flaticon-file-1"></i>
                            <span className="text">Generated Report</span>
                          </div>
                        </a>
                        <a className="col-6 col-md-4 p-0" href="#">
                          <div className="quick-actions-item">
                            <i className="flaticon-database"></i>
                            <span className="text">Create New Database</span>
                          </div>
                        </a>
                        <a className="col-6 col-md-4 p-0" href="#">
                          <div className="quick-actions-item">
                            <i className="flaticon-pen"></i>
                            <span className="text">Create New Post</span>
                          </div>
                        </a>
                        <a className="col-6 col-md-4 p-0" href="#">
                          <div className="quick-actions-item">
                            <i className="flaticon-interface-1"></i>
                            <span className="text">Create New Task</span>
                          </div>
                        </a>
                        <a className="col-6 col-md-4 p-0" href="#">
                          <div className="quick-actions-item">
                            <i className="flaticon-list"></i>
                            <span className="text">Completed Tasks</span>
                          </div>
                        </a>
                        <a className="col-6 col-md-4 p-0" href="#">
                          <div className="quick-actions-item">
                            <i className="flaticon-file"></i>
                            <span className="text">Create New Invoice</span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item dropdown hidden-caret">
                <a
                  className="dropdown-toggle profile-pic"
                  data-toggle="dropdown"
                  href="#"
                  aria-expanded="false"
                >
                  <div className="avatar-sm">
                    <img
                      id="loginimgWrap"
                      src={Prof}
                      alt="Img Profile"
                      className="avatar-img rounded-circle"
                    />
                  </div>
                </a>
                <ul className="dropdown-menu dropdown-user animated fadeIn">
                  <div className="dropdown-user-scroll scrollbar-outer">
                    <li>
                      <div className="user-box">
                        <div className="avatar-lg">
                          <img
                            id="loginimgWrap"
                            src={Prof}
                            alt="Img Profile"
                            className="avatar-img rounded-circle"
                          />
                        </div>
                        <div className="u-text">
                          <h4>{cookies.employeeName}</h4>
                          <p className="text-muted">{cookies.roleName}</p>

                          {/* <a
                              href="profile.html"
                              className="btn btn-xs btn-secondary btn-sm"
                            >
                              View Profile
                            </a> */}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dropdown-divider"></div>

                      {cookies.roleName == "Student" ? (
                        <a
                          className="dropdown-item"
                          data-bs-toggle="modal"
                          data-bs-target="#UpadteStudent"
                        >
                          <i className="fas fa-user"></i> Profile
                        </a>
                      ) : (
                        <></>
                      )}
                      <Link className="dropdown-item" to={"/ChangePassword"}>
                        <i className="fas fa-key"></i>{" "}
                        <span>Change Password</span>
                      </Link>

                      {/* <a className="dropdown-item" href="#">
                          My Balance
                        </a>
                        <a className="dropdown-item" href="#">
                          Inbox
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">
                          Account Setting
                        </a>
                        <div className="dropdown-divider"></div> */}
                      <a className="dropdown-item" href="#" onClick={btnlogout}>
                        <i className="fas fa-sign-out-alt"></i> Logout
                      </a>
                    </li>
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
        {/* <!-- End Navbar --> */}
      </div>
      {/* <!-- Sidebar --> */}
      <div className="sidebar sidebar-style-2">
        <div className="sidebar-wrapper scrollbar scrollbar-inner">
          <div className="sidebar-content">
            <div className="user">
              <div className="avatar-sm float-left mr-2">
                <img
                  id="loginimgWrap"
                  src={Prof}
                  alt="Img Profile"
                  className="avatar-img rounded-circle"
                />
              </div>
              <div className="info">
                <a data-toggle="collapse" href="#" aria-expanded="true">
                  <span>
                    {cookies.employeeName}

                    {cookies.roleName == "Student" ? (
                      <>
                        <select
                          id="SwitchRoledropdown"
                          name="SwitchRoledropdown"
                          placeholder="Select Reporting Manager"
                          value={selectDropdownvalue}
                          onChange={(e) => RolechangeID(e)}
                          class="form-control filled roledropdown"
                        >
                          {Dropdownvalue != null && Dropdownvalue.length > 0 ? (
                            Dropdownvalue.map((item, indexs) => {
                              return <option>Student</option>;
                            })
                          ) : (
                            <option>Student</option>
                          )}
                        </select>
                      </>
                    ) : (
                      <>
                        <span className="">
                          <select
                            id="SwitchRoledropdown"
                            name="SwitchRoledropdown"
                            placeholder="Select Reporting Manager"
                            value={selectDropdownvalue}
                            onChange={(e) => RolechangeID(e)}
                            class="form-control filled roledropdown"
                          >
                            {Dropdownvalue != null &&
                            Dropdownvalue.length > 0 ? (
                              Dropdownvalue.map((item, indexs) => {
                                return (
                                  <option value={item.roleID}>
                                    {item.roleName}
                                  </option>
                                );
                              })
                            ) : (
                              <option>No data</option>
                            )}
                          </select>
                        </span>
                      </>
                    )}
                  </span>
                </a>
                <div className="clearfix"></div>
              </div>
            </div>
            <ul className="nav nav-primary">
              <li className="nav-item submenu sidebar-toggle" id="Dashboard">
                {/* <a
                  data-toggle="collapse"
                  href="#dashboard"
                  className="collapsed"
                  aria-expanded="false"
                > */}
                <Link to={"/Index"}>
                  <i className="fas fa-home"></i> <span>Dashboard</span>
                </Link>

                {/* </a> */}
              </li>

              <li
                className="nav-item submenu sidebar-toggle"
                id="StudentCreation"
              >
                {/* <a
                  data-toggle="collapse"
                  href="#dashboard"
                  className="collapsed"
                  aria-expanded="false"
                > */}
                {priviledge.includes("STUDENTCREATE") ||
                priviledge.includes("STUDENTEDIT") ||
                priviledge.includes("STUDENTDELETE") ||
                priviledge.includes("STUDENTVIEW") ? (
                  <Link to={"/StudentList"}>
                    <i class="fas fa-chalkboard-teacher"></i>
                    <span>Student Creation</span>
                  </Link>
                ) : (
                  ""
                )}

                {/* </a> */}
              </li>

              <li
                className="nav-item submenu sidebar-toggle"
                id="StudentBankdetails"
              >
                {priviledge.includes("STUDENTBANKDETAILVIEW") ? (
                  <Link to={"/StudentBankDetails"}>
                    <i class="fas fa-chalkboard-teacher"></i>
                    <span>Student Bank Details</span>
                  </Link>
                ) : (
                  ""
                )}

                {/* </a> */}
              </li>

              <li className="nav-item submenu sidebar-toggle" id="StudentView">
                {/* <a
                  data-toggle="collapse"
                  href="#dashboard"
                  className="collapsed"
                  aria-expanded="false"
                > */}
                {cookies.roleName == "Student" ? (
                  <Link to={"/StudentView"}>
                    <i class="fas fa-chalkboard-teacher"></i>
                    <span>Result Upload</span>
                  </Link>
                ) : (
                  ""
                )}

                {/* </a> */}
              </li>
              {priviledge.includes("REIMBCREATE") ||
              priviledge.includes("REIMBVIEW") ? (
                <li
                  className="nav-item submenu sidebar-toggle"
                  id="FundRequest"
                >
                  {/* <a
                  data-toggle="collapse"
                  href="#dashboard"
                  className="collapsed"
                  aria-expanded="false"
                > */}
                  <Link to={"/FundRequest"}>
                    <i className="fas fa-money-check-alt"></i>{" "}
                    <span>Reimbursement Request</span>
                  </Link>

                  {/* </a> */}
                </li>
              ) : (
                ""
              )}

              {priviledge.includes("FUNDCREATE") ||
              priviledge.includes("FUNDVIEW") ? (
                <li
                  className="nav-item submenu sidebar-toggle"
                  id="FundApproval"
                >
                  {/* <a
                  data-toggle="collapse"
                  href="#dashboard"
                  className="collapsed"
                  aria-expanded="false"
                > */}
                  <Link
                    to="/ApprovalGrid"
                    onClick={(e) => {
                      if (window.location.pathname === "/ApprovalGrid") {
                        e.preventDefault();
                        window.location.reload();
                      }
                    }}
                  >
                    <i className="fas fa-tasks"></i>
                    <span>Fund Approval</span>
                  </Link>

                  {/* </a> */}
                </li>
              ) : (
                ""
              )}

              {priviledge.includes("ADVANCEBILLVERIFYCREATE") ||
              priviledge.includes("ADVANCEBILLVERIFYVIEW") ? (
                <li
                  className="nav-item submenu sidebar-toggle"
                  id="AdvanceApproval"
                >
                  {/* <a
                  data-toggle="collapse"
                  href="#dashboard"
                  className="collapsed"
                  aria-expanded="false"
                > */}
                  <Link to={"/ApprovalBillGrid"}>
                    <i class="fas fa-money-bill"></i>
                    <span>Advance Bill verify</span>
                  </Link>

                  {/* </a> */}
                </li>
              ) : (
                ""
              )}

              {priviledge.includes("PROFILEEDITREQUESTCREATE") ||
              priviledge.includes("PROFILEEDITREQUESTVIEW") ? (
                <li
                  className="nav-item submenu sidebar-toggle"
                  id="ProfileApproval"
                >
                  {/* <a
                  data-toggle="collapse"
                  href="#dashboard"
                  className="collapsed"
                  aria-expanded="false"
                > */}
                  <Link to={"/StudentProfileApprovelist"}>
                    <i class="fas fa-address-card"></i>
                    <span>Profile Edit Request</span>
                  </Link>

                  {/* </a> */}
                </li>
              ) : (
                ""
              )}
              {/* {priviledge.includes("TIMESHEETVIEW")?
              ( */}
              <li
                className="nav-item submenu sidebar-toggle"
                id="TimeSheet"
                hidden
              >
                <a href="#" id="TimeSheet" onClick={(e) => LoadCTHrms(e)}>
                  <i className="fas fa-calendar-alt"></i>{" "}
                  <span>Time Sheet</span>
                </a>
                {/* </a> */}
              </li>
              {/* ):("")}
               */}
              {priviledge.includes("REPORTVIEW") ? (
                <li className="nav-item  submenu" id="ReportMenu">
                  <a data-toggle="collapse" href="#Report">
                    <i class="fa fa-flag" aria-hidden="true"></i>
                    <p>Report</p>
                    <span className="caret"></span>
                  </a>
                  <div className="collapse" id="Report">
                    <ul className="nav nav-collapse">
                      {/* <li id="LoginTrackerHistory">
                        <Link to={"/LoginTracker"}>
                          <i class="fa fa-history" aria-hidden="true"></i>{" "}
                          <span>Login Activity History</span>
                        </Link>
                      </li> */}
                      <li id="FundReport" className="sidebar-toggle">
                        <Link to={"/FundReport"}>
                          <i class="fa fa-history" aria-hidden="true"></i>{" "}
                          <span>Fund Report</span>
                        </Link>
                      </li>

                      <li id="FundReport" className="sidebar-toggle">
                        <Link to={"/CreditReport"}>
                          <i class="fa fa-history" aria-hidden="true"></i>{" "}
                          <span>Credit Report</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : (
                ""
              )}

              {priviledge.includes("EMPCREATE") ||
              priviledge.includes("EMPVIEW") ||
              priviledge.includes("EMAILTEMPLATEVIEW") ||
              priviledge.includes("EMAILTEMPLATECREATE") ||
              priviledge.includes("EMAILTEMPLATEEDIT") ||
              priviledge.includes("EMAILTEMPLATEDELETE") ||
              priviledge.includes("ROLECREATE") ||
              priviledge.includes("ROLEVIEW") ||
              priviledge.includes("CONFIGCREATE") ||
              priviledge.includes("CONFIGVIEW") ? (
                <li className="nav-item  submenu" id="ConfigurationMenu">
                  <a data-toggle="collapse" href="#Configuration">
                    <i className="fas fa-cog"></i>
                    <p>Configuration</p>
                    <span className="caret"></span>
                  </a>
                  <div className="collapse" id="Configuration">
                    <ul className="nav nav-collapse">
                      {priviledge.includes("EMPCREATE") ||
                      priviledge.includes("EMPVIEW") ? (
                        <li id="Employee" className="sidebar-toggle">
                          <Link to={"/Associate"}>
                            <i className="fas fa-user"></i>{" "}
                            <span>Employee</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {priviledge.includes("EMAILTEMPLATEVIEW") ||
                      priviledge.includes("EMAILTEMPLATECREATE") ||
                      priviledge.includes("EMAILTEMPLATEEDIT") ||
                      priviledge.includes("EMAILTEMPLATEDELETE") ? (
                        <li id="EmailTemplate" className="sidebar-toggle">
                          <Link to={"/Emailtemplate"}>
                            <i className="fab fa-fly"></i>{" "}
                            <span>Email Template</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                      {priviledge.includes("ROLECREATE") ||
                      priviledge.includes("ROLEVIEW") ? (
                        <li id="roleConf" className="sidebar-toggle">
                          <Link to={"/Role"}>
                            <i className="fas fa-compact-disc"></i>{" "}
                            <span>Role Configuration</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}

                      {priviledge.includes("CONFIGCREATE") ||
                      priviledge.includes("CONFIGVIEW") ? (
                        <li id="appconf" className="sidebar-toggle">
                          <Link to={"/ApplicationIndex"}>
                            <i className="fas fa-desktop"></i>{" "}
                            <span>Application Configuration</span>
                          </Link>
                        </li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>

      <div class="card-body">
        <div
          class="modal fade"
          id="UpadteStudent"
          tabindex="-1"
          role="dialog"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h3 class="modal-title">
                  <span class="fw-mediumbold">Update Profile</span>
                </h3>
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={refreshPage}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                {cookies.roleName == "Student" ? (
                  <StudentMain Id={cookies.userID} />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <script src="assets/js/core/jquery.3.2.1.min.js"></script>
      <script src="assets/js/core/popper.min.js"></script>
      <script src="assets/js/core/bootstrap.min.js"></script>

      <script src="assets/js/plugin/jquery-ui-1.12.1.custom/jquery-ui.min.js"></script>
      <script src="../../assets/js/plugin/jquery-ui-touch-punch/jquery.ui.touch-punch.min.js"></script>

      {/* <!-- jQuery Scrollbar --> */}
      <script src="assets/js/plugin/jquery-scrollbar/jquery.scrollbar.min.js"></script>
      {/* <!-- Datatables --> */}
      <script src="assets/js/plugin/datatables/datatables.min.js"></script>
      {/* <!-- Atlantis JS --> */}
      {/* <script src="assets/js/atlantis.min.js"></script> */}
      {/* <!-- Atlantis DEMO methods, don't include it in your project! --> */}
      <script src="assets/js/setting-demo2.js"></script>
    </>
  );
}
export default Sidebar;
