import { useEffect, useState } from "react";
import ReportServices from "../../api/ReportServices";
import MUIDataTable from "mui-datatables";
import Select from "react-select";
import $ from "jquery";

const repObj = new ReportServices();
const CreditReport = () => {
  const datecurre = new Date();
  const [monthFilter, setMonthFilter] = useState(datecurre.getMonth() + 1); // Set default to current month
  const [yearFilter, setYearFilter] = useState(datecurre.getFullYear());
  const [CourseFilter, setCourseFilter] = useState("");
  const [List, setList] = useState([]);
  const [CourceList, setCourceList] = useState([]);
  const handleFilterChange = (selectedValues, filterType) => {
    let currentMonth = monthFilter;
    let currentYear = yearFilter;
    let Coursee = CourseFilter;
    switch (filterType) {
      case "month":
        setMonthFilter(selectedValues);
        currentMonth = selectedValues.join(",");

        break;
      case "year":
        setYearFilter(selectedValues);
        currentYear = selectedValues.join(",");

        break;
      case "course":
        setCourseFilter(selectedValues);
        Coursee = selectedValues.join(",");

        break;
      default:
        break;
    }

    Report_CreditReport(currentMonth, currentYear, Coursee);
  };

  const Report_CreditReport = (month, year, Coursee) => {
    repObj
      .Report_CreditReport(month, year, Coursee)
      .then((res) => {
        if (res.data != null) {
          setList(res?.data);
          // Process the response
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    $(".submenu").removeClass("active");
    $("#ReportMenu").addClass("active");

    $(".collapse").removeClass("show");
    $("#CreditReport").addClass("show");
    window.scrollTo(0, 0);
    handleFilterChange();

    repObj
      .GetCourse()
      .then((res) => {
        if (res.data != null) {
          const options = res.data.map((item) => ({
            value: item.course,
            label: item.course,
          }));

          setCourceList(options);
          // Process the response
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    { name: "ticketNumber", label: "Ticket No" },
    { name: "fundType", label: "Fund Type" },
    { name: "studentName", label: "Student Name" },
    { name: "course", label: "Course" },
    { name: "studentStatus", label: "Student Status" },
    { name: "ticketDetails", label: "Ticket Details" },
    { name: "amount", label: "Amount" },
    { name: "billNumber", label: "Bill Number" },
    {
      name: "createdDate",
      label: "Requested Date",
      options: {
        customBodyRender: (value) => {
          const date = new Date(value);
          const day = date.getDate().toString().padStart(2, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        },
      },
    },
  ];

  const options = {
    responsive: true,
    download: true,
    print: true,
    selectableRows: false,
    filter: true,
    viewColumns: true,
  };

  const allMonths = [
    ...Array.from({ length: 12 }, (_, index) => ({
      value: index + 1,
      label: new Date(0, index).toLocaleString("default", { month: "long" }),
    })),
  ];

  // Assuming you want to show a range of years, adjust the range as needed
  const currentYear = new Date().getFullYear();
  const yearsRange = Array.from(
    { length: 2 },
    (_, index) => currentYear - index
  );

  const allYears = [
    ...yearsRange.map((year) => ({ value: year, label: year })),
  ];

  return (
    <div className="main-panel">
      <div className="content">
        <div className="page-inner">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-filters">
                    <div className="row">
                      <div class="col-lg-4 mb-2">
                        <div class="form-group">
                          <label class="text-label form-label">
                            Month <span class="text-danger"></span>
                          </label>
                          <Select
                            options={allMonths}
                            value={allMonths.find(
                              (month) => month.value === monthFilter
                            )}
                            isMulti
                            onChange={(selectedOptions) => {
                              const selectedValues = selectedOptions.map(
                                (option) => option.value
                              );
                              handleFilterChange(selectedValues, "month");
                            }}
                          />
                          {/* {monthError != "" ? <div class="invalid-feedback" style={{ display: "block" }}>
                                                        {monthError}
                                                    </div> : <></>} */}
                        </div>
                      </div>

                      <div class="col-lg-4 mb-2">
                        <div class="form-group">
                          <label class="text-label form-label">
                            Year <span class="text-danger"></span>
                          </label>
                          <Select
                            options={allYears}
                            value={allYears.find(
                              (year) => year.value === yearFilter
                            )}
                            isMulti
                            onChange={(selectedOptions) => {
                              const selectedValues = selectedOptions.map(
                                (option) => option.value
                              );
                              handleFilterChange(selectedValues, "year");
                            }}
                          />
                          {/* {yearError != "" ? <div class="invalid-feedback" style={{ display: "block" }}>
                                                        {yearError}
                                                    </div> : <></>} */}
                        </div>
                      </div>
                      <div class="col-lg-4 mb-2">
                        <div class="form-group">
                          <label class="text-label form-label">
                            Course <span class="text-danger"></span>
                          </label>
                          <Select
                            options={CourceList}
                            value={CourceList.find(
                              (course) => course.value === CourseFilter
                            )}
                            isMulti
                            onChange={(selectedOptions) => {
                              const selectedValues = selectedOptions.map(
                                (option) => option.value
                              );
                              handleFilterChange(selectedValues, "course");
                            }}
                          />
                          {/* {yearError != "" ? <div class="invalid-feedback" style={{ display: "block" }}>
                                                        {yearError}
                                                    </div> : <></>} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <MUIDataTable
                      title="Credit Reports"
                      data={List} // Use your actual data here
                      columns={columns}
                      options={options}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreditReport;
