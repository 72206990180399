import React, { useContext, useState, useEffect } from "react";
import $ from "jquery";
import logo from "../../assets/Final-logo.png";
import "../../assets/css/LoginIndex.css";
import "../../assets/js/atlantis.js";

import AuthenticationService from "../../api/AuthenticationService";
import { useNavigate } from "react-router";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../Context/AuthContext";
import "../SideBar/Loader.css";

export const Username = () => {
  const [dummy, setDummy] = useState({
    prefixName: "",
    endValue: "",

    errUserName: "",
    errPassword: "",
  });
  const currentYear = new Date().getFullYear();
  const forgotsubmit = (e) => {
    e.preventDefault();
    var data = {
      UserName: document.getElementById("UserName").value,

      rememberMeflag: true,
    };
    if (data.UserName == null || data.UserName == "") {
      setDummy({ errUserName: "Enter username" });

      return false;
    }

    if (data.UserName != null || data.UserName != "") {
      setDummy({ errUserName: "" });
    }

   
   
    forgotPassword(data);
  };

  const [otperror, setOtperror] = useState({
    isNavigate: false,
    errormsg: "",
  });
  const AuthObject = new AuthenticationService();
  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();

  const { data, setCookie, removeCookie } = useContext(AuthContext);

  const forgotPassword = (user) => {
    $(".loader-container").show();
  
    AuthObject.forgotpassword(user)
      .then((res) => {
       
        $(".loader-container").hide();
        
        setOtperror({
          errormsg: "",
          isNavigate: true,
        });
        setDisabled(false);
        navigate("/OTP", { state: { username: user.UserName } });
      
       
      })
      .catch((error) => {
       
        if (error.response && error.response.data == 0) {
        
          toast.error("Username doesn't exist!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          $(".loader-container").hide();
        } else {
          toast.error("Something went wrong!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          $(".loader-container").hide();
        }
      });
  };
  

  return (
    <>
      <div className="main-loader" style={{ display: "none" }}>
        <div className="large-indicator">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <ul className="slideshow">
        <li>
          <span></span>
        </li>
        <li>
          <span>2</span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
      </ul>

      <div>
        <div className="loader-container" style={{ display: "none" }}>
          <span
            className="loadercube"
            style={{ position: "fixed", right: "46%", bottom: "50%" }}
          ></span>
        </div>
        <div className="wrapper sidebar_minimize login">
          <div className="container d-flex justify-content-center">
            <div className="d-flex flex-column justify-content-between">
              <div className="card mt-3 p-5 Top_Card">
                <img
                  src={logo}
                  style={{
                    marginTop: "-25px",
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "10px",
                    height: "150px",
                    width: "150px",
                    margin: "auto",
                  }}
                />
                <div>
                  <p
                    className="mb-3 pt-1"
                    style={{
                      marginBottom: "-0.75rem !important",
                      fontFamily: "inherit",
                      fontSize: "25px",
                      textAlign: "center",
                      color: "white",
                    }}
                  >
                    Forgot Password
                  </p>
                  {/* <h4 className="mb-5 text-white"></h4> */}
                </div>
              </div>
              <div className="card two bg-white px-5 py-4 mb-3">
                <form onSubmit={forgotsubmit}>
                  <div className="form-group">
                    <label
                      className="form-label"
                      id="Lblname"
                      style={{ color: "#8B92AC", marginBottom: "-0.5rem" }}
                    >
                      Username
                    </label>

                    <input
                      type="text"
                      id="UserName"
                      name="UserName"
                      className="form-control"
                    />
                    <span style={{ color: "red" }}>{dummy.errUserName}</span>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        style={{ marginBottom: "15px !important" }}
                        disabled={disabled}
                        type="submit"
                        className={
                          disabled
                            ? "form-control btn btn-default rounded submit px-3"
                            : "form-control btn btn-primary rounded submit px-3"
                        }
                      >
                        Get OTP
                      </button>

                      {/* <a href="/OTP" className="btn btn-primary btn-lg d-block w-100 fw-500 mb-3">Get OTP</a> */}

                      {/* <!-- <button type="submit" className="btn btn-gray btn-lg d-block w-100 fw-500 mb-3">Cancel</button> --> */}
                      <a
                        href="/"
                        className="btn btn-danger btn-lg d-block w-100 fw-500 mb-3"
                      >
                        Cancel
                      </a>
                    </div>
                  </div>
                </form>
              </div>

              <div>
                <p className="" style={{ textAlign: "center" }}>
                  &copy; Copyright {currentYear}. All rights reserved.
                </p>
              </div>
            </div>

            <a href="#" data-click="scroll-top" className="btn-scroll-top fade">
              <i className="fa fa-arrow-up"></i>
            </a>
          </div>
        </div>
      </div>
      <ToastContainer/>

    </>
  );
};
