import React from "react";
import AxiosServices from "./AxiosService";

const Auth = new AxiosServices();

class ReimbursementService {
  SubmitRequest = async (data) => {
    return Auth.post(`/Reimbursement/Save_Request`, data);
  };
  UploadBill = async (data) => {
    return Auth.post(`/Reimbursement/UploadAdvanceBill`, data);
  };

  Get_RequestDetails = async () => {
    return Auth.get(`/Reimbursement/Get_RequestDetails`);
  };

  Get_RequestList = async (Id) => {
    return Auth.get(`/Reimbursement/RequestList?Id=${Id}`);
  };
  

  Get_RequestById = async (ID, RoleId, IsView) => {
    return Auth.get(
      `/Reimbursement/Get_RequestById?id=${ID}&RoleId=${RoleId}&IsView=${IsView}`
    );
  };
  DeleteRequest = async (StudentId) => {
    return Auth.get(`/Reimbursement/Delete_Request?Id=${StudentId}`);
  };

  GetbillNoExsists(obj) {
    return Auth.post("Reimbursement/Getbillnumbercheckexitsornot", obj);
  }

  // Download = async (url) => {
  //   return Auth.post(`Reimbursement/Download?url=${url}`, {
  //     responseType: "blob",
  //   });
  // };

  // Download = async (url) => {
  //   return Auth.post("Reimbursement/Download", (url = url), {
  //     responseType: "blob",
  //   });
  // };

  Download = async (url) => {
    debugger;
    return Auth.post(`Reimbursement/Download?url=${url}`, url, {
      responseType: "blob",
    });
  };

  DownloadPassBook = async (url) => {
    debugger;
    return Auth.post(`Reimbursement/DownloadPassBook?url=${url}`, url, {
      responseType: "blob",
    });
  };


  CommentBox = async (Id) => {
    return Auth.get(`/Approval/CommentBox?Id=${Id}`);
  };
  SaveCommentBox = async (data) => {
    return Auth.post(`/Approval/SaveCommentBox`, data);
  };

  Downloadcomment = async (url) => {
    debugger;
    return Auth.post(`Approval/Download?url=${url}`, url, {
      responseType: "blob",
    });
  };
}

export default ReimbursementService;
