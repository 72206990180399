import AxiosServices from "./AxiosService";

const Axios = new AxiosServices();

class ApplicarionConfigService {
  CreateSave = async (Settings) => {
    return Axios.post("/Settings/DropDownListSave", Settings);
  };
  getData = async (Code) => {
    return Axios.get(`/Settings/DropDownList?Code=${Code}`);
  };
  Deletedata = async (Settings) => {
    return Axios.post("/Settings/DropDownListDelete", Settings);
  };
  Create = async (ID) => {
    return Axios.get("/Settings/DropDownListDelete", ID);
  };
  DropDownTypeList = async () => {
    return Axios.get(`/Settings/DropDownTypeList`);
  };
  GetStepDropDownList = async (id) => {
    return Axios.get(`/Settings/GetStepDropDownList?Id=${id}`);
  };

  GetConfigurationData() {
    return Axios.get("Settings/GetConfigurationData");
  }

  SaveEmailConfiguration(data) {
    return Axios.post("Settings/SaveEmailConfiguration", data);
  }

  Getcheckexitsornot(obj) {
    return Axios.post("Settings/Getcheckexitsornot", obj);
  }
  GetSortOrdercheckexitsornot(obj) {
    return Axios.post("Settings/GetSortOrdercheckexitsornot", obj);
  }
  SaveSharedFolder(data) {
    return Axios.post("Settings/SaveSharedFolder", data);
  }

  GetIdleTime() {
    return Axios.get("Settings/GetIdleTime");
  }
  SaveIdleTime(data) {
    return Axios.post("Settings/SaveIdleTime", data);
  }
  GetLoginHours(Employee, startDate, todate) {
    return Axios.get(
      `Settings/LoginHours?Employee=${Employee}&startDate=${startDate}&todate=${todate}`
    );
  }
  GetCourse = () => {
    try {
        return Axios.get(`Report/GetCourses`)

    } catch (error) {
        console.log(error);
    }
}
}

export default ApplicarionConfigService;
